import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import * as moment from "moment-timezone";
import { MessageService } from "../../appservices/messageservice";
import * as _ from "lodash";

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
    
  @Input() bookingdata;
  @Input() spacedata;  
  @Input() userdata;
  currentbooking: any;
  public moment: any = moment;
  messageSuccessDialog = false;
  messageErrorDialog = false;
  space: any;
  user : any;
  messageDetail: any = {};
  messages: any;
  multipleContact:boolean = true;

  constructor(
    public message: MessageService
  ) { }

  
  ngOnInit() {
    this.space = this.spacedata;
    this.currentbooking = this.bookingdata;
    this.user = this.userdata;
    if(this.user != undefined)
    {
      this.multipleContact = false;
    }
  }

  sendmessage() {
    let number;
    let email;
    let userid;
    if (this.messageDetail.sendTo == "manager") {
      number = this.space.managerphone;
      email = this.space.ownerEmail;
      userid = this.space.ownerId;
    } else if (this.messageDetail.sendTo == "customer") {
      number = this.currentbooking.customerPhoneNumber;
      email = this.currentbooking.customerEmail;
      userid = this.currentbooking.custId;
    }
    else
    {
      number=this.user.phoneNumber;
      email = this.user.email;
      userid = this.user.uid;
    }

    if (this.messageDetail.medium == "call") {
      location.href = "tel:" + number;
    } else if (this.messageDetail.medium == "whatsapp") {
      location.href =
        "whatsapp://send?phone=+91" +
        number +
        "&&text=" +
        this.messageDetail.finalmessage;
    } else if (this.messageDetail.medium == "email") {
      this.messageDetail.email = email;

      this.message.sendMessage(this.messageDetail).then((res : any) => {
        if (res.status == "success") {
          this.messageSuccessDialog = true;
        } else {
          this.messageErrorDialog = true;
        }
      });
    } else if (this.messageDetail.medium == "pushnotification") {
      this.messageDetail.userId = userid;
      this.messageDetail.sendTo ='customer';
      this.message.sendMessage(this.messageDetail).then((res : any) => {
        if (res.status == "success") {
          this.messageSuccessDialog = true;
        } else {
          this.messageErrorDialog = true;
        }
      });
    } else if (this.messageDetail.medium == "sms") {
      this.messageDetail.number = number;
      this.message.sendMessage(this.messageDetail).then((res : any) => {
        if (res.status == "success") {
          this.messageSuccessDialog = true;
        } else {
          this.messageErrorDialog = true;
        }
      });
    }
  }
  onMessageChange(ev) {
    this.messageDetail.finalmessage = this.messageDetail.message;
  }

  onMediumChange(event) {
    this.messageDetail.message = "";
    this.messageDetail.finalmessage = "";
  }
  onSendToChange(value) {
    this.messageDetail.message = "";
    this.messageDetail.finalmessage = "";
    if (value === "manager") {
      this.messages = [
        `We got a booking request from ${
          this.currentbooking.customerName
        } for ${this.currentbooking.spaceName} from ${moment(
          this.currentbooking.startTime
        )
          .tz("Asia/Kolkata")
          .format("DD/MM/YYYY h:mm a")} to ${moment(this.currentbooking.endTime)
          .tz("Asia/Kolkata")
          .format("DD/MM/YYYY h:mm a")}. Shall we proceed to confirm?`,
        `${this.currentbooking.customerName} has cancelled the request for ${
          this.currentbooking.spaceName
        } dated ${moment(this.currentbooking.startTime)
          .tz("Asia/Kolkata")
          .format("DD/MM/YYYY h:mm a")}.`
      ];
    } else if (value === "customer") {
      this.messages = [
        `Your request for ${this.currentbooking.spaceName} from ${moment(
          this.currentbooking.startTime
        )
          .tz("Asia/Kolkata")
          .format("DD/MM/YYYY h:mm a")} to ${moment(this.currentbooking.endTime)
          .tz("Asia/Kolkata")
          .format(
            "DD/MM/YYYY h:mm a"
          )} is confirmed. Please do Checkin when you start using space`,
        `Your request for ${this.currentbooking.spaceName} from ${moment(
          this.currentbooking.startTime
        )
          .tz("Asia/Kolkata")
          .format("DD/MM/YYYY h:mm a")} to ${moment(this.currentbooking.endTime)
          .tz("Asia/Kolkata")
          .format(
            "DD/MM/YYYY h:mm a"
          )} has cancelled. Please try some other location`
      ];
    }
  }
  ngOnDestroy() {}
}
