import { Component, OnInit, Input } from '@angular/core';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
  selector: 'app-feedbacks',
  templateUrl: './feedbacks.component.html',
  styleUrls: ['./feedbacks.component.scss']
})
export class FeedbacksComponent implements OnInit {
  @Input('rating') rating;
  @Input('user') user;
  @Input('date') date;
  @Input('message') message;
  ratingArray = [];
  constructor() { }

  ngOnInit() {
    this.ratingArray = Array(this.rating).fill(1);
  }

}
