
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AppConstants } from '../app.constants';


import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class MessageService {
    serviceUrl: any;
    baseurl: any;
    options: any;
    constructor(    
        private http: HttpClient,
        public _ANGFIRE: AngularFireAuth
        ) {
        // this.baseurl = AppConstants.CONSTANTS.baseurl;
        this.baseurl = AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
        this.serviceUrl = AppConstants.CONSTANTS.bookings;
     }

     async sendMessage(value: any) {
        
        const url = this.baseurl + this.serviceUrl.sendMessage;
        return new Promise((resolve, reject) => {
          return this.http
            .post(url, JSON.stringify(value)).pipe(
            map((res:any) => res))
            .subscribe(
              values => {
                resolve(values);
              },
              err => {
                reject(err);
              }
            );
        });
      }

    //  sendMessage1(messageDetail): Observable<any> {
    //     const url = this.baseurl + this.serviceUrl.sendMessage;
    //     return this.http.post(url, JSON.stringify(messageDetail), this.options)
    //                    .map(this.extractData)
    //                    .catch(this.handleErrorObservable);
    //     }
    // private extractData(res: Response) {
    //     let body = res;
    //     return body || {};
    // }
    // private handleErrorObservable (error: Response | any) {
    //     console.error(error.message || error);
    //     return Observable.throw(error.message || error);
    // }
    
}