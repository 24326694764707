export default [{
    key: 'registeredCompanyName',
    displayName: 'Registered Company Name',
    value: ''
}, {
    key: 'registeredAddress',
    displayName: 'Registered Address',
    value: ''
}, {
    key: 'gstRegistered',
    displayName: 'GST Registered',
    value: ''
}, {
    key: 'gstin',
    displayName: 'GSTIN',
    value: ''
}, {
    key: 'bankname_branch',
    displayName: 'Bank name & Branch',
    value: ''
}, {
    key: 'bankAccountNumber',
    displayName: 'Bank Account Number',
    value: ''
}, {
    key: 'ifscCode',
    displayName: 'IFSC Code',
    value: ''
}, {
    key: 'online',
    displayName: 'Online',
    value: ''
}, {
    key: 'isApproved',
    displayName: 'Approved',
    value: ''
}, {
    key: 'featured',
    displayName: 'Featured',
    value: ''
}, {
    key: 'dayPassAvailable',
    displayName: 'Daily Pass Available',
    value: ''
}, {
    key: 'hourPassAvailable',
    displayName: 'Hourly Pass Available',
    value: ''
}];
