import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog } from '@angular/material';
import { AddfaqComponent } from '../addfaq/addfaq.component';
import { LoaderService } from '../sharedcomponents';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
private faqData = [];
datas :any;
keyDatas:any;
  obj: any = [];
  constructor(
    private db:AngularFireDatabase,
    private dialog:MatDialog,
    private loader : LoaderService
  ) { }

  ngOnInit() {
    this.loader.display(true);
   this.db.database.ref('meta/faqs').once("value",snapshot => {
     this.faqData = snapshot.val();
     this.keyDatas = Object.keys(this.faqData)
    this.loader.display(false);
     })
  }

  addFaq() {
    this.dialog.open(AddfaqComponent, {
      data: {
        category : '',
        faq: {
         name:'',
         description:''
        },
        editMode: false,
      }
    }).afterClosed().subscribe(updateRequired => {
      if (updateRequired) {
        this.ngOnInit();
      }
    })
  }

  editFaq(key,value){
    this.dialog.open(AddfaqComponent, {
      data: {
        category : key,
        editMode: true,
        faq: value,
      }
    }).afterClosed().subscribe(updateRequired => {
      if (updateRequired) {
        this.ngOnInit();
      }
    })
  }

}
