import { Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { ManageSpaceService } from '../appservices/managespace.service';
import { BookingService } from '../appservices/booking.service';
import { LoaderService } from '../sharedcomponents/index';
import BasicConfig from './config/basic-details-config';
import LocationConfig from './config/location-details-config';
import PaymentConfig from "./config/payment-details-config";
import CompanyConfig from "./config/company-details-config";
import { AddSpaceComponent } from '../add-space/add-space.component';
import SpaceConfig from "./config/space-details";
import * as moment from "moment-timezone";
import { MAT_DIALOG_DATA, MatDialogRef, MatTable, MatTableDataSource } from "@angular/material";
import {MatDialog} from '@angular/material';
import * as _ from "lodash";
import { AddNoteComponent } from '../sharedcomponents/add-note/add-note.component';
import { Title } from '@angular/platform-browser';
import { DragulaService } from 'ng2-dragula';
import { SharedProvider } from '../appservices/shared';
@Component({
	selector: 'app-view-space-details',
	templateUrl: './view-space-details.component.html',
	styleUrls: ['./view-space-details.component.scss']
})
export class ViewSpaceDetailsComponent implements OnInit, AfterViewInit {
	id: any;
	ownspace: any = {};
	facility_data = [];
	basic_details = [];
	space_details = [];
	location_details = [];
	payment_details = [];
	company_details = [];
	feedback_details = [];
	spaceImages = [];
	exclusiveOffer = '';
	purposesString = '';
	purposesListString = '';
	displayedColumns: string[] = ['note', 'addedOn','addedByEmail'];
	nearBydisplayedColumns: string[] = ['name', 'type','distance'];
	modelMode = false;
	isInstantlyBookable = false;
	public moment: any = moment;
	public notes:MatTableDataSource<any> =  new MatTableDataSource<any>([]);
	public nearBy:MatTableDataSource<any> =  new MatTableDataSource<any>([]);
	public myDataSource: MatTableDataSource<any>;
	@ViewChild('myTable',{static:false}) myTable: MatTable<any>;
	purposes: any = [];
	facilitiesList = [];
	purposesList = [];
	additionalCharges: any;
	wifiInstructions: any;
	constructor(private bookservice: BookingService,
		private route: ActivatedRoute,
		public router: Router,
		public loader: LoaderService,
		private dialog: MatDialog,
		private changeDetectorRefs: ChangeDetectorRef,
		private dialogRef: MatDialogRef<ViewSpaceDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) private modalData,
		private titleService:Title,
		private dragulaService: DragulaService,
		private sharedService: SharedProvider
		) {
			dragulaService.createGroup("spacephotos", {
				revertOnSpill: true
			  });
		 }

	ngOnInit() {}

	ngAfterViewInit() {
		if(this.modalData.space) {
			this.modelMode = this.modalData.modelMode;
			this.getModalDetails();
		} else if(this.modalData.spaceId){
			this.modelMode = this.modalData.modelMode;
			this.id = this.modalData.spaceId;
			this.getSpaceDetails();
		}else {
			this.id = this.route.snapshot.params['id'];
			this.getSpaceDetails();
		}
		
	}

	private getConfig (configurations: any[], res: any) {
		if (!res) {
			return [];
		}
		return configurations.map(config => ({
			displayName: config.displayName,
			value: res[config.key]
		}));
	}

	private isSpaceInstantlyBookable(space) {
		if (space.isInstantlyBookableWeWork || space.isInstantlyBookableAwfis || space.isInstantlyBookableCowrks || space.isInstantlyBookableInnov8) {
			 return true;
		} else {
			return false;
		}
	}

	public goBack() {
		if(this.modelMode) {
			this.dialogRef.close(false);
		} else {
		this.router.navigate(['/landingpage/manage']);
		}
	}

	editSpace(id=null) {
		
		if(!this.id) {
			return false;
		}
		let spaceId =this.id;
		this.dialog.open(AddSpaceComponent, {
			data: {
				spaceID: spaceId,
				editMode: true
			}
		}).afterClosed().subscribe(updateRequired => {
			if (updateRequired) {
				// this.fromEditScreen = true;
				this.getSpaceDetails();
			}
		});
	}

	getNewSpaceTypes(dataVal){
		let newSpaceTypes = '';
		if(dataVal.spaceSubType && dataVal.spaceSubType.length > 0)
		{
			let temp = dataVal.spaceSubType;
			newSpaceTypes = temp[0];
			for(let i = 1 ; i < temp.length; i++)
			{
				newSpaceTypes += ', ' + temp[i];
			}				
		}
		return newSpaceTypes;
	}
	getModalDetails() {
		this.ownspace = this.modalData.space;
		// console.log("ownspace",this.ownspace);
		if (this.modalData.space.dateAdded) {
			this.modalData.space.dateAdded = this.moment(this.modalData.space.dateAdded)
				.tz("Asia/Kolkata")
				.format("DD/MM/YYYY");
		}
		this.modalData.space.newSpaceTypes = this.getNewSpaceTypes(this.modalData.space);
		this.basic_details = this.getConfig(BasicConfig, this.modalData.space);
		this.space_details = this.getConfig(SpaceConfig,this.modalData.space);
		this.payment_details = this.getConfig(PaymentConfig, this.modalData.space);
		this.isInstantlyBookable = this.isSpaceInstantlyBookable(this.modalData.space);
		this.location_details = this.getConfig(LocationConfig, this.modalData.space.address);
		this.company_details = this.getConfig(CompanyConfig, this.modalData.space);
		if (this.ownspace.notes) {
			this.notes = new MatTableDataSource<any>(_.values(this.ownspace.notes).map(value => {
				value.addedOn = moment(value.addedOn)
					.tz("Asia/Kolkata")
					.format("DD/MM/YYYY h:mm a");
				return value;
			}));
		} 
		if (this.ownspace.nearBy) {
			this.nearBy = new MatTableDataSource<any>(_.values(this.ownspace.nearBy).map(value => {
				value.distance = value.distance + " kms";
				return value;
			}));
		} 
		
		this.exclusiveOffer = this.ownspace.exclusiveOffer;
		this.additionalCharges = this.ownspace.additionalCharges;
		this.wifiInstructions = this.ownspace.wifiInstructions;
		this.facilitiesList = this.ownspace.facilitiesList || [];
		this.purposesList = this.ownspace.purposesList || [];
	
		if(this.purposesList)
		{
				this.purposesListString = '';
				for(let i = 0; i < this.purposesList.length; i++)
				{
					this.purposesListString += this.purposesList[i] + ', ';
				}
				if(this.purposesListString && this.purposesListString.length > 0)
				{
					this.purposesListString = this.purposesListString.substring(0, this.purposesListString.length - 2)
				}
		}	
		this.spaceImages = this.ownspace.photos;
		if (this.ownspace.feedback) {
			this.feedback_details = Object.values(this.ownspace.feedback);
		}
	}
	getSpaceDetails() {
		const data = {
			'id': this.id,
			'app':'admin'
		};
		this.loader.display(true);
		this.bookservice.getSpace(data).then((res: any) => {
			this.ownspace = res;
			if (res.dateAdded) {
				res.dateAdded = this.moment(res.dateAdded)
					.tz("Asia/Kolkata")
					.format("DD/MM/YYYY");
			}
			let titleName = (res.gofloatersSpaceName && res.gofloatersSpaceName !== 'N/A')? res.gofloatersSpaceName : res.spaceDisplayName? res.spaceDisplayName : res.spaceName;
			let title = titleName + ' - ' + "Space Details";
			this.titleService.setTitle(title);
			this.sharedService.setSpaceTitle(title);
			res.newSpaceTypes = this.getNewSpaceTypes(res);
			this.exclusiveOffer = res.exclusiveOffer;
			this.additionalCharges = res.additionalCharges;
			this.wifiInstructions = res.wifiInstructions;
			this.basic_details = this.getConfig(BasicConfig, res);
			this.space_details = this.getConfig(SpaceConfig,res);
			this.payment_details = this.getConfig(PaymentConfig, res);
			this.isInstantlyBookable = this.isSpaceInstantlyBookable(res);
			this.location_details = this.getConfig(LocationConfig, res.address);
			this.company_details = this.getConfig(CompanyConfig, res);
			this.facilitiesList = this.ownspace.facilitiesList || [];
			this.purposesList = this.ownspace.purposesList || [];	
			// console.log("this.ownspace",this.ownspace);		
			if(this.purposesList)
			{
				this.purposesListString = '';
				for(let i = 0; i < this.purposesList.length; i++)
				{
					this.purposesListString += this.purposesList[i] + ', ';
				}
				if(this.purposesListString && this.purposesListString.length > 0)
				{
					this.purposesListString = this.purposesListString.substring(0, this.purposesListString.length - 2)
				}
			}	
			this.spaceImages = this.ownspace.photos;
			if (this.ownspace.feedback) {
				this.feedback_details = Object.values(this.ownspace.feedback);
			}
			if (this.ownspace.notes) {
				this.notes = new MatTableDataSource<any>(_.values(this.ownspace.notes).map(value => {
					value.addedOn = moment(value.addedOn)
						.tz("Asia/Kolkata")
						.format("DD/MM/YYYY h:mm a");
					return value;
				}));
			} 
			if (this.ownspace.nearBy) {
				this.nearBy = new MatTableDataSource<any>(_.values(this.ownspace.nearBy).map(value => {
					value.distance = value.distance + " kms";
					switch (value.type) {
						case 'airport':
							value.type = "Airport";
							break;
						case 'bus_station':
							value.type = "Bus Station";
							break;
						case 'subway_station':
							value.type = "Subway Station";
							break;
						case 'train_station':
							value.type = "Train Station";
							break;
						case 'lodging':
							value.type = "Lodging";
							break;
						default:
							break;
					}
					return value;
				}));
			} 
			// console.log("this.nearBy",this.nearBy);	
			// online, isApproved, featured, dayPassAvailable. hourPassAvailable
			// for (const val in this.ownspace.facilities) {
			// 	if (this.ownspace.facilities[val].Ischecked) {
			// 		this.facility_data.push(this.ownspace.facilities[val].name);
			// 	}
			// }
			this.loader.display(false);
		});
	}
	calculatebooking(value) {
		let totalbooked = 0;
		for (const key in value) {
			let temp = value[key];
			for (let subkey in temp) {
				totalbooked = totalbooked + parseInt(temp[subkey].booked);
			}
		}
		return totalbooked;
	}

	public addNote() {
		this.dialog.open(AddNoteComponent, {
			width: '400px',
			height: '250px'
		}).afterClosed().subscribe(updateRequired => {
			if (updateRequired) {
				const data = {
					'spaceId': this.id,
					'note': updateRequired.note
				};
				this.loader.display(true);
				this.bookservice.addNoteSpace(data).then((res: any) => {
					if(res.status) {
						res.note.addedOn = moment(res.note.addedOn)
						.tz("Asia/Kolkata")
						.format("DD/MM/YYYY h:mm a");
						this.notes.data.push(res.note);
        				this.myTable.renderRows();
					}
					this.loader.display(false);
					this.changeDetectorRefs.detectChanges();
				}).catch((e) =>{
					this.loader.display(false);
				});
			}
		});
	}

ngOnDestroy()
{
	this.titleService.setTitle("GoFloaters");
	this.dragulaService.destroy("spacephotos");
}


}


