import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoaderService} from '../sharedcomponents/index';
import {BookingService} from '../appservices/booking.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import * as Clipboard from 'clipboard/dist/clipboard.min.js';
import * as moment from 'moment-timezone';
import * as _ from 'underscore';

@Component({selector: 'app-user-list', templateUrl: './user-list.component.html', styleUrls: ['./user-list.component.scss']})
export class UserListComponent implements OnInit {
  params : any = {
    'custId': 'GDHAnLxS6nUbM7ciU2FnmZ7Q18x1'
  };
  searchSpaceName : any;
  clipboard: any;
  constructor(private _bookingservice : BookingService, private loader : LoaderService) {
    this.clipboard = new Clipboard('#cpyBtn');
  }
  userlist = [];
  filteredUsers = [];
  public moment : any = moment;
  fromRequestDate : any = this
  .moment().tz('Asia/Kolkata')
  .format('YYYY-MM-DD');
  toRequestDate : any = this
  .moment().tz('Asia/Kolkata')
  .format('YYYY-MM-DD');


  ngOnInit() {
    // this.getUserDetails();
  }
  getUserDetails() {
    const fromDateTimestamp =  Number(this.moment(this.fromRequestDate).tz('Asia/Kolkata'));
    const toDateTimestamp = Number(this.moment(this.toRequestDate).tz('Asia/Kolkata') + 24 * 60 * 60 * 1000 );
    this
      .loader
      .display(true);
    this
      ._bookingservice
      .getuserlist(fromDateTimestamp,toDateTimestamp)
      .then((res : any) => {

        this.userlist =  _.filter(res, function(user){ return user.isSpaceAdmin !== true; });
        this.filteredUsers = this.userlist;
        // this.filterdata();
        this
          .loader
          .display(false);
      });
  }
  calculatebooking(value) {
    let totalbooked = 0;
    for (let key in value) {
      let temp = value[key];
      for (let subkey in temp) {
        totalbooked = totalbooked + parseInt(temp[subkey].booked);
      }
    }
    return totalbooked;
  }

  filterdata() {
    const fromDateTimestamp =  Number(this.moment(this.fromRequestDate).tz('Asia/Kolkata'));
    const toDateTimestamp = Number(this.moment(this.toRequestDate).tz('Asia/Kolkata') + 24 * 60 * 60 * 1000 );
    this.filteredUsers = _.filter(this.userlist, function(user){
      return user.signupDateTimestamp >= fromDateTimestamp && user.signupDateTimestamp <= toDateTimestamp;
    })
  }
  
  searchSpace() {
    //   var reformatedFilterDate = this.reformatDate(this.currdate);
    this.filteredUsers = this
      .userlist
      .filter(item => {
        var location = item
          .spaceName.toUpperCase()
          .search(this.searchSpaceName.toUpperCase());
        if (location >= 0) {
          return true;
        } else {
          return false;
        }
      });
  }
  clearFilter(){
    this.filteredUsers = this.userlist;
    this.searchSpaceName = "";
  }
  downloadCSV() {
    var usersForDownload = [];
    var headings = ["UID","Name","Email","PhoneNumber","Referral Code","Reason","SignupSource","User Level","Source","Company Name","Profession","Signup Referral Code","Credits","Is Worqflexi","No of Bookings","Life Time Value","Average Revenue","First Booking","Last Booking","SignupDate","Days To First Booking","Month","Year", "Country","City"]
    for (let user of this.filteredUsers ) {
      var userObject = (({ uid,displayName, email, phoneNumber, referralCode, reason, signupSource, userLevel,userSource, companyName,profession,signupReferralCode,floatingpoints, isWorqFlexiUser, noOfBookings,ltv,avgRev,firstBooking, lastBooking, signupDate,daysToFirstBooking, month, year, country, city}) => 
                        ({ uid,displayName, email, phoneNumber, referralCode, reason, signupSource, userLevel,userSource,companyName,profession,signupReferralCode, floatingpoints, isWorqFlexiUser, noOfBookings,ltv,avgRev,firstBooking,lastBooking, signupDate,daysToFirstBooking, month, year, country, city}))(user);

      if( typeof userObject["email"] == 'undefined') {
        userObject["email"] = "Not Available";
      }

      if( typeof userObject["city"] == 'undefined') {
        userObject["city"] = "Not Available";
      }
      if( typeof user["signupDateTimestamp"] == 'undefined') {
        userObject["year"] = 'Not Available'
      }else {
        userObject["year"] = this
        .moment(user["signupDateTimestamp"]).tz('Asia/Kolkata')
        .format('YYYY');
      }
        

      if( typeof userObject["country"] == 'undefined') {
        userObject["country"] = "Not Available";
      }

      if( typeof userObject["reason"] == 'undefined') {
        userObject["reason"] = "";
      }

      if( typeof userObject["userSource"] == 'undefined') {
        userObject["userSource"] = "";
      }

      if(( typeof user["signupDateTimestamp"] != 'undefined' && userObject["firstBooking"] !="Not Booked" )) {
        // userObject["daysToBook"] = a.diff(b, 'days');
        let diffDays = moment(userObject["firstBooking"]).diff(moment(user["signupDateTimestamp"]),'days');
        // console.log("firstBooking",userObject["firstBooking"],"signupDateTimestamp",user["signupDateTimestamp"],"diffDays",diffDays);
        userObject["daysToFirstBooking"] = diffDays;
      } else {
        userObject["daysToFirstBooking"] = "";
        userObject["firstBooking"] = "";
        userObject["lastBooking"] = "";
      }

      usersForDownload.push(userObject);
    }
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: { headers: (headings) }, 
      showTitle: false,
      useBom: true
    };
    new Angular2Csv(usersForDownload, 'GoFloaters Users',{ headers: headings });
  }
  getdate(val) {
    return this
      .moment(val).tz('Asia/Kolkata')
      .format('DD/MM/YYYY');
  }
}
