import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../sharedcomponents/index';
import { BookingService } from "../appservices/booking.service";
import * as $ from 'jquery';
import { FormControl } from '@angular/forms';
import * as firebase from 'firebase';

@Component({
  selector: 'app-spacebulkupdate',
  templateUrl: './spacebulkupdate.component.html',
  styleUrls: ['./spacebulkupdate.component.scss']
})
export class SpaceBulkUpdateComponent implements OnInit {
  id: any;
  errorMessage: String;
  showDialog = false;
  showSuccessDialog = false;
  showerrorDialog = false;
  showerrordbDialog = false;
  spaces :any = {};
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loader: LoaderService,
    private _bookingService : BookingService
  ) {
  }
  ngOnInit() {
    //this.loader.display(true);
  }
  spaceBulkUpdate() {
    if (this.spaces.confirmation == "CONFIRM BULK OPERATION") {
    this.loader.display(true);
    let spaceIds = this.spaces.spaceIds.split(',');
    this.spaces.spaceIdsArray = spaceIds;
    this._bookingService.bulkUpdateSpace(this.spaces)
      .then(res => {
        if (res && res["status"] != "error") {
          this.showSuccessDialog = true;
          this.onTabChanged();
        } else {
          this.showerrordbDialog = true;
          this.onTabChanged();
        }
        this.loader.display(false);
      }, err => {
        this.showerrordbDialog = true;
        this.onTabChanged();
        this.loader.display(false);
      });
    } else {
      alert("Confirmation text does not match");
    }
  } 
  
  onTabChanged() {
    this.spaces.emailIds = "";
    this.spaces.spaceIdsArray = [];
    this.spaces.updateFieldName = "";
    this.spaces.updateFieldValue = "";
    this.spaces.note = "";
    this.spaces.confirmation = "";
  }

  failure() {
   
  }
  success() {
  
  }
  cancel() {
    
  }

}

