import { Component, OnInit } from '@angular/core';
import { BookingService } from '../appservices/booking.service';
import { LoaderService } from '../sharedcomponents/index';
import * as moment from 'moment';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';

@Component({
	selector: 'app-floaterpass-report',
	templateUrl: './floaterpass-report.component.html',
	styleUrls: ['./floaterpass-report.component.scss']
})
export class FloaterpassReportComponent implements OnInit {
	searchby: String = 'date';
	floaterpasses: any[];
	currDate: Date = new Date();
	fromDate: Date = new Date();
	toDate: Date = new Date();
	month: Date = new Date();
	customerEmail: String = '';
	selectedYear: number;
	selectedMonth: number;
	years: any = [];
	months: any = [
		{
			name: 'January',
			value: 1
		},
		{
			name: 'February',
			value: 2
		},
		{
			name: 'March',
			value: 3
		},
		{
			name: 'April',
			value: 4
		},
		{
			name: 'May',
			value: 5
		},
		{
			name: 'June',
			value: 6
		},
		{
			name: 'July',
			value: 7
		},
		{
			name: 'August',
			value: 8
		},
		{
			name: 'September',
			value: 9
		},
		{
			name: 'October',
			value: 10
		},
		{
			name: 'November',
			value: 11
		},
		{
			name: 'December',
			value: 12
		}
	];
	constructor(
		private _bookingservice: BookingService,
		private loader: LoaderService,
	) { }

	ngOnInit() {
		for (let index = 2018; index <= new Date().getFullYear(); index++) {
			this.years.push(index);
		}
		this.selectedYear =  new Date().getFullYear();
		this.selectedMonth = new Date().getMonth() + 1;
		// this.getFloaterPass();
	}

	async getFloaterPass() {
		this.loader.display(true);
		let fromDate, toDate;
		switch (this.searchby) {
			case 'date':
				this.currDate.setHours(0, 0, 0, 0);
				fromDate = this.currDate.getTime();
				this.currDate.setHours(23, 59, 59, 59);
				toDate = this.currDate.getTime();
				break;
			case 'dateRange':
				this.fromDate.setHours(0, 0, 0, 0);
				fromDate = this.fromDate.getTime();
				this.toDate.setHours(23, 59, 59, 59);
				toDate = this.toDate.getTime();
				break;
			case 'month':
				fromDate = moment([this.selectedYear, this.selectedMonth - 1]).toDate().getTime();
				toDate = moment(fromDate).endOf('month').toDate().getTime();
				break;
		}
		try {
			this.floaterpasses = <Object[]>await this._bookingservice.getFloaterPassReport({
				searchBy: this.searchby,
				starttime: fromDate,
				endtime: toDate,
				customerEmail: this.customerEmail
			});
		} catch (e) {

		}
		this.loader.display(false);
	}

	downloadCSV() {

		function checkDate(date) {
			if (date === undefined) {
				return '';
			}
			date = new Date(date);
			if (isNaN(date.getTime())) {
				return 'Not Redeemed';
			}
			return date.toString();
		}

		const vouchersForDownload = [];
		const headings = [
			'User ID',
			'User Name',
			'User Email',
			'Package Name',
			'Final Amount Charged',
			'Amount Charged',
			'Discount Amount'
		];
		for (const floaterpass of this.floaterpasses) {
			const {
				userId,
				userName,
				userEmail,
				packageName,
				finalAmountCharged,
				amountCharged,
				discountedAmount,
				claimDate
			} = floaterpass;
			vouchersForDownload.push({
				userId,
				userName,
				userEmail,
				packageName,
				finalAmountCharged,
				amountCharged,
				discountedAmount,
				claimDate: checkDate(claimDate)
			});
		}
		new Angular2Csv(vouchersForDownload, 'GoFloaters Floater Pass', { headers: headings });
	}

}
