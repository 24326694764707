import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import {AddspaceService } from '../appservices/addspace.service';
import { LoaderService } from '../sharedcomponents/index';
import { BookingService } from '../appservices/booking.service';
import * as $ from 'jquery';
import * as moment from 'moment-timezone';
import { FormControl } from '@angular/forms';
// import { } from 'googlemaps'; import { MapsAPILoader } from '@agm/core';
import * as firebase from 'firebase';
import * as _ from 'lodash';
import { Title } from '@angular/platform-browser';

@Component({ selector: 'app-manage-user', templateUrl: './manage-user.component.html', styleUrls: ['./manage-user.component.scss'] })
export class ManageUserComponent implements OnInit {
  id : any;
  errorMessage : String;
  showDialog = false;
  showSuccessDialog = false;
  showerrorDialog = false;
  showerrordbDialog = false;
  public moment : any = moment;
  floatingPointsHistory : any;
  foodCreditsHistory:any;
  userLevelHistory:any;
  userBehaviourHistory:any;
  userBookingHistory:any;
  floatingPointsColumns :string[] = ['changedDate', 'floatingPoints'];
  foodCreditsColumns : string[] = ['changedDate', 'foodCredits'];
  userLevelColumns : string[] =['changedDate','userLevel'];
  userHistoryColumns: string[] = ['actionTaken', 'viewName', 'session','inTime','outTime', 'spaceId', 'spaceType', 'spaceName'];
  userBookingColumns: string[] = ['bookingId', 'space','spaceType','bookingDate', 'status'];
  searchby : any = "userEmailId";
  searchValue : any ;
  user : any = {
    'location': {}
  };
  userMessageData : any;
  disable : boolean = true;
  searchUserEmail : string;
  // space: any = {'address': {}, 'location': [], 'photos' : []}; locationval: any
  // = {}; location: any;
  public latitude : number;
  public longitude : number;
  // public searchControl: FormControl; @ViewChild("search",{static:false}) public
  // searchElementRef: ElementRef;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private _bookingservice: BookingService,
		private loader: LoaderService,
		private ngZone: NgZone,
		private titleService:Title
	) {

	}
	ngOnInit() {
		const { params } = this.route.snapshot;
		this.titleService.setTitle("Manage User");
		if (params && params['userEmailId']) {
			this.searchby = 'userEmailId';
			this.searchValue = params['userEmailId'];
			this.searchUser();
		}
	}
	ngOnDestroy()
	{
		this.titleService.setTitle("GoFloaters");
	}
	onChanceSearchBy() {
		this.searchValue = '';
	}

	async loadUserBehaviour() {
		event.preventDefault();
		try {
			this.loader.display(true);
			this.userBehaviourHistory = await this._bookingservice.getUserBehaviour(this.user.uid);
		} catch (e) {
			this.userBehaviourHistory = [];
		}
		this.loader.display(false);
	}

	async loadUserBookings() {
		event.preventDefault();
		try {
			this.loader.display(true);
			this.userBookingHistory = await this._bookingservice.getUserBookings(this.user.uid);
			// console.log(this.userBookingHistory);
		} catch (e) {
			this.userBookingHistory = [];
		}
		this.loader.display(false);
	}

	searchUser() {
		let searchData;
		this.userBehaviourHistory = [];
		this.floatingPointsHistory = '';
		this.foodCreditsHistory = '';
		this.userLevelHistory = '';
		this
			.loader
			.display(true);
		console.log(this.searchby);
		if (this.searchby === 'userEmailId') {
			searchData = {
				userEmailId: this.searchValue
			};
		} else if (this.searchby === 'userPhoneNumber') {
			searchData = {
				userPhoneNumber: this.searchValue
			};
		} else if (this.searchby === 'userId') {
			searchData = {
				userId: this.searchValue
			};
		}
		this
			._bookingservice
			.getUser
			(searchData)
			.then(res => {
				this.user = res;
				let title = this.user.displayName + ' - ' + "User Details";
				this.titleService.setTitle(title);
				this.userMessageData = res;
				for (const [key, value] of Object.entries(res)) {
					if (key === 'floatingpointhistory') {
						this.floatingPointsHistory = _.values(value);
					}
					if (key === 'foodPointsHistory') {
						this.foodCreditsHistory = _.values(value);
					}
					if (key === 'userLevelHistory') {
						this.userLevelHistory = _.values(value);
					}
				}
				this
					.loader
					.display(false);
				setTimeout(() => {
					$('div.label-floating').toggleClass('is-empty');
					this.disable = false;
				}, 100);
			}, err => {
				this
					.loader
					.display(false);
				this.showerrordbDialog = true;
			});
	}
	updateUser() {
		this
			.loader
			.display(true);
		if (this.user.uid) {
			if (this.user.isSpaceOwner === 'true') {
				this.user.isSpaceOwner = true;
			}
			if (this.user.isAdmin === 'true') {
				this.user.isAdmin = true;
			}
			this
				._bookingservice
				.updateUser(this.user)
				.then(res => {
					if (res) {
						this.showSuccessDialog = true;
					} else {
						this.showerrorDialog = true;
					}
					this
						.loader
						.display(false);
				}, err => {
					this.showerrordbDialog = true;
				});
		}
	}

	getdate(val) {
		if (val !== undefined) {
			return this
				.moment(val).tz('Asia/Kolkata')
				.format('DD/MM/YYYY');
		} else {
			return '';
		}
	}

	// addspace() {   // tslint:disable-next-line:max-line-length
	// //this.space['location'] =
	// (<HTMLInputElement>document.getElementById('location')).value ?
	// ((<HTMLInputElement>document.getElementById('location')).value).split(',') :
	// [];   this.space['facilities'] =
	// (<HTMLInputElement>document.getElementById('facilities')).value ?
	// ((<HTMLInputElement>document.getElementById('facilities')).value).split(',') :
	// [];   this.loader.display(true);   if (this.space.spaceId) {
	// this._bookingservice.updatespace(this.space).subscribe(res => {       if
	// (res) {        this.showSuccessDialog = true;       }else {
	// this.showerrorDialog = true;       }       this.loader.display(false);    },
	// err => {         this.showerrordbDialog = true;     });   } else {
	// this._bookingservice.createspace(this.space).subscribe(res => {       if
	// (res) {        this.showSuccessDialog = true;       }else {
	// this.showerrorDialog = true;       }       this.loader.display(false);    },
	// err => {         this.showerrordbDialog = true;     });   } }
	success() {
		this
			.router
			.navigate(['/landingpage']);
	}

	cancel() {
		this.user = {
			'location': {}
		};
		this.userMessageData = "";
		setTimeout(() => {
			$('div.label-floating').toggleClass('is-empty');
			this.disable = true;
		}, 100);
		this.searchValue = '';
		this.floatingPointsHistory = '';
		this.foodCreditsHistory = '';
		this.userLevelHistory = '';
		this.userBehaviourHistory = [];
		this.titleService.setTitle("Manage User");
	}
	//  editSpace() {    const data = {      'id': this.id    };
	// this._bookingservice.getSpace(data).subscribe(res => {       this.space =
	// res;    });  }

	Failure() {
		this
			.router
			.navigate(['/landingpage']);
	}
	dbFauilure() {
		this
			.router
			.navigate(['/landingpage']);
	}
}
