import { Component, OnInit, Inject } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LoaderService } from '../sharedcomponents';
import { rejects } from 'assert';

@Component({
  selector: 'app-addfaq',
  templateUrl: './addfaq.component.html',
  styleUrls: ['./addfaq.component.scss']
})
export class AddfaqComponent implements OnInit {
  category: String = "";
  name: String = "";
  description: String = "";
  editMode: Boolean;
  completed: Boolean;
  faqAdd: any[] = [];
  remove: any;
  categories = [
    "booking", "general", "pricing", "payments"
  ]
  errorMessage: string;
  showSuccessDialog: boolean;
  showerrorDialog: boolean;
  showSuccessDialog1: boolean;
  objIndex: any;
  constructor(
    private db: AngularFireDatabase,
    private loader: LoaderService,
    private dialogRef: MatDialogRef<AddfaqComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    if (data) {
      this.editMode = data.editMode;
      this.category = data.category;
      const faq = data.faq;
      this.name = faq.name;
      this.description = faq.description;
    }
  }

  ngOnInit() {
    this.editFaqValidate();
  }

  editFaqValidate(){
    if (this.name !== "") {
      this.editFaq()
    }
  }

  pushFaq(category) {
    return new Promise((resolve, reject) => {
      const data = {
        description: this.description,
        name: this.name
      }
      const pushFaq = this.db.database.ref('meta/faqs/' + category);
      pushFaq.once("value", snapshot => {
        this.faqAdd = snapshot.val();
        if (this.faqAdd !== null) {
          this.faqAdd.push(data);
        }
        else {
          return this.faqAdd = [data];
        }
        resolve(this.faqAdd);
      },
        err => {
          reject(err);
        }
      )
    })
  }

  async addFaq() {
    const { category } = this;
    if(category !== ""){
      await this.pushFaq(category);
    if(this.name !== "" && this.description !== ""){
      const newFaq = this.db.database.ref('meta/faqs/' + category);
      newFaq.set(this.faqAdd)
        .then(() => {
          this.loader.display(false);
          this.errorMessage = '';
          this.showSuccessDialog = true;
          this.showSuccessDialog1 = false;
          this.showerrorDialog = false;
        }).catch(() => {
          this.loader.display(false);
          this.errorMessage = 'Server error!...';
          this.showSuccessDialog = false;
          this.showSuccessDialog1 = false;
          this.showerrorDialog = true;
        });
    }
    else{
      if(this.name == ""){
      this.errorMessage = 'Question is required!...';
      }
      else if(this.description == ""){
      this.errorMessage = ' Answer is required!...';
      }
          this.showSuccessDialog = false;
          this.showSuccessDialog1 = false;
          this.showerrorDialog = true;
    }
  }
    else{
      this.errorMessage = 'Category is required!...';
          this.showSuccessDialog = false;
          this.showSuccessDialog1 = false;
          this.showerrorDialog = true;
    }
  }

  editFaq() {
    return new Promise((resolve) => {
      const edit = this.db.database.ref('meta/faqs/' + this.category);
      edit.once("value", snapshot => {
        var faq = snapshot.val();
        this.objIndex = faq.findIndex(x => x.name === this.name);
        this.remove = faq.filter((item) => item.name !== this.name);
        const removeData = this.db.database.ref('meta/faqs/' + this.category);
        removeData.set(this.remove);
      })
      resolve(this.remove)
    })
  }

  async update() {
    const data = {
      name: this.name,
      description: this.description
    }
    if (this.data.category == this.category) {
      this.remove.splice(this.objIndex, 0, data);
      const editValues = this.remove;
      const Updates = this.db.database.ref('meta/faqs/' + this.category);
      Updates.update(editValues)
        .then(() => {
          this.loader.display(false);
          this.errorMessage = '';
          this.showSuccessDialog = false;
          this.showSuccessDialog1 = true;
          this.showerrorDialog = false;
        }).catch(() => {
          this.loader.display(false);
          this.errorMessage = 'Server error!...';
          this.showSuccessDialog = false;
          this.showSuccessDialog1 = false;
          this.showerrorDialog = true;
        });
    }
    else {
      const { category } = this;
      await this.pushFaq(category);
      const newFaq = this.db.database.ref('meta/faqs/' + category);
      newFaq.set(this.faqAdd)
        .then(() => {
          this.loader.display(false);
          this.errorMessage = '';
          this.showSuccessDialog = false;
          this.showSuccessDialog1 = true;
          this.showerrorDialog = false;
        }).catch(() => {
          this.loader.display(false);
          this.errorMessage = 'Server error!...';
          this.showSuccessDialog = false;
          this.showSuccessDialog1 = false;
          this.showerrorDialog = true;
        });
    }
  }

  success() {
    this.dialogRef.close(true);
  }

  error() {
    this.dialogRef.close(false);
  }

}
