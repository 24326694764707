import { Component, OnInit, Inject } from '@angular/core';
import { NgModel } from '@angular/forms';
import { BookingService } from '../appservices/booking.service';
import { LoaderService } from '../sharedcomponents/index';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as Clipboard from 'clipboard/dist/clipboard.min.js';
import { SharedProvider } from '../appservices/shared';
import * as _ from "underscore";
import { LoginService } from '../appservices/login.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-add-promotion',
	templateUrl: './add-promotion.component.html',
	styleUrls: ['./add-promotion.component.scss']
})
export class AddPromotionComponent implements OnInit {
	searchLocationString: String = '';
	priorities = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'];
	promotionSpaces: any[] = [];
	editMode: Boolean = false;
	promotionid: String = '';
	promotionName: String = '';
	promotionDescription: String = '';
	isCollectionActive: String = '';
	isActive: String = '';
	priority: String = '';
	promotionLocation: any[] = [];
	searchSpaceString: String = '';
	spaces: any = [];
	searchFilteredSpace: any = [];
	availablePromotions: any[] = [];

	public showSuccessDialog: Boolean = false;
	public showSuccessDialog1: Boolean = false;
	public showerrorDialog: Boolean = false;
	public errorMessage: String = '';
	private clipboard: any;
	public cityInfo:any = [];
	selectCities:any=[];
	collectionName: any;
	filteredSpaces: any;
	spaceArea: string;
	localities: any[];
	facilities: string;
	locality: string;
	revenue: any;

	constructor(
		private _bookingService: BookingService,
		private loader: LoaderService,
		private dialogRef: MatDialogRef<AddPromotionComponent>,
		private snackBar: MatSnackBar,
		private sharedService : SharedProvider,
		private _bookingservice: BookingService,
		private loginService : LoginService,
		private route : ActivatedRoute,
		private router : Router,
		@Inject(MAT_DIALOG_DATA) private data
	) {
		if (data) {
			this.editMode = data.editMode;
			const promotion = data.promotion;
			this.promotionid = promotion.promotionid;
			this.priority = promotion.priority;
			this.promotionName = promotion.promotionName;
			this.collectionName = this.promotionName;
			this.promotionDescription = data.promotion.promotionDescription;
			this.isActive = promotion.isActive;
			this.isCollectionActive = promotion.isCollectionActive;
			this.promotionLocation = promotion.promotionLocation;
			this.promotionSpaces = promotion.promotionSpaces;
			this.availablePromotions = promotion.availablePromotions || [];
		}
		this.clipboard = new Clipboard('#cpyBtn');
	}

	private allowFetch: Boolean = false;

	fetchSpaces(cities) {
		try {
			if (cities[0] === 'all' && this.allowFetch === false) {
				return;
			}
			this.allowFetch = false;
			this._bookingService.getSpacesForCities({
				cities: cities.filter((city) =>
					city && city !== 'all')
			}).then(res => {
				this.searchFilteredSpace = this.spaces = res;
				this.loader.display(false);
			});

		} catch (e) {
			console.log(e);
		}
	}

	ngOnInit() {
		this.fetchSpaces(this.promotionLocation);
		const user = this.loginService.getCurrentUser();
		if (user.uid !== undefined) {
			// this.spaceOwnerId = user.uid;
			this.sharedService.isLoggedIn = true;
		} else {
			this.loginNow();
		}
		if(this.sharedService.citiesMetaData)
		{
			this.cityInfo = this.sharedService.getActiveCities();
		}
		else
		{
			this.loader.display(true);
			this._bookingservice.getMetaData().then(val => {
				this.cityInfo = this.sharedService.getActiveCities();
				this.loader.display(false);
			}).catch(err => {
				console.log(err);
			});
		}
	}

	showcopytoast() {
		this.snackBar.open('Copied to Clipboard', 'Close', {
			duration: 1000,
		});
	}

	selectAll(checkAll, select: NgModel, values) {
		this.allowFetch = true;
		if(checkAll){
		  select.update.emit(values.map(value =>{
			  if(value.value){
				return value.value.toLowerCase();
			  }
			 else {
				return value.spaceId;
			 }
		  })); 
			this.loader.display(false);
		}
		else{
			this.loader.display(false);
		  select.update.emit([]);
		}
	  }

	editPromotion() {
		const { promotionid, collectionName, promotionName, promotionDescription, priority, isActive, isCollectionActive, promotionLocation, promotionSpaces = [] } = this;
		this.loader.display(true);
		this._bookingService.addPromotion({
			promotionid,
			collectionName,
			promotionName,
			promotionDescription,
			priority,
			isActive,
			isCollectionActive,
			promotionLocation,
			promotionSpaces
		}, true).then((res) => {
			this.loader.display(false);
			this.errorMessage = '';
			this.showSuccessDialog = false;
			this.showSuccessDialog1 = true;
			this.showerrorDialog = false;
		}).catch(() => {
			this.loader.display(false);
			this.errorMessage = 'Server error!...';
			this.showSuccessDialog = false;
			this.showerrorDialog = true;
		});
	}

	addPromotion() {
		const { promotionid, promotionName, collectionName, promotionDescription, isActive, priority, isCollectionActive, promotionLocation, promotionSpaces } = this;
		if (this.availablePromotions.includes(promotionid)) {
			this.errorMessage = 'Promotion ID is already found';
			this.showSuccessDialog = false;
			this.showerrorDialog = true;
			return;
		}
		this.loader.display(true);
		this._bookingService.addPromotion({
			promotionid,
			promotionName,
			collectionName,
			promotionDescription,
			isActive,
			priority,
			isCollectionActive,
			promotionLocation,
			promotionSpaces
		}).then((res) => {
			this.loader.display(false);
			this.errorMessage = '';
			this.showSuccessDialog = true;
			this.showerrorDialog = false;
		}).catch(() => {
			this.loader.display(false);
			this.errorMessage = 'Server error!...';
			this.showSuccessDialog = false;
			this.showerrorDialog = true;
		});
	}

	searchLocation(e) {
		this.searchLocationString = e.target.value;
		if (e.key === ' ') {
			this.searchLocationString += ' ';
		}
		if (!this.searchLocationString) {
			this.cityInfo;
		} else {
		 this.cityInfo
				.filter(location =>{
					location.name && location.name.toUpperCase().includes(this.searchLocationString.toUpperCase());
				})
		}
	}

	searchSpaces(e) {
		this.searchSpaceString = e.target.value;
		if (e.key === ' ') {
			this.searchSpaceString += ' ';
		}
		if (!this.searchSpaceString) {
			this.searchFilteredSpace = this.spaces;
		} else {
			this.searchFilteredSpace = this.spaces
				.filter(space => space.spaceName && space.spaceName.toUpperCase().includes(this.searchSpaceString.toUpperCase()));
		}
	}


	success() {
		this.dialogRef.close(true);
	}

	error() {
		this.dialogRef.close(false);
	}

	loginNow() {
		this.loader.display(false);
		this.router.navigate(["/login"]);
	}

}
