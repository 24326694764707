
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { SharedProvider } from './shared';
import { AppConstants } from '../app.constants';

@Injectable()
export class FoodCreditService {
    
    serviceUrl: any;
    baseurl: any;
    appurl: string;
    // options: any;

    constructor(
        private http: HttpClient, 
        public _ANGFIRE: AngularFireAuth, 
        private router: Router,
        public sharedService: SharedProvider
        ) {
        this.baseurl = AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
        this.appurl =
        AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].appurl;
        this.serviceUrl = AppConstants.CONSTANTS.foodCredits;   
           
    }
    private handleErrorObservable(error: any) {
        return observableThrowError(error);
    }
    async getFoodCreditTransactionList(searchData) {
        
        return new Promise((resolve, reject) => {
            return this.http
                .post(
                    this.baseurl + this.serviceUrl.foodTransactionList,
                    searchData,
                ).pipe(
                map((res:any) => res))
                .subscribe(values => {
                    resolve(values);
                }, err => {
                    reject(err);
                });
        });
    }
    async getFoodCreditPurchaseList(searchData) {
        
        return new Promise((resolve, reject) => {
            return this.http
                .post(
                    this.appurl + this.serviceUrl.foodCreditsPurchaseList,
                    searchData,
                    
                ).pipe(
                map((res:any) => res))
                .subscribe(values => {
                    resolve(values);
                }, err => {
                    reject(err);
                });
        });
    }

}