export default [{
    key: 'spaceName',
    displayName: 'Space Name',
    value: ''
},
{
    key:'gofloatersSpaceName',
    displayName:'GoFloaters Space Name',
    value:''
},
{
    key:'spaceDisplayName',
    displayName:'Space Display Name',
    value:''
},
{
    key: 'newSpaceTypes',
    displayName: 'Space Sub Type(s)',
    value: ''
},
{
    key: 'ownerName',
    displayName: 'Owner Name',
    value: ''
}, 
{
    key: 'ownerEmail',
    displayName: 'Owner Email',
    value: ''
},
{
    key: 'mobile',
    displayName: 'Owner Phone',
    value: ''
},
{
    key: 'altContactNumbers',
    displayName: 'Alt Contact Nos.',
    value: ''
},
{
    key: 'managerName',
    displayName: 'Manager Name',
    value: ''
}, {
    key: 'managerphone',
    displayName: 'Manager Phone',
    value: ''
},{
    key: 'managerEmail',
    displayName: 'Manager Email',
    value: ''
},
{
    key: 'seatsAvailable',
    displayName: 'Seats Available',
    value: ''
},
{
    key: '',
    displayName: '',
    value: ''
},
{
    key: 'priceperhr',
    displayName: 'Rate / hr',
    value: ''
},
{
    key: 'priceperday',
    displayName: 'Rate / day',
    value: 'priceperday'
}, {
    key: 'pricepermonth',
    displayName: 'Rate / month',
    value: ''
},
{
    key: 'justDialLink',
    displayName: 'JustDial Link',
    value: ''
}
];