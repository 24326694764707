import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../sharedcomponents/index';
import { BookingService } from '../appservices/booking.service';
import * as _ from 'underscore';
import { AddPromotionComponent } from '../add-promotion/add-promotion.component';
import { MatDialog } from '@angular/material';
import * as Clipboard from 'clipboard/dist/clipboard.min.js';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-promotion-details',
  templateUrl: './promotion-details.component.html',
  styleUrls: ['./promotion-details.component.scss']
})
export class PromotionDetailsComponent implements OnInit {
  promotions = [];
  clipboard: any;

  constructor(
    private loader: LoaderService,
    private _bookingservice: BookingService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.clipboard = new Clipboard('#cpyBtn');
  }

  ngOnInit() {
    this.fetchPromotionDetails();
  }

  addPromotion() {
    this.dialog.open(AddPromotionComponent, {
      data: {
        promotion: {
          promotionid: '',
          collectionName: '',
          promotionName: '',
          priority: '',
          promotionDescription: '',
          isActive: false,
          isCollectionActive: true,
          promotionLocation: [],
          availablePromotions: this.promotions.map(promotion => promotion.promotionid)
        },
        editMode: false,
      }
    }).afterClosed().subscribe(updateRequired => {
      if (updateRequired) {
        this.fetchPromotionDetails();
      }
    });
  }

  showcopytoast() {
    this.snackBar.open('Copied to Clipboard', 'Close', {
      duration: 1000,
    });
  }

  editPromotion(promotion) {
    this.dialog.open(AddPromotionComponent, {
      data: {
        editMode: true,
        promotion: promotion
      }
    }).afterClosed().subscribe(updateRequired => {
      if (updateRequired) {
        this.fetchPromotionDetails();
      }
    });
  }

  fetchPromotionDetails() {
    this.loader.display(true);
    this._bookingservice.getPromotionDetails().then((promotions: any) => {
      if (Array.isArray(promotions)) {
        this.promotions = promotions;
      } else {
        this.promotions = [];
      }
      this.loader.display(false);
    }).catch(() => {
      this.promotions = [];
      this.loader.display(false);
    });
  }


}