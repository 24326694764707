import { Component, OnInit } from "@angular/core";
import { NgModel } from "@angular/forms";
import * as moment from "moment";
import { BookingService } from "../appservices/booking.service";
import { LoaderService } from "../sharedcomponents";
import { SharedProvider } from "../appservices/shared";
import { LoginService } from "../appservices/login.service";
import { Router } from "@angular/router";
import { Angular2Csv } from "angular2-csv/Angular2-csv";
import * as _ from "underscore";
import { MatDialog } from "@angular/material";
import { AddSettlementComponent } from '../sharedcomponents/add-settlement/add-settlement.component';

@Component({
	selector: "app-report",
	templateUrl: "./report.component.html",
	styleUrls: ["./report.component.scss"]
})
export class ReportComponent implements OnInit {
	TotalRevenue: any = 0;
	useDateFilter: boolean = false;
	allSpacesFlag: boolean = true;
	revenue: any = {};
	bookings: any = [];
	// tslint:disable-next-line: indent
	public moment: any = moment;
	years: Number[] = [];
	SpaceOwnerId: any;
	spaces: any[] = [];
	searchSpaceString: String = ''; // new
	searchOriginalSpaceString: String = '';
	searchGoFNameString : String = '';
	searchSpaceDispNameString : String = '';
	filteredSpaces: any[] = [];
	searchFilteredSpace = [];      //new
	revenueResult: any[] = [];
	cityInfo:any = [];
	totalRevenue: Number = 0;
	revenueShare: Number = 0.00;
	revenueShareWOGst: Number = 0.00;
	gstonRevenue: Number = 0.00;
	grossRevenueWGst: Number = 0.00;
	// totalAmountPaid : Number = 0.00;
	currentSearch: any;
	spaceArea: string;
	facilities:string;
	localities: string[];
	locality: string;
	fromDate: Date;
	toDate: Date;
	paymentBtn : boolean = false;
	months: any = [
		{
			name: "January",
			value: 1
		},
		{
			name: "February",
			value: 2
		},
		{
			name: "March",
			value: 3
		},
		{
			name: "April",
			value: 4
		},
		{
			name: "May",
			value: 5
		},
		{
			name: "June",
			value: 6
		},
		{
			name: "July",
			value: 7
		},
		{
			name: "August",
			value: 8
		},
		{
			name: "September",
			value: 9
		},
		{
			name: "October",
			value: 10
		},
		{
			name: "November",
			value: 11
		},
		{
			name: "December",
			value: 12
		}
	];
	public settlement:Array<string>  = [];
	constructor(
		private _bookingservice: BookingService,
		private loader: LoaderService,
		public loginService: LoginService,
		public sharedService: SharedProvider,
		private router: Router,
		private dialog: MatDialog
	) {
		this.spaceArea = "all";
		this.localities = [];
		this.locality = "all";
		this.facilities = "all";
	}

	async ngOnInit() {
		this.moment.tz.setDefault('Asia/Kolkata');
		const user = this.loginService.getCurrentUser();
		if (user.uid !== undefined) {
			this.SpaceOwnerId = user.uid;
			this.sharedService.isLoggedIn = true;
		} else {
			this.loginNow();
		}
		this.revenue = {
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1
		};
		for (let index = 2018; index <= new Date().getFullYear(); index++) {
			this.years.push(index);
		}
		const today = new Date();
		today.setDate(1);
		this.fromDate = today;
		this.toDate = new Date();
		this.loader.display(true);
		await this.getSpaceDetails();
		console.log("spaces loaded");
		// this.getMonthDateRange(2019,3)
	
		if(this.sharedService.citiesMetaData)
		{
			this.cityInfo = this.sharedService.getActiveCities();
		}
		else
		{
			// this.loader.display(true);
			await this._bookingservice.getMetaData();
			console.log("metadata loaded");
			// this._bookingservice.getMetaData().then(val => {
			this.cityInfo = this.sharedService.getActiveCities();
				// this.loader.display(false);
			// }).catch(err => {
			// 	console.log(err);
			// });
			
		}
		this.loader.display(false);
	}
	loginNow() {
		this.loader.display(false);
		this.router.navigate(["/login"]);
	}

	filterLocalities() {
		if (this.locality === "All") {
			this.filteredSpaces = this.spaces;
			this.searchFilteredSpace = this.spaces;
		} else {
			this.filteredSpaces = this.spaces.filter(space => {
				return (space.address && space.address.locality) === this.locality;
			});
			this.searchFilteredSpace = this.filteredSpaces;
		}

		this.revenue.spacename = [this.filteredSpaces[0].spaceId];
		this.Calculate();
	}
	getSpaceDetails() {		
		return new Promise((resolve, reject) => {
			
			this._bookingservice
				.getSpaceDetails({
					city: this.spaceArea === "all" ? undefined : this.spaceArea,
					isArchived:'both'
				})
				.then((res: any) => {
					
					res = _.sortBy(res, r => r.spaceName);
					res = res.filter( space => space.isApproved == true );
					this.spaces = res;
					// for (let index = 0; index < this.spaces.length; index++) {
					//   const element = this.spaces[index];
					//   if (this.spaces[index].spaceId == null) {
					//   }
					// }
					this.filteredSpaces = res;
					this.searchFilteredSpace = res;
					const localities = _.uniq(
						res
							.map(r => (r.address ? r.address.locality : null))
							.filter(locality => locality)
					).sort();
					if (localities.length > 0) {
						this.locality = "All";
						this.localities = ["All"].concat(localities);
					} else {
						this.locality = "";
						this.localities = [];
					}
					if (this.spaces[0] && this.spaces[0].spaceId) {
						this.revenue.spacename = [this.spaces[0].spaceId];
					} else {
						this.revenue.spacename = [];
					}
					// this.Calculate();
					// this.loader.display(false);
					resolve(res);
				})
				.catch(err => {
					this.loader.display(false);
				});
		});
	}
	getdatetime(val: any) {
		return this.moment(val).format("DD/MM/YYYY, h:mm a");
	}

	downloadCSV() {
		const allbookings = [];
		this.revenueResult.forEach(value => {
			if (value.bookings) {
				allbookings.push(
					...value.bookings.map(el => ({
						...el,
						...{ spacename: value.spacename, gofloatersSpaceName : value.gofloatersSpaceName, spaceDisplayName : value.spaceDisplayName, spacetype: value.spacetype, spaceSubType : value.spaceSubType, city: value.city, originalName: value.originalName }
					}))
				);
			}
		});
		const bookingReportForDownload = [];
		const headings = [
			"Booking ID",
			"Space Name",
			"GoFloaters Space Name",
			"Original Space Name",
			"Space Display Name",
			"Space Type",
			"Space Sub Type",
			"City",
			"Booked by",
			"Booked by Email",
			"Revenue Share Percentage",
			"Booking Date & Time",
			"Request Date & Time",
			"Pass Type",
			"Duration",
			"Seats",
			"Payment Made",
			"Payment Ref Number",
			"Revenue Share W/O GST",
			"GST on Revenue Share",
			"Revenue Share with GST",
			"Settled On",
			"Settlement Reference Number"
		];
		for (let booking of allbookings) {
			var bookingObject = (({
				bookingid,
				spacename,
				gofloatersSpaceName,
				originalName,
				spaceDisplayName,
				spacetype,
				spaceSubType,
				city,
				customerName,
				customerEmail,
				partnerRevenueShare,
				bookingdate,
				requestdate,
				bookingType,
				duration,
				headCount,
				paymentMade,
				paymentRefNumber,
				partnerRevenueWithoutGst,
				partnerGst,
				partnerRevenue
			}) => ({
				bookingid,
				spacename,
				gofloatersSpaceName,
				originalName,
				spaceDisplayName,
				spacetype,
				spaceSubType,
				city,
				customerName,
				customerEmail,
				partnerRevenueShare,
				bookingdate,
				requestdate,
				bookingType,
				duration,
				headCount,
				paymentMade,
				paymentRefNumber,
				partnerRevenueWithoutGst,
				partnerGst,
				partnerRevenue
			}))(booking);

			if (typeof booking["bookingdate"] != "undefined") {
				bookingObject["bookingdate"] = this.moment(booking["bookingdate"])
					.tz("Asia/Kolkata")
					.format("DD/MM/YYYY, h:mm a");
			}
			if (typeof booking["requestdate"] != "undefined") {
				bookingObject["requestdate"] = this.moment(booking["requestdate"])
					.tz("Asia/Kolkata")
					.format("DD/MM/YYYY, h:mm a");
			}
			if (typeof booking["settlement"]["addedOn"] != "undefined") {
				bookingObject["settlementdate"] = this.moment(booking["settlement"]["addedOn"])
					.tz("Asia/Kolkata")
					.format("DD/MM/YYYY, h:mm a");
				bookingObject["settlementRefNumber"] = booking["settlement"]["settlementRefNumber"];
			} else {
				bookingObject["settlementdate"] = "";
				bookingObject["settlementRefNumber"] = "";
			}

			switch (booking.spacetype) {
				case "Restaurant":
				case "Cafe":
					if (booking.bookingType == "Day") {
						bookingObject["duration"] = "1 Day(s)";
					} else if (!booking.bookingType || booking.bookingType == "Hour") {
						if (booking.roundedDuration) {
							bookingObject["duration"] = booking.roundedDuration + " Hour(s)";
						} else {
							bookingObject["duration"] = booking.duration + " Hour(s)";
						}
					}
					break;
				case "Training Room":
				case "Conference Room":
						if (booking.bookingType == "Day") {
							bookingObject["duration"] = "1 Day(s)";
						} else {
							bookingObject["duration"] = booking.duration + " Hour(s)";
						}
					break;
				case "Shared Workspace":
					if (booking.bookingType == "Day") {
						bookingObject["duration"] = "1 Day(s)";
					} else if (booking.bookingType == "Month") {
						bookingObject["duration"] = booking.durationMonth + " Month(s)";
					}
					// bookingObject["duration"] = bookingObject["totalduration"];
					break;
				default:
					bookingObject["duration"] = 0;
					break;
			}

			bookingReportForDownload.push(bookingObject);
		}
		const options = {
			fieldSeparator: ",",
			quoteStrings: '"',
			decimalseparator: ".",
			showLabels: { headers: headings },
			showTitle: false,
			useBom: true
		};
		new Angular2Csv(
			bookingReportForDownload,
			"GoFloaters Revenue Share Report " + this.currentSearch.year,
			{ headers: headings }
		);
	}

	downloadPaymentSettlement() {
		const allpayments = [];
		this.revenueResult.forEach(value => {
				allpayments.push(
					{ spacename : value.spacename, amountpaid : value.revenueShare, rzrpayacct : value.razorPayAccoundId }
				);
		});
		const paymentSettlementReportForDownload = [];
		const headings = [
			"Space Name",
			"Razorpay Account Number",
			"Payout Amount",
			"Payout Currency",
			"Payout Mode",
			"Payout Purpose",
			"Payout Narration",
			"Fund Account Id",
			"Fund Account Type",
			"Fund Account Name",
			"Fund Account IFSC",
			"Fund Account Number",
			"Fund Account Vpa",
			"Contact Type",
			"Contact Name",
			"Contact Email",
			"Contact Mobile",
			"Contact Reference Id",
			"Notes[Place]",
			"Notes[Code]"
		];
		for (let payment of allpayments) {
			var paymentObject = (({
				spacename,
				gofRazorpayAcct,
				amountpaid,
				currency,
				mode,
				purpose,
				payoutNarration,
				rzrpayacct
				

			}) => ({
				spacename,
				gofRazorpayAcct,
				amountpaid,
				currency,
				mode,
				purpose,
				payoutNarration,
				rzrpayacct

				
			}))(payment);
			paymentObject.gofRazorpayAcct = 7878780035190380;
			paymentObject.currency = "INR";
			paymentObject.mode = "NEFT";
			paymentObject.purpose = "Payout"
			paymentObject.payoutNarration = '';
			if(payment["rzrpayacct"] == undefined) 
			{
				paymentObject.rzrpayacct = '';	
							
			}
			paymentSettlementReportForDownload.push(paymentObject);
		}
		const options = {
			fieldSeparator: ",",
			quoteStrings: '"',
			decimalseparator: ".",
			showLabels: { headers: headings },
			showTitle: false,
			useBom: true
		};
		new Angular2Csv(
			paymentSettlementReportForDownload,
			"GoFloaters Payment Settlement Report " + this.months[this.currentSearch.month - 1].name + ' ' + this.currentSearch.year,
			{ headers: headings }
		);
	}

	selectAll(checkAll, select: NgModel, values) {
		if (checkAll) {
			select.update.emit(values.map(value => value.spaceId));
		} else {
			select.update.emit([]);
		}
	}

	searchSpace(e) {
		this.searchSpaceString = e.target.value;
		if (e.key === ' ') {
			this.searchSpaceString += ' ';
		}
		if (!this.searchSpaceString) {
			this.searchFilteredSpace = this.filteredSpaces;
		} else {
			this.searchFilteredSpace = this.filteredSpaces
				.filter(space => space.spaceName && space.spaceName.toUpperCase().includes(this.searchSpaceString.toUpperCase()));
		}
	}

	searchOrigNameSpace(e) {
		this.searchOriginalSpaceString = e.target.value;
		if (e.key === ' ') {
			this.searchOriginalSpaceString += ' ';
		}
		if (!this.searchOriginalSpaceString) {
			this.searchFilteredSpace = this.filteredSpaces;
		} else {
			this.searchFilteredSpace = this.filteredSpaces
				.filter(space => space.originalName && space.originalName.toUpperCase().includes(this.searchOriginalSpaceString.toUpperCase()));
		}
	}

	searchGoFName(e) {
		this.searchGoFNameString = e.target.value;
		if (e.key === ' ') {
			this.searchGoFNameString += ' ';
		}
		if (!this.searchGoFNameString) {
			this.searchFilteredSpace = this.filteredSpaces;
		} else {
			this.searchFilteredSpace = this.filteredSpaces
				.filter(space => space.gofloatersSpaceName && space.gofloatersSpaceName.toUpperCase().includes(this.searchGoFNameString.toUpperCase()));
		}
	}

	searchSpaceDispName(e) {
		this.searchSpaceDispNameString = e.target.value;
		if (e.key === ' ') {
			this.searchSpaceDispNameString += ' ';
		}
		if (!this.searchSpaceDispNameString) {
			this.searchFilteredSpace = this.filteredSpaces;
		} else {
			this.searchFilteredSpace = this.filteredSpaces
				.filter(space => space.spaceDisplayName && space.spaceDisplayName.toUpperCase().includes(this.searchSpaceDispNameString.toUpperCase()));
		}
	}

	Calculate() {
		this.currentSearch = { ...this.revenue };
		this.revenueShare = 0.00;
		this.revenueShareWOGst = 0.00;
		this.gstonRevenue = 0.00;
		this.grossRevenueWGst = 0.00;
		// this.totalAmountPaid = 0.00;
		this.loader.display(true);
		this.revenueResult = [];
		const year = this.revenue.year;
		const month = this.revenue.month;
		const spacename = this.revenue.spacename;
		let startDate, endDate;
		if (this.useDateFilter) {
			this.fromDate.setHours(0, 0, 0, 0);
			this.toDate.setHours(23, 59, 59, 59);
			startDate = this.fromDate.getTime();
			endDate = this.toDate.getTime();
		} else {
			startDate = this.moment([year, month - 1]);
			endDate = this.moment(startDate).endOf("month");
		}
		let spaceList = [];
		if (!this.allSpacesFlag) {
			if (spacename === "All Spaces") {
				spaceList = this.spaces.map(space => space.spaceId);
			} else {
				spaceList = spacename;
			}
			spaceList = spaceList.filter(space => space);
		} 
		if (this.allSpacesFlag) {
			// reportreq["allSpacesFlag"] = true;
			spaceList = this.spaces.map(space => space.spaceId);
		}
		const reportreq = {
			spaceOwnerId: this.SpaceOwnerId,
			spaces: spaceList,
			starttime: startDate.valueOf(),
			endtime: endDate.valueOf()
		};
		
		this._bookingservice
			.getRevenueReport(reportreq)
			.then((val: any) => {
				// this.totalRevenue = val.totalRevenue;
				this.revenueShare = val.revenueShare.toFixed(2);
				this.revenueShareWOGst = val.partnerShareWoGST.toFixed(2);
				this.gstonRevenue = val.gstOnRevenue.toFixed(2);
				this.grossRevenueWGst = val.totalRevenue.toFixed(2);
				// this.totalAmountPaid = val.totalRevenue;
				this.paymentBtn = Number(this.revenueShareWOGst) > 0 ? true : false;
				spaceList.forEach(spaceid => {
					
					if (val[spaceid]) {
						const spaceDetail = this.spaces.find(
							space => space.spaceId === spaceid
						);
						const revenueVal = val[spaceid];
						revenueVal.spacename = spaceDetail.spaceName;
						revenueVal.gofloatersSpaceName = spaceDetail.gofloatersSpaceName ? spaceDetail.gofloatersSpaceName : 'N/A';
						revenueVal.spaceDisplayName = spaceDetail.spaceDisplayName ? spaceDetail.spaceDisplayName : 'N/A';
						revenueVal.spaceSubType = spaceDetail.spaceSubType ? spaceDetail.spaceSubType.toString() : 'N/A';
						revenueVal.originalName = spaceDetail.originalName;
						revenueVal.partnerRevenueShare = revenueVal.partnerRevenueShare.toFixed(2);
						revenueVal.partnerShareWoGST = revenueVal.partnerShareWoGST.toFixed(2);
						revenueVal.gstOnRevenue = revenueVal.gstOnRevenue.toFixed(2);
						revenueVal.revenueShare = revenueVal.revenueShare.toFixed(2);
						revenueVal.totalRevenue = revenueVal.totalRevenue.toFixed(2);
						revenueVal.spacetype = spaceDetail.spaceType;
						revenueVal.city = spaceDetail.city;
						revenueVal.isGstRegistered = revenueVal.gstRegistered;
						this.revenueResult.push(revenueVal);
					}
				});
				this.loader.display(false);
			})
			.catch(err => {
				this.loader.display(false);
			});
	}
	change(event) {
		
		if(this.settlement.indexOf(event.source.value)> -1) {
			this.settlement.splice(this.settlement.indexOf(event.source.value), 1);
		} else {
			this.settlement.push(event.source.value);
		}
	}
	AddSettle() {
		
		if(this.settlement.length > 0) {
			this.dialog.open(AddSettlementComponent, {
				data: { },
				width: '400px',
				height: '250px'
			}).afterClosed().subscribe(updateRequired => {
				if (updateRequired) {
					this.loader.display(true);
					this._bookingservice.updateBookingSettlement({
						bookingIds:this.settlement,
						ref_number:updateRequired.ref_number,
						ref_date:updateRequired.ref_date
					}).then((res: any) => {
						this.loader.display(false);
						this.settlement = [];
						this.Calculate();
					}).catch((e) =>{
						this.loader.display(false);
						this.settlement = [];
					});
				}	
			});
		}
		
	}
	getSettlementStatus(booking) {
		let settlementStatus = "";
		// Set the booking settlement flag
		if (!booking.settlement) {
		  settlementStatus =  "Yet to request";    
		} else {
		  	  if (booking.settlement.settlementRefNumber) {
				settlementStatus = "Settled";
			  } else if (booking.settlement.settlementStatus == "Requested") {
				settlementStatus = "Requested";
			  } else {
				settlementStatus =  "Yet to request"; 
			  }
			}
		  
		return settlementStatus;
	  }
}
