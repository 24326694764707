import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AngularFireDatabase } from '@angular/fire/database';
import { LoaderService } from '../sharedcomponents';

@Component({
  selector: 'app-add-searchcollection',
  templateUrl: './add-searchcollection.component.html',
  styleUrls: ['./add-searchcollection.component.scss']
})
export class AddSearchCollectionComponent implements OnInit {
  editMode: Boolean = false;
  spaceSuggestionId: String = "";
  searchTitle: String = "";
  searchDescription:String = "";
  spaceIds: String = "";
  private searchCollection;
  // availablePartner: any[] = [];
  public errorMessage: String = '';
  public showSuccessDialog: Boolean = false;
  public showerrorDialog: Boolean = false;
  public showSuccessDialog1: Boolean = false;
  constructor(
    private db: AngularFireDatabase,
    private loader: LoaderService,
    private dialogRef : MatDialogRef<AddSearchCollectionComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    if (data) {
      this.editMode = data.editMode;
      this.spaceSuggestionId = data.spaceSuggestionId;
      // const searchCollection = data.searchCollection;
      this.searchTitle = data.searchTitle;
      this.searchDescription = data.searchDescription;
      // this.offerSummary = organizations.offerSummary;
      this.spaceIds = data.spaceIds?data.spaceIds.flat():'';
    }
    if (this.editMode == false) {
      this.searchDescription = "GoFloaters is India's widest network of meeting and training spaces. I thank you for the opportunity to be able to serve your needs. I recommend the below spaces that match your requirements. The rates mentioned are rack rates. I will be able to work out special rates after you confirm which space you want to use."
    }
  }
  ngOnInit() {

  }

  addSearchCollection() {
    const {spaceSuggestionId} = this;
    const ref = this.db.database.ref('spaceSuggestions/' + spaceSuggestionId);
    ref.once("value", snapshot => {
     this.searchCollection = snapshot.val();
    if(!this.searchCollection){
      this.loader.display(true)
    const data = {
      dateAdded : `${new Date().getTime()}`,
      searchTitle: this.searchTitle,
      searchDescription:this.searchDescription,
      spaceIds: this.spaceIds.split(','),
      active: true,
      spaceSuggestionId:spaceSuggestionId,
      spaceType:"meeting-space",
      searchBottomText: "<p>Welcome to GoFloaters' Meeting Rooms – where seamless collaboration meets affordability! 🚀</p>  <p>Looking for the perfect space for team meetings, sales pitches, presentations, brainstorming, or discussions? Look no further! Our meeting rooms are tailored to suit all your professional needs.</p>  <ul>   <li><strong>Right Sized Rooms:</strong> Whether you need an intimate space or a room for the whole team, we've got you covered. Choose from a variety of sizes that match your specific requirements.</li>      <li><strong>Right Priced Rooms:</strong> Enjoy premium meeting spaces without burning a hole in your budget! Our rooms are designed to offer top-notch quality at wallet-friendly prices, making it the smart choice for your business.</li>      <li><strong>Instant Bookings:</strong> Say goodbye to waiting times! With our hassle-free online booking system, you can reserve your meeting room, make the payment, and receive instant confirmations – all in a few clicks.</li>      <li><strong>Free Cancellations:</strong> We understand plans can change. That's why we offer free cancellations, providing you with flexibility and peace of mind.</li>      <li><strong>Free Rescheduling:</strong> Need to reschedule? No worries! Make changes to your booking without any extra charges. Your convenience is our priority.</li>      <li><strong>Lowest Price Guarantee:</strong> We're committed to giving you the best value. If you find a lower price for a similar meeting room elsewhere, let us know, and we'll match it!</li> </ul>  <p>Hurry, take advantage of our special 15% discount offer! 🎉 Book your meeting room now and elevate your work collaboration to new heights!</p>"
    }
    console.log("Search collection being added",data);
    const newSearchCollection = this.db.database.ref('spaceSuggestions/' + spaceSuggestionId);
    newSearchCollection.set(data).then(() => {
      console.log("Search Collection added");
      this.loader.display(false);
      this.errorMessage = '';
      this.showSuccessDialog = true;
      this.showSuccessDialog1 = false;
      this.showerrorDialog = false;
    }).catch(() => {
      this.loader.display(false);
      this.errorMessage = 'Server error!...';
      this.showSuccessDialog = false;
      this.showSuccessDialog1 = false;
      this.showerrorDialog = true;
    });
    }
    else{
      this.errorMessage = 'Search Collection Id is already exists';
        this.showSuccessDialog = false;
      this.showSuccessDialog1 = false;
        this.showerrorDialog = true;
    }
    })
  }

  editSearchCollection() {
    this.loader.display(true);
    const Updates = this.db.database.ref('spaceSuggestions/' + this.spaceSuggestionId);
    // Updates.once("value", snapshot => {
      // var updates = {};
      // snapshot.forEach(childSnapShot => {
      //   var comment = childSnapShot.val();
      //   const data = {
      //     partnerName: this.partnerName,
      //     partnerDescription: this.partnerDescription,
      //     partnerLogoUrl: this.partnerLogoUrl,
      //     offerSummary: this.offerSummary,
      //     offerDetailUrl: this.offerDetailUrl,
      //     priority : this.priority
      //   }
      //   updates = data;
      // })
      const updatedData = {
        searchTitle: this.searchTitle,
        searchDescription:this.searchDescription,
        spaceIds: this.spaceIds.split(',')
      }
      // updates = data;
      Updates.update(updatedData).then(() => {
        console.log("Search Collection updated");
        this.loader.display(false);
        this.errorMessage = '';
        this.showSuccessDialog = false;
        this.showSuccessDialog1 = true;
        this.showerrorDialog = false;
      }).catch(() => {
        this.loader.display(false);
        this.errorMessage = 'Server error!...';
        this.showSuccessDialog = false;
      this.showSuccessDialog1 = false;
        this.showerrorDialog = true;
      });
    // })
  }

  success() {
		this.dialogRef.close(true);
	}

	error() {
		this.dialogRef.close(false);
  }
  
}
