import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../sharedcomponents/index';
import { BookingService } from '../appservices/booking.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import * as moment from 'moment-timezone';
import * as _ from 'underscore';
import { AddVoucherComponent } from "../add-voucher/add-voucher.component";
import { MatDialog } from '@angular/material';

@Component({ selector: 'app-voucher-list', templateUrl: './voucher-list.component.html', styleUrls: ['./voucher-list.component.scss'] })
export class VoucherListComponent implements OnInit {
	public filteredVouchers = [];
	public moment: any = moment;
	public fromRequestDate: any = this.moment().tz('Asia/Kolkata').format('YYYY-MM-DD');
	public toRequestDate: any = this.moment().tz('Asia/Kolkata').format('YYYY-MM-DD');

	constructor(
		private loader: LoaderService,
		private _bookingservice: BookingService,
		private dialog: MatDialog
	) { }

	ngOnInit() {
		this.fetchVoucherDetails();
	}
	addVoucher() {
		this.dialog.open(AddVoucherComponent, {
		}).afterClosed().subscribe(updateRequired => {
			if (updateRequired) {
				this.fetchVoucherDetails();
			}
		});
	}

	editVoucher(voucher) {
		this.dialog.open(AddVoucherComponent, {
			data: {
				editMode: true,
				voucher: {
					...voucher,
					ExpiresOn: new Date(voucher.ExpiresOn),
					creationDate: voucher.creationDateWithoutMS,
					creationDateWithoutMS: null
				}
			}
		}).afterClosed().subscribe(updateRequired => {
			if (updateRequired) {
				this.fetchVoucherDetails();
			}
		});
	}

	fetchVoucherDetails() {
		const fromDateTimestamp = Math.floor(Number(this.moment(this.fromRequestDate).tz('Asia/Kolkata')) / 1000);
		const toDateTimestamp = Math.floor(Number(this.moment(this.toRequestDate).tz('Asia/Kolkata') + 24 * 60 * 60 * 1000) / 1000);
		this.loader.display(true);
		this._bookingservice.getVoucherList({
			fromDateTimestamp,
			toDateTimestamp
		}).then((vouchers: any) => {
			if (Array.isArray(vouchers)) {
				this.filteredVouchers = vouchers.map(voucher => ({
					...voucher,
					creationDateWithoutMS: voucher.creationDate,
					ExpiresOn: voucher.ExpiresOn * 1000,
					creationDate: voucher.creationDate * 1000,
					redeemedOn: voucher.redeemedOn ?  voucher.redeemedOn * 1000 : undefined
				}));
			} else {
				this.filteredVouchers = [];
			}
			this.loader.display(false);
		}).catch(e => {
			this.filteredVouchers = [];
			this.loader.display(false);
		});
	}


	downloadCSV() {

		function checkDate(date) {
			if (date === undefined) {
				return '';
			}
			date = new Date(date);
			if (isNaN(date.getTime())) {
				return 'Not Redeemed';
			}
			return date.toString();
		}

		const vouchersForDownload = [];
		const headings = ['Voucher Code', 'Creation Date', 'Expires On', 'Total Points', 'Floating Points','Food Credits','Redeemed By', 'Redeemed On', 'Status'];
		for (const voucher of this.filteredVouchers) {
			const {
				voucherCode,
				creationDate,
				ExpiresOn,
				totalPoints,
				floatingPointsPercent,
				foodCreditsPercent,
				redeemedOn = 'Not Redeemed',
				status,
				user
			} = voucher;
			vouchersForDownload.push({
				voucherCode,
				creationDate: checkDate(creationDate),
				ExpiresOn: checkDate(ExpiresOn),
				totalPoints,
				floatingPointsPercent,
				foodCreditsPercent,
				user: user ? user.displayName : 'Not Redeemed',
				redeemedOn: checkDate(redeemedOn),
				status
			});
		}
		new Angular2Csv(vouchersForDownload, 'GoFloaters Vouchers', { headers: headings });
	}
}
