import { Component, OnInit } from '@angular/core';
import { BookingService } from '../appservices/booking.service';
import { LoaderService } from '../sharedcomponents/index';
import * as _ from 'underscore';
import * as moment from 'moment';
import {MatDialog} from '@angular/material';
import {CaptureRazorpayAmountComponent} from '../capture-razorpay-amount/capture-razorpay-amount.component';

@Component({
  selector: 'app-razorpay-transactions',
  templateUrl: './razorpay-transactions.component.html',
  styleUrls: ['./razorpay-transactions.component.scss']
})
export class RazorpayTransactionsComponent implements OnInit {

  fromDate: Date;
  toDate: Date;
  transactions: any = [];
  clipboard: any;
  public moment: any = moment;
  constructor(
    public _bookingservice: BookingService,
    private loader: LoaderService,
    private dialog: MatDialog,
  
    ) {}


   ngOnInit() {
      this.fromDate = this.toDate = new Date();
      this.getTransactions(this.fromDate,this.toDate)
   }
  
  
  getTransactions(FromDate,ToDate) {
    let searchData, start, end;
    start = this.format(FromDate);
    let temp = new Date();
    temp.setDate(ToDate.getDate() + 1)
    end = this.format(temp);        
    searchData = {
      from: start,
      to: end
    }
    this.loader.display(true);
    this._bookingservice.getPaymentList(searchData).then((res:any) => {
      this.loader.display(false);
      this.transactions = res.response['items'];
      this.transactions = _.sortBy(this.transactions, t => t.created_at);
    }).catch(e => {
      this.loader.display(false);
      this.transactions = [];
    });

  }

  format(date: Date): string {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return year + '-' + this.to2digit(month) + '-' + this.to2digit(day);
    } 

  to2digit(n: number) {
  return ('00' + n).slice(-2);
  } 

  getDateTime(val) {
    return this.moment(val*1000).format('DD/MM/YYYY, h:mm a');
  }
  openCaptureModal(transactiondata){
    this.dialog.open(CaptureRazorpayAmountComponent, {
      data: {
        transactiondata 
      }
      }).afterClosed().subscribe(response => {
        if(response){
          this.getTransactions(this.fromDate,this.toDate)
        }
      });
  }
}
