import { Component, OnInit } from '@angular/core';
import { LoaderService } from './sharedcomponents/index';

// import * as firebase from "firebase/app";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
public showLoader = false;
constructor(
  private loaderService: LoaderService
  ) {

}
  ngOnInit() {
    this.loaderService.status.subscribe((val: boolean) => {
      if (val) {
        this.showLoader = true;
      } else {
        this.showLoader = false;
      }
  });
  }
}
