import {Component, OnInit} from '@angular/core';
import {BookingService} from '../appservices/booking.service';
import {LoaderService} from '../sharedcomponents/index';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';

import { FcmCloudMessagingService } from '../appservices/fcm-cloud-messaging.service';
import { LoginService } from '../appservices/login.service';
import { FoodCreditService } from '../appservices/foodcredit.service';
import { SharedProvider } from '../appservices/shared';
@Component({
  selector: 'app-foodcredits-purchase-report',
  templateUrl: './foodcredits-purchase-report.component.html',
  styleUrls: ['./foodcredits-purchase-report.component.scss']
})
export class FoodcreditsPurchaseReportComponent implements OnInit {
  public user: any;
  public moment : any = moment;
  foodCreditsPurchased = [];
  searchby : any = "purchaseDate";
  searchvalue : any; 
  fromDate : any = this
    .moment().tz('Asia/Kolkata')
    .format('YYYY-MM-DD');
  toDate : any = this
    .moment().tz('Asia/Kolkata')
    .format('YYYY-MM-DD');

  years: any = [];
  months: any = [
    {
      name: 'January',
      value: 1
    },
    {
      name: 'February',
      value: 2
    },
    {
      name: 'March',
      value: 3
    },
    {
      name: 'April',
      value: 4
    },
    {
      name: 'May',
      value: 5
    },
    {
      name: 'June',
      value: 6
    },
    {
      name: 'July',
      value: 7
    },
    {
      name: 'August',
      value: 8
    },
    {
      name: 'September',
      value: 9
    },
    {
      name: 'October',
      value: 10
    },
    {
      name: 'November',
      value: 11
    },
    {
      name: 'December',
      value: 12
    }
  ];
	selectedYear: number;
	selectedMonth: number;

  constructor(
    private router: Router,
    private loader : LoaderService,
    public message : FcmCloudMessagingService,
    public sharedService: SharedProvider,
    private loginService :LoginService,
    private _foodcreditservice:FoodCreditService
  ) {}

  ngOnInit() {
    this.moment.tz.setDefault('Asia/Kolkata');
    this.user = this.loginService.getCurrentUser();
    if (this.user.uid !== undefined) {
      this.selectedYear =  new Date().getFullYear();
      this.selectedMonth = new Date().getMonth() + 1;
      for (let index = 2018; index <= this.selectedYear; index++) {
        this.years.push(index);
      }
    //  this.message.enableNotifications(this.loginService.getCurrentUser());
      this.sharedService.isLoggedIn = true;
      let requestDateTimestamp = new Date(this.fromDate).getTime(); 
      //Do GMT offset
      requestDateTimestamp = requestDateTimestamp - 5.5*60*60*1000;
      let searchData;
      searchData = {
      searchby : this.searchby,
      startDate : requestDateTimestamp,
      endDate : requestDateTimestamp
    }
     // this.getFoodCreditsPurchaseDetails(searchData);      
    } else {
      this.loginNow();
    } 
  }
 
  getFoodCreditsPurchaseDetails(searchData) {
    this
      .loader
      .display(true);
    this
      ._foodcreditservice
      .getFoodCreditPurchaseList(searchData)
      .then((res:any) => {
        this.foodCreditsPurchased = res;
        this
          .loader
          .display(false);
      });

  }

  loginNow() {
    this.loader.display(false);
    this.router.navigate(['/login']);
  }

  filterdata() {
    let searchData;
    if(this.searchby === 'purchaseDate'){
      let purchaseDate = new Date(this.fromDate).getTime();
      //Do GMT offset
      purchaseDate = purchaseDate - 5.5*60*60*1000;
      searchData = {
        searchBy : this.searchby,
        starttime : purchaseDate,
        endtime : purchaseDate
      }
    }
    else if(this.searchby === 'purchaseDateRange')
    {
      let fromDateTimestamp = new Date(this.fromDate).getTime();
      let toDateTimestamp = new Date(this.toDate).getTime();
      //Do GMT offset
      fromDateTimestamp = fromDateTimestamp - 5.5*60*60*1000;
      toDateTimestamp = toDateTimestamp - 5.5*60*60*1000;
      searchData = {
        searchBy : this.searchby,
        starttime : fromDateTimestamp,
        endtime: toDateTimestamp
      }
    }
    else if (this.searchby === 'purchaseMonth') {
      const fromDateTimestamp = moment([this.selectedYear, this.selectedMonth - 1]).toDate().getTime();
      const toDateTimestamp = moment(fromDateTimestamp).endOf('month').toDate().getTime();
      searchData = {
        searchBy : this.searchby,
        starttime : fromDateTimestamp,
        endtime: toDateTimestamp
      };
    }
    else {
      searchData = {
        searchBy : this.searchby,
        customerEmail : this.searchvalue
      }
    }
    this.getFoodCreditsPurchaseDetails(searchData);
  }
  
  getdatetime(val) {
    return this
      .moment(val).tz('Asia/Kolkata')
      .format('DD/MM/YYYY, h:mm a');
  }
  
}
