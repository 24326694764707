import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'booleanConverter'})
export class BooleanConverterPipe implements PipeTransform {
  transform(value: any): string {
    if (typeof value === 'boolean') {
      if (value) {
        return 'Yes';
      } else {
        return 'No';
      }
    }
    return value;
  }
}
