
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {auth} from "firebase/app";
import { AppConstants } from '../app.constants';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable()
export class LoginService {
  public serviceUrl: any = {};
  currentUser: any = {};
  baseUrl: any;
  public user: Observable<Object>;
  private userdetails: any;
  constructor(private http: HttpClient, public _ANGFIRE: AngularFireAuth, private db: AngularFireDatabase,) {
    // this.serviceUrl = AppConstants.CONSTANTS.loginServices;
    this.baseUrl = AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].appurl;
  }
  // login(email, password) {
  //     const data = {
  //         email: email,
  //         password: password
  //     };
  //     //const baseurl = 'https://us-central1-gofloaters.cloudfunctions.net/api/login';
  //     // const baseurl = 'https://us-central1-gofloatersdev.cloudfunctions.net/api/login';        
  //     // const baseurl = 'http://localhost:5000/gofloatersdev/us-central1/api/login';
  //     const baseurl = AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].loginurl;
  //     const headers = new Headers({ 'Content-Type': 'application/json' });
  //     const options = new RequestOptions({ headers: headers });
  //     return this.http.post(baseurl, JSON.stringify(data)
  //     .map((response: Response) => {
  //         this.currentUser = response["user"];
  //         return response;
  //     }).catch(this.handleErrorObservable);
  // }
  logout() {

  }
  getCurrentUser() {
    return this.currentUser;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return observableThrowError(error.message || error);
  }
  async getUserDetails(user): Promise<any> {
    return new Promise((resolve, reject) => {
      return this
        .http
        .post(this.baseUrl + "/getuserdetails", user)
        .subscribe((values: any) => {
          this.userdetails = values
          resolve(values);
        }, err => {
          resolve({})
        })
    })
  }
  async getUserDetailsRealtime(userId): Promise<any> {
    // console.log("Fetching user details", userId);
    let that = this;
    let userRef = this.db.database.ref("/users/" + userId);
    return new Promise((resolve, reject) => {
      return userRef
        .once("value", function (snapshot) {
          let user = snapshot.val();
          resolve(user);
        })
    })
  }

  setCurrentUser(user) {
    this.currentUser = user;
  }

  async loginWithEmailAndPassword(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this
        ._ANGFIRE
        .auth
        .signInWithEmailAndPassword(email, password)
        .then((val: any) => {
          this
            .getUserDetails({ id: val.user.uid })
            .then(data => {
              this.setCurrentUser(data)
              // this.userdetails = data;
              resolve(data);
            })
        })
        .catch((err: any) => {
          let message: any;
          if (err.code == "auth/network-request-failed") {
            message = 'Please connect to the internet';
          } else if (err.code == 'auth/invalid-email') {
            message = 'Please enter valid email';
          } else if (err.code == 'auth/user-not-found') {
            message = 'Please enter valid email and password';
          } else {
            message = 'Bad credentials';
          }
          reject(message);
        });
    });
  }

  async loginWithGmail(): Promise<any> {
    return new Promise((resolve, reject) => {
      const provider = new auth.GoogleAuthProvider();
      this._ANGFIRE.auth.signInWithPopup(provider)
        .then((val: any) => {
          this
            .getUserDetails({ id: val.user.uid })
            .then(data => {
              this.setCurrentUser(data)
              // this.userdetails = data;
              resolve(data);
            })
        })
        .catch((err: any) => {
          let message: any;
          if (err.code == "auth/network-request-failed") {
            message = 'Please connect to the internet';
          } else if (err.code == 'auth/invalid-email') {
            message = 'Please enter valid email';
          } else if (err.code == 'auth/user-not-found') {
            message = 'Please enter valid email and password';
          } else {
            message = 'Bad credentials';
          }
          reject(message);
        });
    });
  }
}
