import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild , ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from "firebase/app";
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { SharedProvider } from './shared';
import { LoaderService } from '../sharedcomponents';
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

constructor(public firebaseAuth: AngularFireAuth,
  private loginservice: LoginService,
    private sharedService: SharedProvider,
    private router: Router,
    private loader: LoaderService
  ){

}
  canActivate():any {
    firebase
    .auth()
    .onAuthStateChanged((user) => {
      if (user && user.uid) {
        return true;
      }else {
          return false;
      }
    },err => {
        return false;
    });
    
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.sharedService.isLoggedIn) {
        resolve(true);
      } else {
        
        this.loader.display(true);
        firebase.auth().onAuthStateChanged((user:firebase.User) => {
          if (user) {
            if (user && user.uid) {
              this
                .loginservice
                .getUserDetails({
                  id: user.uid
                })
                .then((data) => {
                  this.loginservice.setCurrentUser(data);
                  this.sharedService.isLoggedIn = true;
                  this.loader.display(false);
                  resolve(true);
                })
            } else {
              this.loader.display(false);
              this.router.navigate(['/login']);
              resolve(false);
            }

          } else {
            this.loader.display(false);
            this.router.navigate(['/login']);
            resolve(false);
          }
        });
      }
    });
  }

}