import { Component, OnInit, Input, Output, OnChanges, EventEmitter, Inject } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {  MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
@Component({
  selector: 'app-add-settlement',
  templateUrl: './add-settlement.component.html',
  styleUrls: ['./add-settlement.component.scss'],
  animations: [
    trigger('settlement', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class AddSettlementComponent implements OnInit {
  public ref_number:string;
  public ref_date:any;
  constructor(private dialogRef: MatDialogRef<AddSettlementComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() { }

  close() {
    if(this.ref_number) {
        this.dialogRef.close({ref_number:this.ref_number,ref_date:this.ref_date});
    } else {
        this.dialogRef.close();
    }
  }
}