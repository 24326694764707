import {
  Component,
  Inject,
  OnInit,
  ElementRef,
  NgZone,
  ViewChild
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { LoaderService } from "../sharedcomponents/index";
import { BookingService } from "../appservices/booking.service";
import { LoginService } from "../appservices/login.service";
import * as $ from "jquery";
import { FormControl } from "@angular/forms";
import * as firebase from "firebase";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AppConstants } from "../app.constants";

@Component({
  selector: "app-edit-booking",
  templateUrl: "./edit-booking.component.html",
  styleUrls: ["./edit-booking.component.scss"]
})
export class EditBookingComponent implements OnInit {
  bookingKey: any;
  public booking: any = {};
  errorMessage: String;
  showDialog = false;
  showSuccessDialog = false;
  showerrorDialog = false;
  showerrordbDialog = false;
  showFPerrorDialog = false;
  checkinTime: any;
  checkoutTime: any;
  bookingTime: any;
  headCount : any;
  totalduration: any;
  space: any;
  spaceObject: any;
  spacesList: any[];
  public moment: any = moment;
  filteredOptions: Observable<any[]>;
  myControl = new FormControl();
  cafeBooking: Boolean = false;
  hourPass: Boolean = false;
  dayPass: Boolean = false;
  oldBookingStatus: String = "";
  requireUpdate: Boolean = false;
  showMandatoryFields: Boolean = false;
  mandatoryFieldsErrorMessage: String = "";
  private bookingSuperUsers = AppConstants.CONSTANTS.bookingSuperUsers;
  public isSuperUser:boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _bookingservice: BookingService,
    private loader: LoaderService,
    private ngZone: NgZone,
    private dialogRef: MatDialogRef<EditBookingComponent>,
    public loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.bookingKey = data.bookingKey;
  }
  ngOnInit() {
    //   this.bookingKey = this.route.snapshot.params['id'];
    const user = this.loginService.getCurrentUser();
    if (this.bookingSuperUsers.includes(user.email)) {
			this.isSuperUser = true;
		}
    console.log("Current user",user,this.isSuperUser);
    this.spacesList = [];
    if (this.bookingKey) {
      setTimeout(() => {
        $("div.label-floating").toggleClass("is-empty");
      }, 1000);
      // this.checkinTime = new Date();
      this.viewBooking();
    }
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.spacesList.filter(
      option => option.gofloatersSpaceName.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  private checkDayPass() {
    if (this.checkCafeBooking() && this.booking.bookingType === "Day") {
      return true;
    }
    return false;
  }

  private checkHourPass() {
    if (this.checkCafeBooking() && this.booking.bookingType === "Hour") {
      return true;
    }
    return false;
  }

  private checkCafeBooking() {
    if (this.booking) {
      return ["Cafe", "Restaurant"].includes(this.booking.spaceType);
    }
    return false;
  }

  viewBooking() {
    this.loader.display(true);
    const data = {
      bookingKey: this.bookingKey
    };
    this._bookingservice.getbooking(data).then(res => {
      if (res) {
        this.booking = res;
        
        this.oldBookingStatus = this.booking.status;
        this.cafeBooking = this.checkCafeBooking();
        this.dayPass = this.checkDayPass();
        this.hourPass = this.checkHourPass();
        if (this.booking.checkin) {
          this.checkinTime = this.getdatetime(this.booking.checkin);
        }
        if (this.booking.checkout) {
          this.checkoutTime = this.getdatetime(this.booking.checkout);
        }

        this.bookingTime = this.getdatetime(this.booking.startTime);
        this.totalduration = this.booking.duration;
        this.spaceObject = {
          gofloatersSpaceName: this.booking.gofloatersSpaceName,
          spaceId: this.booking.spaceId
        };
        this.loadAvailableSpace();
        this.loader.display(false);
      }
    });
  }
  getdatetime(val) {
    return this.moment(val).format("YYYY-MM-DDTHH:mm");
  }

  async loadAvailableSpace() {
    const payload = {
      city: this.booking.spaceaddress.city,
      isOnLine: true
    };
    try {
      this.spacesList = await this._bookingservice.getSpaceDetails(payload);
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith<string | any>(""),
        map(value => {
          return typeof value === "string" ? value : value.gofloatersSpaceName;
        }),
        map(name => {
          return name ? this._filter(name) : [...this.spacesList];
        })
      );
    } catch (e) {
      this.spacesList = [];
    }
  }

  displayFn(space) {
    return space ? space.gofloatersSpaceName : undefined;
  }

  updateSpace() {
    const data = {
      id: this.spaceObject.spaceId
    };
    this._bookingservice.getSpace(data).then(res => {
      this.space = res;

      (this.booking.spaceId = this.space.spaceId),
        (this.booking.spaceName = this.space.spaceName),
        (this.booking.gofloatersSpaceName = this.space.gofloatersSpaceName),
        (this.booking.spaceType = this.space.spaceType),
        (this.booking.spaceaddress = this.space.address),
        (this.booking.spaceOwnerEmail = this.space.ownerEmail),
        (this.booking.spacecoords = this.space.location),
        (this.booking.priceperhr = this.space.priceperhr),
        (this.booking.originalName  = this.space.originalName),
        (this.booking.pricepermonth = this.space.pricepermonth);
    });
  }

  checkMandatoryFields(booking: any, fields: string[]) {
    for (let index = 0; index < fields.length; index++) {
      const value = booking[fields[index]];
      if (value === null || isNaN(value)) {
        return fields[index];
      }
    }
    return null;
  }

  // validateFields() {
  //   const { booking } = this;
  //   if (this.cafeBooking) {
  //     // For Cafe and Restaurant
  //     if (booking.status === "CheckoutInitiated") {
  //       const value = this.checkMandatoryFields(booking, [
  //         "checkin",
  //         "checkout"
  //       ]);
  //       if (value === null) {
  //         return true;
  //       }
  //       this.showMandatoryFields = true;
  //       this.mandatoryFieldsErrorMessage = `${value} is required`;
  //       return false;
  //     } else if (booking.status === "Checkout") {
  //       let value = this.checkMandatoryFields(booking, [
  //         "checkin",
  //         "checkout",
  //         "paymentMade",
  //         "roundedDurationHours",
  //         "gstFee"
  //       ]);
  //       if (value === null && booking.paymentRefNumber) {
  //         return true;
  //       } else if (!booking.paymentRefNumber) {
  //         value = "paymentRefNumber";
  //       }
  //       this.showMandatoryFields = true;
  //       this.mandatoryFieldsErrorMessage = `${value} is required`;
  //       return false;
  //     }
  //     return true;
  //   } else {
  //     // For Shared Space and Meeting rooms
  //     if (booking.status === "Prepaid") {
  //       let value = this.checkMandatoryFields(booking, [
  //         "paymentMade",
  //         "gstFee"
  //       ]);
  //       if (value === null && booking.paymentRefNumber) {
  //         return true;
  //       } else if (!booking.paymentRefNumber) {
  //         value = "paymentRefNumber";
  //       }
  //       this.showMandatoryFields = true;
  //       this.mandatoryFieldsErrorMessage = `${value} is required`;
  //       return false;
  //     }else {
  // 	  return true;
  //   }
  //   }
  // }

  isNumber(number) {
    return typeof number === "number";
  }
  validateFields() {
    const { booking } = this;
    if (this.cafeBooking) {
      // For Cafe and Restaurant
      if (booking.status === "CheckoutInitiated") {
        const value = this.checkMandatoryFields(booking, [
          "checkin",
          "checkout"
        ]);
        if (value === null) {
          return true;
        }
        this.showMandatoryFields = true;
        this.mandatoryFieldsErrorMessage = `${value} is required`;
        return false;
      } else if (booking.status === "Checkout") {
        let value = this.checkMandatoryFields(booking, [
          "checkin",
          "checkout",
          "paymentMade",
          "roundedDurationHours",
          "gstFee"
        ]);
        if (value === null && booking.paymentRefNumber) {
          return true;
        } else if (!booking.paymentRefNumber) {
          value = "paymentRefNumber";
        }
        this.showMandatoryFields = true;
        this.mandatoryFieldsErrorMessage = `${value} is required`;
        return false;
      } else if (booking.status === "Checkin") {
        const value = this.checkMandatoryFields(booking, ["checkin"]);
        if (value === null) {
          return true;
        }
        this.showMandatoryFields = true;
        this.mandatoryFieldsErrorMessage = `${value} is required`;
        return false;
      }
      return true;
    } else {
      // For Shared Space and Meeting rooms
      if (booking.status === "Prepaid") {
        let value = this.checkMandatoryFields(booking, [
          "paymentMade",
          "gstFee"
        ]);
        if (value === null && booking.paymentRefNumber) {
          return true;
        } else if (!booking.paymentRefNumber) {
          value = "paymentRefNumber";
        }
        this.showMandatoryFields = true;
        this.mandatoryFieldsErrorMessage = `${value} is required`;
        return false;
      } else {
        return true;
      }
    }
  }

  calculateGSTAmount(amount: number) {
    const { paymentMade } = this.booking;
    this.booking.gstFee = paymentMade - paymentMade / 1.18;
  }
  calculateFloatingPoints() {
    const { headCount, priceperhr, duration } = this.booking;
    this.booking.redeemedPoints = headCount * priceperhr * duration;
    // Math.ceil((this.booking.endTime - this.booking.startTime) / (1000 * 60 * 60))
  }

  // async editBooking() {
  //   if (this.booking.status.toLowerCase() === "checkin") {
  //     let checkInMoment = this.moment(this.checkinTime);
  //     checkInMoment = checkInMoment.toDate().getTime();
  //     this.booking.checkin = checkInMoment;
  //   } else if (
  //     this.booking.status.toLowerCase() === "checkout" ||
  //     this.booking.status.toLowerCase() === "checkoutinitiated"
  //   ) {
  //     // if (this.booking.status.toLowerCase() == "checkout") {
  //     let checkInMoment = this.moment(this.checkinTime);
  //     checkInMoment = checkInMoment.toDate().getTime();
  //     this.booking.checkin = checkInMoment;
  //     let checkOutMoment = this.moment(this.checkoutTime);
  //     checkOutMoment = checkOutMoment.toDate().getTime();
  //     this.booking.checkout = checkOutMoment;
  //   }

  async editBooking() {
    if (this.checkinTime && this.booking.status.toLowerCase() === "checkin") {
      let checkInMoment = this.moment(this.checkinTime);
      checkInMoment = checkInMoment.toDate().getTime();
      this.booking.checkin = checkInMoment;
    } else if (
      (this.checkinTime &&
        this.checkoutTime &&
        this.booking.status.toLowerCase() === "checkout") ||
      this.booking.status.toLowerCase() === "checkoutinitiated"
    ) {
      // if (this.booking.status.toLowerCase() == "checkout") {
      let checkInMoment = this.moment(this.checkinTime);
      checkInMoment = checkInMoment.toDate().getTime();
      this.booking.checkin = checkInMoment;
      let checkOutMoment = this.moment(this.checkoutTime);
      checkOutMoment = checkOutMoment.toDate().getTime();
      this.booking.checkout = checkOutMoment;
    }

    this.booking.bookingdate = this.moment(this.bookingTime)
      .toDate()
      .getTime();
    this.booking.startTime = this.moment(this.bookingTime)
      .toDate()
      .getTime();
      
    if (this.totalduration !== this.booking.duration) {
      this.booking.duration = this.totalduration;
      this.booking.endTime =
        new Date(this.booking.startTime).getTime() +
        1000 * 60 * 60 * parseInt(this.totalduration);
    }

    if (!this.booking.redeemedPoints) {
      this.booking.redeemedPoints = 0;
    }
    if (!this.validateFields()) {
      return;
    }

    if (
      this.oldBookingStatus !== "Checkout" &&
      this.booking.status === "Checkout"
    ) {
      this.requireUpdate = true;
    } else {
      this.requireUpdate = false;
    }
    if (this.requireUpdate) {
      const { redeemedPoints } = this.booking;
      let availableFloatingPoints: any = 0;
      try {
        availableFloatingPoints = await this._bookingservice.getUserFloatingPoints(
          {
            uid: this.booking.custId
          }
        );
      } catch (e) {}
      if (redeemedPoints > availableFloatingPoints) {
        this.showFPerrorDialog = true;
        return;
      }
    }
    this.loader.display(true);
    this._bookingservice
      .editBooking({
        ...this.booking,
        requireUpdate: this.requireUpdate
      })
      .then(
        res => {
          if (res) {
            this.showSuccessDialog = true;
          } else {
            this.showerrorDialog = true;
          }
          this.loader.display(false);
        },
        err => {
          this.showerrordbDialog = true;
        }
      );
  }
  success() {
    this.dialogRef.close(true);
    // this.router.navigate(["/landingpage"]);
  }
  cancel() {
    // this.user = {   'location': {} };
    setTimeout(() => {
      $("div.label-floating").toggleClass("is-empty");
    }, 100);
  }

  Failure() {
    this.router.navigate(["/landingpage"]);
  }
  dbFauilure() {
    this.router.navigate(["/landingpage"]);
  }
}
