import { Component, OnInit } from '@angular/core';
import { LoginService } from '../appservices/login.service';
import { BookingService } from '../appservices/booking.service';
import { LoaderService } from '../sharedcomponents/index';
import * as Clipboard from 'clipboard/dist/clipboard.min.js';
import {MatSnackBar} from '@angular/material';


@Component({
  selector: 'app-generate-booking',
  templateUrl: './generate-booking.component.html',
  styleUrls: ['./generate-booking.component.scss']
})
export class GenerateBookingComponent implements OnInit {
  bookingDate: Date;
  requestedDate: Date;
  bookingKeys: String = '';
  showDialog: Boolean = false;
  result: any[] = [];
  clipboard: any;
  constructor(
    public _bookingservice: BookingService,
    public _loginService: LoginService,
    private loader: LoaderService,
		private snackBar: MatSnackBar
    ) {
      this.clipboard = new Clipboard('#cpyBtn');
    }

  ngOnInit() {
    this.bookingDate = this.requestedDate = new Date();
  }

  showcopytoast() {
		this.snackBar.open('Copied to Clipboard', 'Close', {
			duration: 1000,
		});
	}

  generateBooking = () => {
    const {email, uid} = this._loginService.currentUser;
    const { bookingDate, requestedDate, bookingKeys } = this;
    const bookingKeysArray = bookingKeys.split(','),
          requestedTimeStamp = new Date(requestedDate).getTime(),
          bookingTimeStamp = new Date(bookingDate).setHours(0, 0, 0, 0);
    for(let i = 0; i < bookingKeysArray.length; i++)
    {
      bookingKeysArray[i] = bookingKeysArray[i].trim();
    }
    this.loader.display(true);
    this._bookingservice.generateBookings({
      user: {
        user_id: uid,
        email
      },
      // bookingKeys: ['-LgWPEWy0fa32NLfPWYR', '-LgnJJxPvQucqG0TLniF'],
      bookingKeys: bookingKeysArray,
      bookingTimeStamp,
      requestedTimeStamp
    }).then(result => {
      this.loader.display(false);
      this.result = result;
      this.showDialog = true;
    }).catch(e => {
      this.loader.display(false);
      this.result = [];
      this.showDialog = false;
    });

  }

}
