import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AngularFireDatabase } from '@angular/fire/database';
import { LoaderService } from '../sharedcomponents';

@Component({
  selector: 'app-add-organizations',
  templateUrl: './add-organizations.component.html',
  styleUrls: ['./add-organizations.component.scss']
})
export class AddOrganizationsComponent implements OnInit {
  editMode: Boolean = false;
  organizationId: String = "";
  name: String = "";
  // partnerDescription: String = "";
  logoURL: String = "";
  // offerSummary: String = "";
  adminEmailIds: String = "";
  emailDomains: String = "";
  active : Boolean = true;
  orgCredits: number = 0;
  private organization;
  // availablePartner: any[] = [];
  public errorMessage: String = '';
  public showSuccessDialog: Boolean = false;
  public showerrorDialog: Boolean = false;
  public showSuccessDialog1: Boolean = false;
  constructor(
    private db: AngularFireDatabase,
    private loader: LoaderService,
    private dialogRef : MatDialogRef<AddOrganizationsComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    if (data) {
      this.editMode = data.editMode;
      this.organizationId = data.organizationId;
      const organization = data.organization;
      this.name = organization.name;
      // this.partnerDescription = organizations.partnerDescription;
      this.logoURL = organization.logoURL;
      // this.offerSummary = organizations.offerSummary;
      this.adminEmailIds = organization.adminEmailIds;
      this.emailDomains = organization.emailDomains.toString();
      // this.availablePartner = communitypartners.availablePartner || [];
      this.active = organization.active;
      this.orgCredits = organization.orgCredits || 0;
    }
  }
  ngOnInit() {
  }

  addOrganization() {
    const {organizationId} = this;
    const ref = this.db.database.ref('organizations/' + organizationId);
    ref.once("value", snapshot => {
     this.organization = snapshot.val();
    if(!this.organization){
      this.loader.display(true)
    const data = {
      dateAdded : `${new Date().getTime()}`,
      name: this.name,
      adminEmailIds: this.adminEmailIds,
      logoURL: this.logoURL,
      active: this.active,
      organizationId:organizationId,
      emailDomains:this.emailDomains.split(","),
      orgCredits: this.orgCredits
    }
    const newOrganization = this.db.database.ref('organizations/' + organizationId);
    newOrganization.set(data).then(() => {
      this.loader.display(false);
      this.errorMessage = '';
      this.showSuccessDialog = true;
      this.showSuccessDialog1 = false;
      this.showerrorDialog = false;
    }).catch(() => {
      this.loader.display(false);
      this.errorMessage = 'Server error!...';
      this.showSuccessDialog = false;
      this.showSuccessDialog1 = false;
      this.showerrorDialog = true;
    });
    }
    else{
      this.errorMessage = 'Organization Id is already exists';
        this.showSuccessDialog = false;
      this.showSuccessDialog1 = false;
        this.showerrorDialog = true;
    }
    })
  }

  editOrganization() {
    this.loader.display(true);
    const Updates = this.db.database.ref('organizations/' + this.organizationId);
    // Updates.once("value", snapshot => {
      // var updates = {};
      // snapshot.forEach(childSnapShot => {
      //   var comment = childSnapShot.val();
      //   const data = {
      //     partnerName: this.partnerName,
      //     partnerDescription: this.partnerDescription,
      //     partnerLogoUrl: this.partnerLogoUrl,
      //     offerSummary: this.offerSummary,
      //     offerDetailUrl: this.offerDetailUrl,
      //     priority : this.priority
      //   }
      //   updates = data;
      // })
      const updatedData = {
        name: this.name,
        adminEmailIds: this.adminEmailIds,
        logoURL: this.logoURL,
        emailDomains:this.emailDomains.split(','),
        active : this.active,
        orgCredits: this.orgCredits
      }
      // updates = data;
      Updates.update(updatedData).then(() => {
        console.log("Organization updated");
        this.loader.display(false);
        this.errorMessage = '';
        this.showSuccessDialog = false;
      this.showSuccessDialog1 = true;
        this.showerrorDialog = false;
      }).catch(() => {
        this.loader.display(false);
        this.errorMessage = 'Server error!...';
        this.showSuccessDialog = false;
      this.showSuccessDialog1 = false;
        this.showerrorDialog = true;
      });
    // })
  }

  success() {
		this.dialogRef.close(true);
	}

	error() {
		this.dialogRef.close(false);
  }
  
}
