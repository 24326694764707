import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../sharedcomponents/index';
import {SendNotificationService} from '../appservices/sendnotification.service';
import * as $ from 'jquery';
import { FormControl } from '@angular/forms';
import * as firebase from 'firebase';

@Component({
  selector: 'app-sendnotification',
  templateUrl: './sendnotification.component.html',
  styleUrls: ['./sendnotification.component.scss']
})
export class SendNotificationComponent implements OnInit {
  id: any;
  errorMessage: String;
  showDialog = false;
  showSuccessDialog = false;
  showerrorDialog = false;
  showerrordbDialog = false;
  notification :any = {};
  public tab: number = 0;  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loader: LoaderService,
    private sendNotificationService : SendNotificationService
  ) {
  }
  ngOnInit() {
    //this.loader.display(true);
  }
  sendNotification() {
    this.loader.display(true);
    if(this.tab == 1)
    {
      let emailIds = this.notification.emailIds.split(',');
      let validEmailIds = [];
      let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      emailIds.forEach(element => {
        element = element.trim();
        if(emailPattern.test(element))
          validEmailIds.push(element);
      });
      this.notification.emailIds = validEmailIds;
      this.loader.display(false);
      this.sendNotificationService.sendNotificationToMultipleUsers(this.notification)
      .then(res => {
      if (res) {
       this.showSuccessDialog = true;
       this.onTabChanged();
      }else {
       this.showerrorDialog = true;
       this.onTabChanged();
      }
      this.loader.display(false);
      }, err => {
        this.showerrordbDialog = true;
       this.onTabChanged();
       this.loader.display(false);
      }); 
    }
    else
    {
      this.sendNotificationService.sendNotification(this.notification)
      .then(res => {
      if (res) {
       this.showSuccessDialog = true;
      }else {
       this.showerrorDialog = true;
      }
      this.loader.display(false);
      }, err => {
        this.showerrordbDialog = true;
        this.loader.display(false);
      });
    }    
  }
  onTabChanged() {
    this.notification.emailIds = "";
    this.notification.topicName = "";
    this.notification.message = "";
  }

  failure() {
   
  }
  success() {
  
  }
  cancel() {
    
  }
}

