import { Component, OnInit } from '@angular/core';
import { BookingService } from '../appservices/booking.service';
import { LoaderService } from '../sharedcomponents/index';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';

@Component({
  selector: 'app-referral-report',
  templateUrl: './referral-report.component.html',
  styleUrls: ['./referral-report.component.scss']
})
export class ReferralReportComponent implements OnInit {
  referralDetails: any = [];
  constructor(
    private _bookingservice: BookingService,
		private loader: LoaderService
  ) { }

  ngOnInit() {
    this.getReferralDetails();
  }

  async getReferralDetails() {
		this.loader.display(true);
		try {
      this.referralDetails = <Object[]>await this._bookingservice.getReferralDetails();
		} catch (e) {
      this.referralDetails = [];
		}
		this.loader.display(false);

  }

	downloadCSV() {

		function checkDate(date) {
			if (date === undefined) {
				return '';
			}
			date = new Date(date);
			if (isNaN(date.getTime())) {
				return 'Invalid Date';
			}
			return date.toString();
		}

		const referralDownload = [];
		const headings = [
			'Creation Date',
			'Referee',
			'Referral Code',
			'Referral Status',
			'Referrer',
		];
		for (const floaterpass of this.referralDetails) {
			const {
				creationDate,
        referee_metadata,
				referralCode,
        referralStatus,
        referrer_metadata
      } = floaterpass;
			referralDownload.push({
				creationDate,
        referee_metadata: referee_metadata ? referee_metadata.email : 'Not Found',
				referralCode,
        referralStatus,
        referrer_metadata: referrer_metadata ? referrer_metadata.email : 'Not Found'
			});
		}
		new Angular2Csv(referralDownload, 'GoFloaters Referral', { headers: headings });
	}

}
