
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable()
export class ManageSpaceService {
    url = "api/books";
    serviceUrl: any;
    baseurl: any;
    options: any;
    constructor(private http: HttpClient) {
    }
    getspaces(value): Observable<any> {
        return this.http.get(this.url).pipe(
		   map(this.extractData),
		   catchError(this.handleError),);
    }
    getspaceById(spaceId: string): Observable<any> {
        return this.http.get(this.url + '/' + spaceId).pipe(
                map(this.extractData),
                catchError(this.handleError),);
        }
    private extractData(res: any) {
        let body = res;
            return body.data || {};
        }
        private handleError (error: any) {
            console.error(error.message || error);
            return observableThrowError(error.message || error);
            }

}
