import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../matreial.module";
import { Ng2CompleterModule } from 'ng2-completer';
// Interceptors
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
// import { httpFactory } from './sharedservices/index';
import { Router } from "@angular/router";
import { AgmCoreModule } from "@agm/core";
import { TokenInterceptor } from './token.interceptor';
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { LandingpageComponent } from "./landingpage/landingpage.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { OrgbookingsComponent } from "./orgbookings/orgbookings.component";
import { EnquiriesComponent } from "./enquiries/enquiries.component";
import { BookingsComponent } from "./bookings/bookings.component";
import { ManageComponent } from "./manage/manage.component";
import { AddSpaceComponent } from "./add-space/add-space.component";
import { ManageUserComponent } from "./manage-user/manage-user.component";
import { EditBookingComponent } from "./edit-booking/edit-booking.component";
import { SignupComponent } from "./signup/signup.component";
import { ReportComponent } from "./report/report.component";

import { LoginService } from "./appservices/login.service";
import { AddspaceService } from "./appservices/addspace.service";
import { BookingService } from "./appservices/booking.service";
import { EnquiryService } from "./appservices/enquiry.service";
import { SendNotificationService } from "./appservices/sendnotification.service";
import { ManageSpaceService } from "./appservices/managespace.service";
import { FoodCreditService } from "./appservices/foodcredit.service";

import {FlexLayoutModule} from '@angular/flex-layout';
import {MenuListItemComponent} from './menu-list-item/menu-list-item.component';
import { LoaderService } from "./sharedcomponents/index";
import { SpinnerComponent } from "./sharedcomponents/spinner/spinner.component";
import { DialogComponent } from "./sharedcomponents/dialog/dialog.component";
import { AddNoteComponent } from './sharedcomponents/add-note/add-note.component';
import { AddSettlementComponent } from './sharedcomponents/add-settlement/add-settlement.component';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AuthService } from "./auth.service";
// import {AngularFireDatabaseModule} from '@angular/fire/database';
import { ProfileComponent } from "./profile/profile.component";
import { SettingsComponent } from "./settings/settings.component";
import * as firebase from "firebase";
import { ViewbookingComponent } from "./viewbooking/viewbooking.component";
import { ViewSpaceDetailsComponent } from "./view-space-details/view-space-details.component";
import { SendNotificationComponent } from "./sendnotification/sendnotification.component";
import { SpaceBulkUpdateComponent } from "./spacebulkupdate/spacebulkupdate.component";
import { PartnerInvoicesComponent } from "./partnerinvoices/partnerinvoices.component";
import { SendPaymentLinkComponent } from "./sendpaymentlink/sendpaymentlink.component";
import { AppConstants } from "./app.constants";

import { Ng2SmartTableModule } from "ng2-smart-table";
import { FcmCloudMessagingService } from "./appservices/fcm-cloud-messaging.service";
import { MessageService } from "./appservices/messageservice";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AuthenticationService } from "./appservices/authentication.service";
import { AuthGuard } from "./appservices/auth-guard.service";
import { DragulaModule } from "ng2-dragula";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { ImgPreloadDirective } from "./img.preload";
import { SharedProvider } from "./appservices/shared";
import { DropZoneDirective } from "./drop-zone.directive";
import { FileDropDirective } from "./file-drop.directive";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import {MatSortModule} from '@angular/material/sort'
import { AutocompleteComponent } from "./google-service/google-places.component";
import { UserListComponent } from "./user-list/user-list.component";
import  { BooleanConverterPipe } from "./view-space-details/pipes/BooleanConverter";
import { FeedbacksComponent } from './feedbacks/feedbacks.component';
import { GenerateBookingComponent } from './generate-booking/generate-booking.component';
import { VoucherListComponent } from './voucher-list/voucher-list.component';
import { SpaceSearchComponent } from './space-search/space-search.component';
import { AddVoucherComponent } from './add-voucher/add-voucher.component';
import { FoodRedemptionReportComponent } from './food-redemption-report/food-redemption-report.component';
import { HelpComponent } from './help/help.component';
import { FoodRedemptionComponent } from './foodredemption/foodredemption.component';
import { FloaterpassReportComponent } from './floaterpass-report/floaterpass-report.component';
import { PromotionDetailsComponent } from './promotion-details/promotion-details.component';
import { AddPromotionComponent } from './add-promotion/add-promotion.component';
import { FoodcreditsPurchaseReportComponent } from './foodcredits-purchase-report/foodcredits-purchase-report.component';
import { MessageComponent } from './sharedcomponents/message/message.component';
import { RazorpayTransactionsComponent } from './razorpay-transactions/razorpay-transactions.component';
import { ReferralReportComponent } from './referral-report/referral-report.component';
import { CaptureRazorpayAmountComponent } from './capture-razorpay-amount/capture-razorpay-amount.component';
import { NavService } from "./nav.service";
import { CommunityparntersComponent } from './communityparnters/communityparnters.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { SearchCollectionsComponent } from './searchcollection/searchcollection.component';
import { AddCommunitypartnersComponent } from './add-communitypartners/add-communitypartners.component';
import { AddOrganizationsComponent } from './add-organizations/add-organizations.component';
import { AddSearchCollectionComponent } from './add-searchcollection/add-searchcollection.component';
import { FaqComponent } from './faq/faq.component';
import { AddfaqComponent } from './addfaq/addfaq.component';
import { ImageCropperModule } from 'ngx-image-cropper';

// export const firebaseconfig = {
//   apiKey: "AIzaSyDYYQUOaQ08YOogOvnhccMPHesAIlFNShI",
//   authDomain: "gofloatersdev.firebaseapp.com",
//   databaseURL: "https://gofloatersdev.firebaseio.com",
//   projectId: "gofloatersdev",
//   storageBucket: "gofloatersdev.appspot.com",
//   messagingSenderId: "509881371385"
//   // apiKey: "AIzaSyD0UFJFOezqn0sk9gVnRUdJ0sMa1Yi8PZE",
//   // authDomain:"gofloaters.firebaseapp.com",
//   // databaseURL:"https://gofloaters.firebaseio.com",
//   // projectId: "gofloaters",
//   // storageBucket:"gofloaters.appspot.com",
//   // messagingSenderId: "187167465849"
// };
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingpageComponent,
    DashboardComponent,
    OrgbookingsComponent,
    EnquiriesComponent,
    BookingsComponent,
    ManageComponent,
    AddSpaceComponent,
    ManageUserComponent,
    EditBookingComponent,
    SpinnerComponent,
    SignupComponent,
    ProfileComponent,
    SettingsComponent,
    ViewbookingComponent,
    DialogComponent,
    AddNoteComponent,
    AddSettlementComponent,
    ViewSpaceDetailsComponent,
    SendNotificationComponent,
    SpaceBulkUpdateComponent,
    PartnerInvoicesComponent,
    SendPaymentLinkComponent,
    UserListComponent,
    ImgPreloadDirective,
    FileDropDirective,
    DropZoneDirective,
    ReportComponent,
    AutocompleteComponent,
    BooleanConverterPipe,
    FeedbacksComponent,
    GenerateBookingComponent,
    VoucherListComponent,
    SpaceSearchComponent,
    AddVoucherComponent,
    FoodRedemptionReportComponent,
    HelpComponent,
    FoodRedemptionComponent,
    FloaterpassReportComponent,
    PromotionDetailsComponent,
    AddPromotionComponent,
    FoodcreditsPurchaseReportComponent,
    MessageComponent,
    RazorpayTransactionsComponent,
    ReferralReportComponent,
    CaptureRazorpayAmountComponent,
    MenuListItemComponent,
    CommunityparntersComponent,
    OrganizationsComponent,
    AddSearchCollectionComponent,
    SearchCollectionsComponent,
    AddCommunitypartnersComponent,
    AddOrganizationsComponent,
    FaqComponent,
    AddfaqComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    MatSortModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(
      AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].firebase
    ),
    FlexLayoutModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    // AngularFireDatabaseModule,
    BrowserAnimationsModule,
    Ng2CompleterModule,
    Ng2SmartTableModule,
    AngularFireStorageModule,

    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAOYYiyxLtWYGtuoVTJudwY8I2wRAjUIFo",
      libraries: ["places"]
    }),
    RouterModule.forRoot([
      {
        path: "signup",
        component: SignupComponent
      },
      {
        path: "login",
        component: LoginComponent
      },
      {
        path: "",
        redirectTo: "/landingpage/bookings",
        pathMatch: "full"
      },
      {
        path: "landingpage",
        component: LandingpageComponent,
        canActivateChild: [AuthGuard],
        children: [
          {
            path: "reportdashboard",
            component: ReportComponent
          },
          {
            path: 'fooddashboard',
            component: FoodRedemptionReportComponent
          },
          {
            path: 'dashboard',
            component: DashboardComponent
          },
          {
            path: 'orgbookings',
            component: OrgbookingsComponent
          },
          {
            path: 'enquiries',
            component: EnquiriesComponent
          },
          {
            path: "bookings",
            component: BookingsComponent
          },
          {
            path: "viewbooking/:id",
            component: ViewbookingComponent
          },
          {
            path: "viewspace/:id",
            component: ViewSpaceDetailsComponent
          },
          {
            path: "manage",
            component: ManageComponent
          },
          {
            path: "manage-user",
            component: ManageUserComponent
          },
          {
            path: "manage-user/:userEmailId",
            component: ManageUserComponent
          },
          {
            path: "edit-booking/:id",
            component: EditBookingComponent
          },
          {
            path: "add-space",
            component: AddSpaceComponent
          },
          {
            path: "add-space/:id",
            component: AddSpaceComponent
          },
          {
            path: "",
            redirectTo: "bookings",
            pathMatch: "full"
          },
          {
            path: "profile",
            component: ProfileComponent
          },
          {
            path: "settings",
            component: SettingsComponent
          },
          {
            path: "notifications",
            component: SendNotificationComponent
          },
          {
            path: "spacebulkupdate",
            component: SpaceBulkUpdateComponent
          },
          {
            path: "partnerinvoices",
            component: PartnerInvoicesComponent
          },
          {
            path: "paymentlink",
            component: SendPaymentLinkComponent
          },
          {
            path: "generate-bookings",
            component: GenerateBookingComponent
          },
          {
            path: "promotion-details",
            component: PromotionDetailsComponent
          },
          {
            path: "userdashboard",
            component: UserListComponent
          },
          {
            path: "voucherdashboard",
            component: VoucherListComponent
          },
          {
            path: "searchspace",
            component: SpaceSearchComponent
          },
          {
            path: 'foodredemption',
            component: FoodRedemptionComponent
          },
          {
            path: 'floaterpassreport',
            component: FloaterpassReportComponent
          },
          { 
            path: 'foodcreditspurchasereport',
            component:FoodcreditsPurchaseReportComponent
          },
          { 
            path: 'paymenttransactions',
            component:RazorpayTransactionsComponent
          },
          {
            path: 'referralreport',
            component: ReferralReportComponent
          },
          {
            path: 'communitypartners',
            component: CommunityparntersComponent
          },
          {
            path: 'organizations',
            component: OrganizationsComponent
          },
          {
            path: 'searchcollections',
            component: SearchCollectionsComponent
          },
          {
            path: 'faq',
            component: FaqComponent
          },
          {
            path: 'help',
            component: HelpComponent
          },
        ]
      }
    ]),
    DragulaModule.forRoot(),
    MaterialModule,
    ImageCropperModule
  ],
  providers: [
    LoginService,
    NavService,
    AddspaceService,
    BookingService,
    EnquiryService,
    ManageSpaceService,
    LoaderService,
    AuthService,
    SendNotificationService,
    MessageService,
    FcmCloudMessagingService,
    AuthenticationService,
    FoodCreditService,
    AuthGuard,
    DragulaModule,
    SharedProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ],
  entryComponents: [AddVoucherComponent, AddPromotionComponent, AddNoteComponent,AddSettlementComponent,CaptureRazorpayAmountComponent,AddCommunitypartnersComponent,AddOrganizationsComponent,AddfaqComponent,OrganizationsComponent,AddSearchCollectionComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
