import { Component, OnInit, Input, Output, OnChanges, EventEmitter, Inject } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {  MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
  animations: [
    trigger('note', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class AddNoteComponent implements OnInit {
  public note:string;
  constructor(private dialogRef: MatDialogRef<AddNoteComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() { }

  close()
  {
    this.dialogRef.close();
  }
  add() {
    if(this.note) {
        this.dialogRef.close({note:this.note});
    } else {
        this.dialogRef.close();
    }
  }
}