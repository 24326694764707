import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../sharedcomponents/index';
// import {SendNotificationService} from '../appservices/sendnotification.service';
import { BookingService } from "../appservices/booking.service";
import {MatSnackBar} from '@angular/material';
import * as Clipboard from 'clipboard/dist/clipboard.min.js';
import * as $ from 'jquery';
import { FormControl } from '@angular/forms';
import * as firebase from 'firebase';

@Component({
  selector: 'app-sendpaymentlink',
  templateUrl: './sendpaymentlink.component.html',
  styleUrls: ['./sendpaymentlink.component.scss']
})
export class SendPaymentLinkComponent implements OnInit {
  id: any;
  errorMessage: String;
  showDialog = false;
  showSuccessDialog = false;
  showerrorDialog = false;
  showerrordbDialog = false;
  paymentLink :any = {};
  clipboard: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loader: LoaderService,
    // private sendNotificationService : SendNotificationService
    private _bookingservice :BookingService,
    private snackBar: MatSnackBar
  ) {
    this.clipboard = new Clipboard('#cpyBtn');
  }
  ngOnInit() {
    //this.loader.display(true);
  }
  sendPaymentLink() {
    if (this.paymentLink.amount > 0) {
      this.loader.display(true);
      let invoiceDetail = {};
      invoiceDetail["sendsms"] = "0";
      invoiceDetail["sendemail"] = "0";
      invoiceDetail["description"] = this.paymentLink.description;
      invoiceDetail["amount"] = Number(this.paymentLink.amount).toFixed(2);
      this._bookingservice.createPaymentLink(invoiceDetail).then(
        (res: any) => {
          this.paymentLink["url"] = res.status.short_url;
          this.loader.display(false);
          if (res.status["status"] && res.status["status"] === "issued") {
            this.showSuccessDialog = true;
          } else {
            this.showerrorDialog = true;
          }
        },
        err => {
          this.showerrordbDialog = true;
        }
      )

    }
  }  

  showcopytoast() {
		this.snackBar.open('Copied to Clipboard', 'Close', {
			duration: 1000,
		});
	}

  failure() {
   
  }
  success() {
  
  }
  cancel() {
    
  }
}

