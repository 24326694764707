import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
// import { auth } from 'firebase/app';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase';
import { User } from 'firebase';
import { database } from 'firebase/app';
import { LoaderService } from '../sharedcomponents/index';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  currentUser: any = {};
  showSuccessDialog = false;
  showerrorDialog = false;
  showerrordbDialog = false;
  constructor(public authService: AuthService, private loader: LoaderService, private router: Router) { }

  ngOnInit() {
    const that = this;
    that.loader.display(true);
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        that.currentUser.email = user.email;
        that.currentUser.displayName = user.displayName;
        // that.currentUser.phoneNumber = user.phoneNumber;
      }
    });
    that.loader.display(false);
  }
  addprofile() {
    const that = this;
    that.loader.display(true);
    firebase.auth().onAuthStateChanged(function (user) {
      user.updateProfile({
        displayName: that.currentUser.displayName,
        photoURL: '',
      });
      if (user) {
        that.showSuccessDialog = true;
      } else {
        that.showerrorDialog = true;
      }
      that.loader.display(false);
    }, err => {
      that.showerrordbDialog = true;
    });
  }
  success() {
    this.router.navigate(['/profile']);
  }
  Failure() {
    this.router.navigate(['/profile']);
  }
  dbFauilure() {
    this.router.navigate(['/profile']);
  }
}
