import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import * as moment from 'moment-timezone';

@Component({
  selector: 'help-videos',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  public docURL = "https://docs.google.com/document/d/e/2PACX-1vRzu61d2v-m70lGRs31XifT7qK8LTbEJoApz066PBGmyzBm-pZVbxgVyBPnZgowTvkFbgySKWAOvn1N/pub?embedded=true";

  constructor(
    private router: Router,
  ) {
    // this.helpVideos =  [
    //   {
    //     videoTitle : "Login & Password Reset",
    //     videoURL : "https://www.youtube.com/embed/rBa2DXWCXVY"
    //   },
    //   {
    //     videoTitle : "Space listing & space edit",
    //     videoURL : "https://www.youtube.com/embed/UvsKH47ns3g"
    //   },
    //   {
    //     videoTitle : "Booking Management",
    //     videoURL : "https://www.youtube.com/embed/2fjqIMpboQ0"
    //   },
    //   {
    //     videoTitle : "Reports",
    //     videoURL : "https://www.youtube.com/embed/EtCgQ40iXEI"
    //   },
    //   {
    //     videoTitle : "Getting Help",
    //     videoURL : "https://www.youtube.com/embed/pHL2lVc6Dvo"
    //   }
    // ]
  }
  ngOnInit() {
    
  }
  loginNow() {
    // this.loader.display(false);
    this.router.navigate(['/login']);
  }

}
