import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import * as moment from 'moment';
import { BookingService } from '../appservices/booking.service';
import { LoaderService } from '../sharedcomponents';
import { SharedProvider } from '../appservices/shared';
import { LoginService } from '../appservices/login.service';
import { Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import * as _ from 'underscore';
import { AddSettlementComponent } from '../sharedcomponents/add-settlement/add-settlement.component';
import { MatDialog } from '@angular/material';

@Component({
	selector: 'app-food-redemption-report',
	templateUrl: './food-redemption-report.component.html',
	styleUrls: ['./food-redemption-report.component.scss']
})

export class FoodRedemptionReportComponent implements OnInit {
	useDateFilter = false;
	fromDate: Date;
	toDate: Date;
	revenue: any = {};
	bookings: any = [];
	public moment: any = moment;
	years: Number[] = [];
	SpaceOwnerId: any;
	spaces: any[] = [];
	searchSpaceString: String = '';
	filteredSpaces: any[] = [];
	searchFilteredSpace = [];
	revenueResult: any[] = [];
	totalRevenue: Number = 0;
	revenueShare: Number = 0.00;
	revenueShareWOGst: Number = 0.00;
	gstonRevenue: Number = 0.00;
	currentSearch: any;
	spaceArea: string;
	localities: string[];
	cityInfo:any=[];
	locality: string;
	showSuccessDialog: Boolean = false;
	showerrorDialog: Boolean = false;
	paymentBtn : boolean = false;
	months: any = [
		{
			name: 'January',
			value: 1
		},
		{
			name: 'February',
			value: 2
		},
		{
			name: 'March',
			value: 3
		},
		{
			name: 'April',
			value: 4
		},
		{
			name: 'May',
			value: 5
		},
		{
			name: 'June',
			value: 6
		},
		{
			name: 'July',
			value: 7
		},
		{
			name: 'August',
			value: 8
		},
		{
			name: 'September',
			value: 9
		},
		{
			name: 'October',
			value: 10
		},
		{
			name: 'November',
			value: 11
		},
		{
			name: 'December',
			value: 12
		}
	];
	settlement: any = [];
	constructor(
		private _bookingservice: BookingService,
		private loader: LoaderService,
		public loginService: LoginService,
		public sharedService: SharedProvider,
		private router: Router,
		private dialog: MatDialog
	) {
		this.spaceArea = 'all';
		this.localities = [];
		this.locality = 'all';
	}

	ngOnInit() {
		this.moment.tz.setDefault('Asia/Kolkata');
		const user = this.loginService.getCurrentUser();
		if (user.uid !== undefined) {
			this.SpaceOwnerId = user.uid;
			this.sharedService.isLoggedIn = true;
		} else {
			this.loginNow();
			return;
		}
		this.revenue = {
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			amountSettledToPartner: false
		};
		for (let index = 2018; index <= new Date().getFullYear(); index++) {
			this.years.push(index);
		}
		const today = new Date();
		today.setDate(1);
		this.fromDate = today;
		this.toDate = new Date();

		this.getSpaceDetails();

		if(this.sharedService.citiesMetaData)
		{
			this.cityInfo = this.sharedService.getActiveCities();
		}
		else
		{
			this.loader.display(true);
			this._bookingservice.getMetaData().then(val => {
				this.cityInfo = this.sharedService.getActiveCities();
				this.loader.display(false);
			}).catch(err => {
				console.log(err);
			});
		}
	}

	loginNow() {
		this.loader.display(false);
		this.router.navigate(['/login']);
	}

	makePayment(spaceId: String) {
		this.loader.display(true);
		this._bookingservice.makeFoodPayment({
			spaceId
		}).then(() => {
			this.loader.display(false);
			this.showSuccessDialog = true;
			this.showerrorDialog = false;
		}).catch(() => {
			this.loader.display(false);
			this.showerrorDialog = true;
			this.showSuccessDialog = false;
		});
	}

	filterLocalities() {
		if (this.locality === 'All') {
			this.filteredSpaces = this.spaces;
			this.searchFilteredSpace = this.spaces;
		} else {
			this.filteredSpaces = this.spaces.filter(space => {
				return (space.address && space.address.locality) === this.locality;
			});
			this.searchFilteredSpace = this.filteredSpaces;
		}
		this.revenue.spacename = [this.filteredSpaces[0].spaceId];
		this.calculate();
	}

	calculate() {
		this.currentSearch = { ...this.revenue };
		this.revenueShare = 0.00;
		this.revenueShareWOGst = 0.00;
		this.gstonRevenue = 0.00;
		this.loader.display(true);
		this.revenueResult = [];
		const year = this.revenue.year;
		const month = this.revenue.month;
		const spacename = this.revenue.spacename;
		let startDate, endDate;
		if (this.useDateFilter) {
			this.fromDate.setHours(0, 0, 0, 0);
			this.toDate.setHours(23, 59, 59, 59);
			startDate = this.fromDate.getTime();
			endDate = this.toDate.getTime();
		} else {
			startDate = this.moment([year, month - 1]);
			endDate = this.moment(startDate).endOf('month');
		}
		let spaceList = [];
		if (spacename === 'All Spaces') {
			spaceList = this.spaces.map(space => space.spaceId);
		} else {
			spaceList = spacename;
		}
		spaceList = spaceList.filter(space => space);
		const reportreq = {
			spaceOwnerId: this.SpaceOwnerId,
			spaceIds: spaceList,
			starttime: startDate.valueOf(),
			endtime: endDate.valueOf(),
			amountSettledToPartner: this.revenue.amountSettledToPartner
		};
		this._bookingservice
			.getFoodReport(reportreq)
			.then((val: any) => {
				this.revenueShare = val.revenueShare || '0.00';
				this.revenueShareWOGst = val.partnerShareWoGST || '0.00';
				this.gstonRevenue = val.gstOnRevenue || '0.00';
				this.paymentBtn = val.revenueShare != undefined? true : false;
				this.revenueResult = spaceList.map(spaceId => {
					const spaceDetail = this.spaces.find(
						space => space.spaceId === spaceId
					);
					return {
						...val[spaceId],
						spaceName: spaceDetail.spaceName || '',
						originalName: spaceDetail.originalName || ''
					};
				});
				this.loader.display(false);
			})
			.catch(err => {
				this.loader.display(false);
			});
	}
	downloadPaymentSettlement() {
		const allpayments = [];
		this.revenueResult.forEach(value => {
				allpayments.push(
					{ spacename : value.spaceName, amountpaid : value.revenueShare, rzrpayacct : value.razorPayAccoundId }
				);
		});
		const paymentSettlementReportForDownload = [];
		const headings = [
			"Space Name",
			"Razorpay Account Number",
			"Payout Amount",
			"Payout Currency",
			"Payout Mode",
			"Payout Purpose",
			"Payout Narration",
			"Fund Account Id",
			"Fund Account Type",
			"Fund Account Name",
			"Fund Account IFSC",
			"Fund Account Number",
			"Fund Account Vpa",
			"Contact Type",
			"Contact Name",
			"Contact Email",
			"Contact Mobile",
			"Contact Reference Id",
			"Notes[Place]",
			"Notes[Code]"
		];
		for (let payment of allpayments) {
			var paymentObject = (({
				spacename,
				gofRazorpayAcct,
				amountpaid,
				currency,
				mode,
				purpose,
				payoutNarration,
				rzrpayacct
				

			}) => ({
				spacename,
				gofRazorpayAcct,
				amountpaid,
				currency,
				mode,
				purpose,
				payoutNarration,
				rzrpayacct

				
			}))(payment);
			paymentObject.gofRazorpayAcct = 7878780035190380;
			paymentObject.currency = "INR";
			paymentObject.mode = "NEFT";
			paymentObject.purpose = "Payout"
			paymentObject.payoutNarration = '';
			if(payment["rzrpayacct"] == undefined) 
			{
				paymentObject.rzrpayacct = '';	
							
			}
			paymentSettlementReportForDownload.push(paymentObject);
		}
		const options = {
			fieldSeparator: ",",
			quoteStrings: '"',
			decimalseparator: ".",
			showLabels: { headers: headings },
			showTitle: false,
			useBom: true
		};
		new Angular2Csv(
			paymentSettlementReportForDownload,
			"GoFloaters Payment Settlement Report " + this.months[this.currentSearch.month - 1].name + ' ' + this.currentSearch.year,
			{ headers: headings }
		);
	}

	selectAll(checkAll, select: NgModel, values) {
		if (checkAll) {
			select.update.emit(values.map(value => value.spaceId));
		} else {
			select.update.emit([]);
			this.revenue.spacename = [];
		}
	}

	showPaymentButton(spacename) {
		return spacename && spacename.filter(item => item).length === 1;
	}

	searchSpace(e) {
		this.searchSpaceString = e.target.value;
		if (e.key === ' ') {
			this.searchSpaceString += ' ';
		}
		if (!this.searchSpaceString) {
			this.searchFilteredSpace = this.filteredSpaces;
		} else {
			this.searchFilteredSpace = this.filteredSpaces
				.filter(space => space.spaceName && space.spaceName.toUpperCase().includes(this.searchSpaceString.toUpperCase()));
		}
	}

	getdatetime(val: any) {
		return this.moment(val).format('DD/MM/YYYY, h:mm a');
	}

	getSpaceDetails() {
		return new Promise((resolve, reject) => {
			this.loader.display(true);
			this._bookingservice
				.getSpaceDetails({
					city: this.spaceArea === 'all' ? undefined : this.spaceArea
				})
				.then((res: any) => {
					this.loader.display(false);
					res = _.sortBy(res, r => r.spaceName);
					res = res.filter(space => {
						console.log("Find cafe here ");
						return space.isApproved == true && (space.spaceType == "Cafe" || space.spaceType == "Restaurant" )
					} );
					this.spaces = res;
					this.filteredSpaces = res;
					this.searchFilteredSpace = res;
					const localities = _.uniq(res.map(r => (r.address ? r.address.locality : null))
						.filter(locality => locality)
					).sort();
					if (localities.length > 0) {
						this.locality = 'All';
						this.localities = ['All'].concat(localities);
					} else {
						this.locality = '';
						this.localities = [];
					}
					if (this.spaces[0] && this.spaces[0].spaceId) {
						this.revenue.spacename = [this.spaces[0].spaceId];
					} else {
						this.revenue.spacename = [];
					}
					resolve(res);
					this.calculate();
				})
				.catch(err => {
					this.loader.display(false);
				});
		});
	}

	downloadCSV() {
		const allRedemptions = [];
		this.revenueResult.forEach(value => {
			if (value.redemptions) {
				allRedemptions.push(
					...value.redemptions
				);
			}
		});
		const redemptionsForDownload = [];
		const headings = [	
			'Food Credit Id',		
			'Customer Name',			
			'Space Name',
			'Original Space Name',
			'Redemption Date and Time',
			'Amount Redeemed',
			'Revenue Earned (w/o GST)',
			'GST on Revenue Earned',
			'Revenue Earned With GST'
		];
		for (const redeemption of allRedemptions) {
			let redeemptionObject = (({	
				foodCreditId,			
				customerName,				
				spaceName,
				originalName,
				redeemedOn,
				redeemedFoodCredit,
				billAmountWithOutGST,
				gstAmount,
				billAmountWithGST
			}) => ({	
				foodCreditId,			
				customerName,
				spaceName,
				originalName,
				redeemedOn,
				redeemedFoodCredit,
				billAmountWithOutGST,
				gstAmount,
				billAmountWithGST
			}))(redeemption);

			if (typeof redeemption['redeemedOn'] !== 'undefined') {
				redeemptionObject['redeemedOn'] = this.moment(redeemption['redeemedOn'])
					.tz('Asia/Kolkata')
					.format('DD/MM/YYYY, h:mm a');
			}
			redemptionsForDownload.push(redeemptionObject);
		}
		// tslint:disable-next-line: no-unused-expression
		new Angular2Csv(
			redemptionsForDownload,
			'GoFloaters Redemtion Report ' + this.currentSearch.year,
			{ headers: headings }
		);
	}
	change(event) {
		
		if(this.settlement.indexOf(event.source.value)> -1) {
			this.settlement.splice(this.settlement.indexOf(event.source.value), 1);
		} else {
			this.settlement.push(event.source.value);
		}
	}
	AddSettle() {
		
		if(this.settlement.length > 0) {
			this.dialog.open(AddSettlementComponent, {
				data: { },
				width: '400px',
				height: '250px'
			}).afterClosed().subscribe(updateRequired => {
				if (updateRequired) {
					this.loader.display(true);
					this._bookingservice.updateFoodRedemptionSettlement({
						foodRedemptionIds:this.settlement,
						ref_number:updateRequired.ref_number,
						ref_date:updateRequired.ref_date
					}).then((res: any) => {
						this.loader.display(false);
						this.settlement = [];
						this.calculate();
					}).catch((e) =>{
						this.loader.display(false);
						this.settlement = [];
					});
				}	
			});
		}
		
	}
}
