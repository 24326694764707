import {Component, OnInit} from '@angular/core';
import {EnquiryService} from '../appservices/enquiry.service';
import {Router} from '@angular/router';
import {LoaderService} from '../sharedcomponents/index';
import * as moment from 'moment-timezone';

import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SharedProvider } from '../appservices/shared';

@Component({selector: 'app-enquiries', 
templateUrl: './enquiries.component.html', 
styleUrls: ['./enquiries.component.scss'],
styles: [`

:host /deep/ ng2-smart-table { font-size : 16px }

`]})
export class EnquiriesComponent implements OnInit {
  enquiries = [];
  public moment : any = moment;
  filteredenquiries = [];
  searchby : any = "requestDate";
  // searchvalue :any;
  fromRequestDate : any = this
  .moment().tz('Asia/Kolkata')
  .format('YYYY-MM-DD');
  toRequestDate : any = this
  .moment().tz('Asia/Kolkata')
  .format('YYYY-MM-DD');
  smartTablesettings : any;
  organization: any;
  constructor(private _enquiryservice : EnquiryService, private loader : LoaderService, private sharedService : SharedProvider) {
    this.smartTablesettings = {
      attr: {
        class: 'table table-bordered'
        },
      noDataMessage:"No Enquiries available",
      actions: {
        add:false,
        edit:false,
        delete:false
      },
      columns: {
        enquiryId: {
          title: 'Enquiry Id'
        },
        spaceName: {
          title: 'Space Name'
        },
        name: {
          title: 'Lead Name'
        },
        phone: {
          title: 'Lead Phone'
        },
        email: {
          title: 'Lead Email'
        },
        enquiryDate: {
          title : 'Requested On'
        },
        date: {
          title : 'Space Needed From'
        },
        numberOfSeats: {
          title: 'Number Of Seats'
        },
        status: {
          title: 'Status'
        }
      }
    };


  }

  ngOnInit() {
    //this.getBookingsDetails();
    // this.filterdata()
    // this.getBookingsDetailsForRequestDateRange(this.fromRequestDate,this.toRequestDate);
    this.getEnquiriesList();
  }
  downloadCSV() {

    var enquiiesForDownload = [];
    var headings = ["Enquiry Id","Space Name","Lead Name","Lead Phone","Lead Email","Requested On","Space Needed From","Number Of Seats","Status"]
    for (let enquiry of this.filteredenquiries ) {
      var enquiryObject = (({enquiryId, spaceName,name, phone, email, enquiryDate ,date,numberOfSeats, status }) => ({ enquiryId, spaceName,name, phone, email, enquiryDate ,date,numberOfSeats, status }))(enquiry);
      // enquiryObject["date"] = this.getdatetime(enquiryObject["date"]);
      // enquiryObject["enquiryDate"] = this.getdatetime(enquiryObject["enquiryDate"]);    
      enquiiesForDownload.push(enquiryObject);
    }
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: { headers: (headings) }, 
      showTitle: false,
      useBom: true
    };
    new Angular2Csv(enquiiesForDownload, 'GoFloaters Enquiries Report',{ headers: headings });
  }

  // refresh() {
  //   //this.getBookingsDetails();
  //   this.getBookingsDetailsForRequestDateRange(this.fromRequestDate,this.toRequestDate);
  // }

  getEnquiriesList() {
    this._enquiryservice.enquiries.subscribe(res => {
      this.enquiries = res.map(enquiry => {
        enquiry.enquiryDate = this.getdate(enquiry.enquiryDate);
        enquiry.date = this.getdate(Number(enquiry.date));
        // console.log("enquiry.date",enquiry.date);
        // console.log("enquiry.enquiryDate",enquiry.enquiryDate);
        // enquiry.date = this.getdate(enquiry.date);
        return enquiry;
      });
      this.filteredenquiries = this.enquiries;
      });
  }
  getEnquiryDetailsForRequestDateRange(searchByData) {
    this
      .loader
      .display(true);
    this
      ._enquiryservice
      .searchEnquirylist(searchByData)
      .then( (res : any) => {
        // this.enquiries = res;
        // this.filteredenquiries = this.enquiries;
        this.loader.display(false);
          // if(this.sharedService.isSpaceCollectionLoaded)
          // { 
          //   this.filterEnquiries();
          // }
          // else
          // {
          //   this.sharedService.getSpacesList().then(val => {
          //     this.filterEnquiries();
          //   });
          // }    
      });
  }

  // filterEnquiries() {
  //   this.filteredenquiries = [];
  //   for (let enquiry of this.enquiries) {
  //       this.filteredenquiries.push(enquiry);
  //     }
  // }


  fetchEnquiries() {
    const fromRequestDateTimestamp = Number(this.moment(this.fromRequestDate).tz('Asia/Kolkata'));
    const toRequestDateTimestamp = Number(this.moment(this.toRequestDate).tz('Asia/Kolkata'));
    console.log("Filter date range : " + fromRequestDateTimestamp + " " + toRequestDateTimestamp);
    this.getEnquiryDetailsForRequestDateRange({ searchby: 'enquiryDate',fromEnquiryDate: fromRequestDateTimestamp, toEnquiryDate: toRequestDateTimestamp });
  }

  clearFilter() {
    this.filteredenquiries = this.enquiries;
    //this.currdate = "";
  }
  reformatDate(dateStr)
  {
    var dArr = dateStr.split("-"); // ex input "2018-01-12"
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex out: "12/01/2018"
  }
  getTime(time) {
    return this
      .moment(time).tz('Asia/Kolkata')
      .format('h:mm a');
  }
  getdate(val) {
    return this
      .moment(val).tz('Asia/Kolkata')
      .format('DD/MM/YYYY');
  }
  getdatetime(val) {
    return this
      .moment(val).tz('Asia/Kolkata')
      .format('DD/MM/YYYY, h:mm a');
  }
  converttime(timestamp) {

    const date = new Date(timestamp * 1000);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = '0' + date.getMinutes();
    // Seconds part from the timestamp
    const seconds = '0' + date.getSeconds();
    // Will display time in 10:30:23 format
    const formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    return formattedTime;
  }
}
