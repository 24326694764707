
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConstants } from "../app.constants";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable()
export class SendNotificationService {
  serviceUrl: any;
  baseurl: any;
  options: any;
  constructor(private http: HttpClient, public _ANGFIRE: AngularFireAuth) {
    // this.baseurl = AppConstants.CONSTANTS.baseurl;
    this.baseurl =
      AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
    this.serviceUrl = AppConstants.CONSTANTS.bookings
  }
//   sendNotification1(notification): Observable<any> {
//     const url = this.baseurl + this.serviceUrl.sendnotification;
//     return this.http
//       .post(url, JSON.stringify(notification), this.options)
//       .map(this.extractData)
//       .catch(this.handleErrorObservable);
//   }
  async sendNotification(value) {
    
    const url = this.baseurl + this.serviceUrl.sendnotification;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, value).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  async sendNotificationToMultipleUsers(value)
  {
    const url = this.baseurl + this.serviceUrl.notificationtomultipleusers;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, value).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  private extractData(res: any) {
    let body = res;
    return body.data || {};
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return observableThrowError(error.message || error);
  }
}
