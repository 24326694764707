import { EventEmitter } from '@angular/core';
// import { HttpClientClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
/*
  Generated class for the SharedProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class SharedProvider {
  public homePageRelead = true;
  public isLoggedIn = false;
  private spaceList : any[] = [];
  private locations : any;
  private selectedLocation :any;
  locationSelected = new EventEmitter<any>();
  public isLocationsLoaded = false;
  public isSpaceCollectionLoaded = false;
  private spaceCollection : any;
  public bookingTitle = new BehaviorSubject<String>('');
  public spaceTitle = new BehaviorSubject<String>('');
  public metaData : any;
  public citiesMetaData : any;
  private activeCities : any = [];
  private spaceSubTypeFilters : any;
  constructor(
    private db:AngularFireDatabase,
    ) {
    // console.log('Hello SharedProvider Provider');

  }
  setLocations(locations) {
    this.locations = locations;
    this.isLocationsLoaded = true;
  }
  getLocations() {
    return this.locations
  }

  setSpaceList(list) {
    this.spaceList = list;
  }

  getSpaceList() {
    return this.spaceList;
  }
  getSelectedLocation(){
    return this.selectedLocation
  }
  setSelectedLocation(selectedLocation) {
    this.selectedLocation = selectedLocation;
    this.locationSelected.emit(this.selectedLocation);
  }

  getSelectedLocationDetail(value){
      return value;
    // return  _.find(this.locations, function (location : any) {
    //   return location
    //     .city
    //     .toLowerCase() == value.toLowerCase();
    // });
  }

  setSpaceCollection(data)
  {
    this.spaceCollection = data;
    this.isSpaceCollectionLoaded = true;
  }

  getSpaceCollection()
  {
    return this.spaceCollection;
  }

  getSpaceDetailsFromSpaceCollection(spaceId)
  {
    let data = {
      spaceName : '', originalSpaceName:'', gofloatersSpaceName:'', spaceDisplayName: '', managerName:'', managerPhone:'', spaceSubType:'',gofRevenueShare:''
    };
    if(this.isSpaceCollectionLoaded && Object.keys(this.spaceCollection).length > 0)
    {      
      if(this.spaceCollection[spaceId])
      {
        data = {
          spaceName : this.spaceCollection[spaceId].spaceName,
          originalSpaceName : this.spaceCollection[spaceId].originalName,
          gofloatersSpaceName : this.spaceCollection[spaceId].gofloatersSpaceName,
          spaceDisplayName : this.spaceCollection[spaceId].spaceDisplayName,
          managerName : this.spaceCollection[spaceId].managerName,
          managerPhone : this.spaceCollection[spaceId].managerPhone,
          spaceSubType : this.spaceCollection[spaceId].spaceSubType,
          gofRevenueShare:this.spaceCollection[spaceId].gofRevenueShare
        }
    }
    }
    return data;
  }

  searchSpaceSpacesFromSpaceCollection(searchString,searchType) {
    let spaceList = [];
    let spaceKeys = Object.keys(this.spaceCollection);
    spaceKeys.forEach(spaceKey => {
      if (this.spaceCollection[spaceKey][searchType] && this.spaceCollection[spaceKey][searchType].toLowerCase().includes(searchString)) {
        console.log("Search results",this.spaceCollection[spaceKey]);
        spaceList.push(this.spaceCollection[spaceKey]);
      }
    });
    return spaceList;
  }

  getSpaceIdForSpaceName(name,value)
  {
    if(this.isSpaceCollectionLoaded && Object.keys(this.spaceCollection).length > 0)
    {
      if(name == 'gofloatersSpaceName')
      {
        for (var key in this.spaceCollection) {
          if (this.spaceCollection.hasOwnProperty(key)) 
          {
              if(this.spaceCollection[key].gofloatersSpaceName.indexOf(value) > -1)
                return key;
          }
        }
      }
      else
      {
        for (var key in this.spaceCollection) {
          if (this.spaceCollection.hasOwnProperty(key)) 
          {
              if(this.spaceCollection[key].spaceDisplayName.indexOf(value) > -1)
                return key;
          }
        }
      }     
    }
    return '';
  }
  setBookingTitle(value) {
    this.bookingTitle.next(value);
  }
  setSpaceTitle(value)
  {
    this.spaceTitle.next(value);
  }

/* Shyam 3rd Feb 2023 >> Function not needed
*
  getSpacesList()
  {
    let spaces : any;
     return new Promise((resolve,reject) => {
     this.db.database.ref('spaces')
      .on('value', snapshot => {
        console.log("Fetching Spaces - getSpacesList");
        if(snapshot.exists())
        {
          spaces = snapshot.val();    
          Object.keys(spaces).map(function(key, index) {
            spaces[key] = {
             spaceName: spaces[key].spaceName || '',
             originalName : spaces[key].originalName || '',
             gofloatersSpaceName : spaces[key].gofloatersSpaceName || '',
             spaceDisplayName : spaces[key].spaceDisplayName || '',
             managerName : spaces[key].managerName || '',
             managerPhone: spaces[key].managerphone || '',
             spaceSubType : spaces[key].spaceSubType? spaces[key].spaceSubType.toString() : '',
             address:spaces[key].address? spaces[key].address.toString() : '',
             location:spaces[key].location? spaces[key].location[0] + "," + spaces[key].location[1]:"",
             photos:spaces[key].photos,
             priceperday:spaces[key].priceperday,
             priceperhr:spaces[key].priceperhr,
             pricepermonth:spaces[key].pricepermonth,
             seatsAvailable:spaces[key].seatsAvailable,
             spaceId:key,
             gofRevenueShare:spaces[key].gofRevenueShare
            };
          });
        this.setSpaceCollection(spaces);
        resolve({"msg":"Spaces Loaded"});            
        }
        else
        {
          reject({"msg":"No Spaces Loaded"});
        }
      });
    });
  }
*/
  setMetaData(data)
  {
    this.metaData = data;
    this.citiesMetaData = data.operationalCities;
    this.spaceSubTypeFilters = data.facilities.amenities.concat(data.facilities.spaceinfo);
  }

  getActiveCities()
  {
    if(this.citiesMetaData)
    {
      this.activeCities = [];
      Object.keys(this.citiesMetaData).forEach((key, index) => {
        let data = this.citiesMetaData[key];
        data.forEach(city => {
          if(city.available)
          this.activeCities.push({
            name:city.name,
            value:city.value,
            cityId:city.cityId
          });
        });       
      });
      this.activeCities.sort(function(a,b) {
        return a.cityId - b.cityId
      });
      return this.activeCities;
    }
  }
 
getAmenities()
{
  return this.spaceSubTypeFilters;
}

}
