import {Injectable} from '@angular/core';

@Injectable()
export class AppConstants {
  public static  CONSTANTS = {
    currentEnvironment: "prod", 
    currentVersion:"v7.7.2",
    spaceSuperUsers:["akila@gofloaters.com","prerana@gofloaters.com","aarti@gofloaters.com","shyam@gofloaters.com"],
    bookingSuperUsers:["prerana@gofloaters.com","shyam@gofloaters.com","ramanand@gofloaters.com","sabeha@gofloaters.com","akila@gofloaters.com"],
    devProd: {
      firebase: {
        apiKey: "AIzaSyD0UFJFOezqn0sk9gVnRUdJ0sMa1Yi8PZE",
        authDomain: "gofloaters.firebaseapp.com",
        databaseURL: "https://gofloaters.firebaseio.com",
        projectId: "gofloaters",
        storageBucket: "gofloaters.appspot.com",
        messagingSenderId: "187167465849",
        appId: "1:187167465849:web:425043bfad6fcb46910fa8"
      },
      baseurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api/admin',
      appurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api',
      loginurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api/login',
      razorPayKey: 'rzp_test_87k0WW6Nk4NGoC'
    },
    prod: {
      firebase: {
        apiKey: "AIzaSyD0UFJFOezqn0sk9gVnRUdJ0sMa1Yi8PZE",
        authDomain: "gofloaters.firebaseapp.com",
        databaseURL: "https://gofloaters.firebaseio.com",
        projectId: "gofloaters",
        storageBucket: "gofloaters.appspot.com",
        messagingSenderId: "187167465849",
        appId: "1:187167465849:web:425043bfad6fcb46910fa8"
      },
      baseurl: 'https://gofloaters.web.app/admin',
      appurl: 'https://gofloaters.web.app',
      loginurl: 'https://gofloaters.web.app/login',
      razorPayKey: 'rzp_live_L4jnEFvzps4krf'
    },
    dev: {
      firebase: {
        apiKey: "AIzaSyDYYQUOaQ08YOogOvnhccMPHesAIlFNShI",
        authDomain: "gofloatersdev.firebaseapp.com",
        databaseURL: "https://gofloatersdev.firebaseio.com",
        projectId: "gofloatersdev",
        storageBucket: "gofloatersdev.appspot.com",
        messagingSenderId: "509881371385",
        appId: "1:509881371385:web:e4e3749254771b8a13b028"
      },
      baseurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api/admin',
      appurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api',
      loginurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api/login',
      razorPayKey: 'rzp_test_87k0WW6Nk4NGoC'
    },
    perf: {
      firebase: {
        apiKey: "AIzaSyDkMcr-mNva0C5ysW7iEf8uRqNnYSBMWuk",
        authDomain: "gofloatersperftest.firebaseapp.com",
        databaseURL: "https://gofloatersperftest.firebaseio.com",
        projectId: "gofloatersperftest",
        storageBucket: "gofloatersperftest.appspot.com",
        messagingSenderId: "369376544649",
        appId: "1:369376544649:web:fd7a9a9b9afb081f98c358"
    },
      baseurl: 'https://us-central1-gofloatersperftest.cloudfunctions.net/api/admin',
      appurl: 'https://us-central1-gofloatersperftest.cloudfunctions.net/api',
      loginurl: 'https://us-central1-gofloatersperftest.cloudfunctions.net/api/login',
      razorPayKey: 'rzp_test_87k0WW6Nk4NGoC'
    },
    local: {
      firebase: {
        apiKey: "AIzaSyDYYQUOaQ08YOogOvnhccMPHesAIlFNShI",
        authDomain: "gofloatersdev.firebaseapp.com",
        databaseURL: "https://gofloatersdev.firebaseio.com",
        projectId: "gofloatersdev",
        storageBucket: "gofloatersdev.appspot.com",
        messagingSenderId: "509881371385",
        appId: "1:509881371385:web:e4e3749254771b8a13b028"
      },
      baseurl: 'http://localhost:5000/gofloatersdev/us-central1/api/admin',
      appurl: 'http://localhost:5000/gofloatersdev/us-central1/api',
      loginurl: 'http://localhost:5000/gofloatersdev/us-central1/api/login',
      razorPayKey: 'rzp_test_87k0WW6Nk4NGoC'
    },
    beta: {
      firebase: {
        apiKey: "AIzaSyD0UFJFOezqn0sk9gVnRUdJ0sMa1Yi8PZE",
        authDomain: "gofloaters.firebaseapp.com",
        databaseURL: "https://gofloaters.firebaseio.com",
        projectId: "gofloaters",
        storageBucket: "gofloaters.appspot.com",
        messagingSenderId: "187167465849",
        appId: "1:187167465849:web:425043bfad6fcb46910fa8"
      },
      baseurl: 'https://us-central1-gofloatersapibeta.cloudfunctions.net/api/admin',
      appurl: 'https://us-central1-gofloatersapibeta.cloudfunctions.net/api',
      loginurl: 'https://us-central1-gofloatersapibeta.cloudfunctions.net/api/login',
      razorPayKey: 'rzp_live_L4jnEFvzps4krf'
    },
    localProd: {
      firebase: {
        apiKey: "AIzaSyD0UFJFOezqn0sk9gVnRUdJ0sMa1Yi8PZE",
        authDomain: "gofloaters.firebaseapp.com",
        databaseURL: "https://gofloaters.firebaseio.com",
        projectId: "gofloaters",
        storageBucket: "gofloaters.appspot.com",
        messagingSenderId: "187167465849",
        appId: "1:187167465849:web:425043bfad6fcb46910fa8"
      },
      baseurl: 'http://localhost:5001/gofloaters/us-central1/api/admin',
      appurl: 'http://localhost:5001/gofloaters/us-central1/api',
      loginurl: 'http://localhost:5001/gofloaters/us-central1/api/login',
      razorPayKey: 'rzp_test_87k0WW6Nk4NGoC'
    },
    // baseurl: 'https://us-central1-gofloaters.cloudfunctions.net/api/admin',
    // baseurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api/admin',
    baseurl: 'http://localhost:5000/gofloatersdev/us-central1/api/admin',
    loginServices: {
      verifylogin: '/login'
    },
    bookings: {
      addfeedback:'/addfeedback',
      bookinglist: '/bookingslist',
      getbooking: '/getbooking',
      generateBookings: '/booking/generateBookings',
      getVoucherList: '/user/getVoucherList',
      addVoucher: '/user/addVoucher',
      editVoucher: '/user/editVoucher',
      bookingListForRequestDate: '/booking/list/forRequestDate',
      searchbookingList: '/booking/search',
      // bookingListForRequestDateRange: '/booking/list/forRequestDateRange',
      bookingListSearchBy: '/booking/list/searchby',
      getspacesforcities: '/getspacesforcities',
      getspaces: '/getspaces',
      getFilteredSpaces: '/getFilteredSpaces',
      getNearestSpaces: '/spaces/search',
      createspace: '/createspace',
      clonespace: '/clonespace',
      updatespace: '/updatespace',
      bulkUpdateSpace: '/space/bulkupdate',
      addnotespace: '/addnotespace',
      updateBooking: '/updatebooking',
      bookingsettlement: '/bookingssettlement',
      editBooking: '/editbooking',
      addnoteBooking: '/addnotebooking',
      updatestatus: '/updatestatus',
      sendnotification: '/sendNotificationToTopic',
      notificationtomultipleusers : '/sendNotificationToMultipleUsers',
      sendMessage: '/sendMessage',
      spacephotos: '/space/photos',
      updateUser : '/updateuser',
      getuser: '/getuser',
      getUserBehaviour: '/user/behaviour',
      getspace: '/getspace',
      userlist: '/user/list',
      createPaymentLink: '/createpaymentlink',
      revenuereport: '/space/revenuereport',
      makeFoodPayment: '/space/makefoodpayment',
      foodredemptionsettlement:'/space/foodredemptionsettlement',
      foodreport: '/space/foodreport',
      bookinghistory: '/getbookinghistory',
      bookingintegration:'/admin/booking/booking-integration',
      floaterpassreport: '/space/floaterpassreport',
      getFloatingPoints: '/user/getFloatingPoints',
      getPromotionDetails: '/getPromotionDetails',
      addPromotion: '/addPromotion',
      editPromotion: '/editPromotion',
      getPaymentList:'/paymenttransactions', //razor
      getReferralDetails: '/user/getReferralDetails',
      getmetadata:'/getmetadata',
      filterSpaces:'/filterspaces'
    },
    foodCredits:{
      foodTransactionList:'/foodcredit/list',
      foodCreditsPurchaseList : '/space/foodpassreport'
      },
    filestorage: {
      filepath: 'images'
    },
    fcm: {
      register: '/register/device'
    }
  };
}
