import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../sharedcomponents/index';
import { BookingService } from '../appservices/booking.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import {MatDialog} from '@angular/material';
import { AddSpaceComponent } from '../add-space/add-space.component';
import * as Clipboard from 'clipboard/dist/clipboard.min.js';
import * as moment from 'moment-timezone';
import * as _ from 'underscore';
import {MatSnackBar} from '@angular/material';
import { ViewSpaceDetailsComponent } from '../view-space-details/view-space-details.component';
import { AddNoteComponent } from '../sharedcomponents/add-note/add-note.component';
import { SharedProvider } from '../appservices/shared';
import { Router } from '@angular/router';
import { LoginService } from '../appservices/login.service';

interface Cache {
	spaceType?: string;
	spaceArea?: string;
	isApproved?: boolean;
	onLine?: boolean;
}


@Component({ selector: 'app-manage', templateUrl: './manage.component.html', styleUrls: ['./manage.component.scss'] })

export class ManageComponent implements OnInit {
	params: any = {
		'custId': 'OSYbaoC6eohpUg6usgxo5Zvzog72'
	};
	searchSpaceName: string = '';
	originalSpaceName : string = '';
	gofloatersSpaceName : string = '';
	spaceDisplayName : string = '';
	public edit_spaceId: string = '';
	spaceArea: string = '';
	spaceType: string = '';
	isApproved: boolean = true;
	onLine: boolean = true;
	cache: Cache;
	clipboard: any;
	spaces = [];
	edit_space: any = {};
	cityInfo:any=[];
	filteredSpaces = [];
	public moment: any = moment;
	fromRequestDate: string = "DD-MM-YYYY";
	toRequestDate: string = "DD-MM-YYYY";
	showSpaces: boolean = false;
	unapprovedError = false;
	onlineError = false;
	// fromEditScreen : boolean = false;

	constructor(
		private _bookingservice: BookingService, 
		private loader: LoaderService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private sharedService : SharedProvider,
		private router : Router,
		private loginService : LoginService
	)
	{
		this.clipboard = new Clipboard('#cpyBtn');
		this.initializeCache();
	}

	ngOnInit() {
		//this.getSpaceDetails();
		const user = this.loginService.getCurrentUser();
		if (user.uid !== undefined) {
			this.sharedService.isLoggedIn = true;
		} else {
			this.loginNow();
		}
		if(this.sharedService.citiesMetaData)
		{
			this.cityInfo = this.sharedService.getActiveCities();
		}
		else
		{
			this.loader.display(true);
			this._bookingservice.getMetaData().then(val => {
				this.cityInfo = this.sharedService.getActiveCities();
				this.loader.display(false);
			}).catch(err => {
				console.log(err);
			});
		}
	}

	initializeCache() {
		this.cache = {};
		this.edit_space = {};
		this.edit_spaceId = '';
	}

	updateCache() {
		this.cache = {
			spaceType: this.spaceType,
			spaceArea: this.spaceArea,
			isApproved: this.isApproved,
			onLine: this.onLine
		};
	}

	async getSpaceDetails() {
		this.showSpaces  = true;
		const cache = this.cache;
		this.edit_space = {};
		this.edit_spaceId = '';
		if (cache.isApproved !== this.isApproved 
			|| cache.onLine !== this.onLine 
			|| cache.spaceArea !== this.spaceArea
			|| cache.spaceType !== this.spaceType
		) {
			this.loader.display(true);
			try {
				this.spaces = await this._bookingservice.getSpaceDetails({
					isApproved: this.isApproved,
					isOnLine: this.onLine,
					city: this.spaceArea,
					spaceType: this.spaceType
				});
				this.updateCache();
				this.doFiltering();
				// if(this.fromEditScreen)
				// {
				// 	this.doFiltering();
				// 	this.fromEditScreen = false;
				// }
				// else
				// {
				// 	this.clearFilter();
				// }				
			} catch(err) {
				this.spaces = [];
			}
			this.loader.display(false);
		}
	}

	openSpaceModel(spaceId) {
		
		this.dialog.open(ViewSpaceDetailsComponent, {
				data: {
					spaceId: spaceId,
					modelMode: true
				}
			}).afterClosed().subscribe(updateRequired => {
				
			});
	  }

	showcopytoast() {
		this.snackBar.open('Copied to Clipboard', 'Close', {
			duration: 1000,
		});
	}

	getSubType(type)
	{
		if(type != undefined)
		{
			return type.toString();
		}
		else
		{
			return 'N/A';
		}
	}

	doFiltering() {
	// _.compose will call the methods sequentiall in the order <-------
		const doClientSideFiltering =  _.compose(this.searchSpace, this.searchOriginalSpace, this.searchGofloatersSpaceName,this.searchSpaceDisplayName, this.checkDate);
		this.filteredSpaces =  doClientSideFiltering(this.spaces);
	}

	checkDate = (spaces) => {
		const fromDateTimestamp = Number(this.moment(this.fromRequestDate).tz('Asia/Kolkata'));
		const toDateTimestamp = Number(this.moment(this.toRequestDate).tz('Asia/Kolkata'));
		if (isNaN(fromDateTimestamp) || isNaN(toDateTimestamp)) {
			return spaces;
		}
		return spaces.filter(space => space.dateAdded >= fromDateTimestamp 
			&& space.dateAdded <= toDateTimestamp);
	}

	searchSpace = (spaces) => {
		if(this.searchSpaceName != '')
		{
			return spaces.filter(item => {
				var location = item.spaceName.toUpperCase()
					.search(this.searchSpaceName.toUpperCase());
				if (location >= 0) {
					return true;
				} else {
					return false;
				}
			});
		}
		else
		{
			return spaces;
		}
		
	}

	searchOriginalSpace = (spaces) => {		
		// console.log("OriginalSpace");
		if(this.originalSpaceName != '')
		{
		return spaces.filter(item => {			
			if(item.originalName == undefined)
			{
				item.originalName = item.registeredCompanyName? item.registeredCompanyName: item.spaceName;
			}
			var search = item.originalName.toUpperCase()
				.search(this.originalSpaceName.toUpperCase());
			if (search >= 0) {
				return true;
			} else {
				return false;
			}
			
			});
		}
		else
		{
			return spaces;
		}
	}


	searchGofloatersSpaceName = (spaces) => {
		if(this.gofloatersSpaceName != '')
		{
		return spaces.filter(item => {
			if(item.gofloatersSpaceName != undefined)
			{
			var searchVal = item.gofloatersSpaceName.toUpperCase()
				.search(this.gofloatersSpaceName.toUpperCase());
				if (searchVal >= 0) {
					return true;
				} else {
					return false;
				}
			}
		 });
		}
		else
		{
			return spaces;
		}
			
	}

	searchSpaceDisplayName = (spaces) => {
		if(this.spaceDisplayName != '')
		{
		return spaces.filter(item => {
			if(item.spaceDisplayName != undefined)
			{
			var searchVal = item.spaceDisplayName.toUpperCase()
				.search(this.spaceDisplayName.toUpperCase());
				if (searchVal >= 0) {
					return true;
				} else {
					return false;
				}
			}
		 });
		}
		else
		{
			return spaces;
		}
			
	}

	clearFilter() {
		this.fromRequestDate = "DD-MM-YYYY";
		this.toRequestDate = "DD-MM-YYYY";
		this.searchSpaceName = "";
		this.originalSpaceName = "";
		this.gofloatersSpaceName = "";
		this.spaceDisplayName = "";
		this.filteredSpaces = this.spaces;
	}

	editSpace(id=null) {
		
		if(!this.edit_space.spaceId && !id) {
			return false;
		}
		let spaceId =id?id:this.edit_space.spaceId;
		this.dialog.open(AddSpaceComponent, {
			data: {
				spaceID: spaceId,
				editMode: true
			}
		}).afterClosed().subscribe(updateRequired => {
			this.edit_space = {};
			this.edit_spaceId = '';
			if (updateRequired) {
				// this.fromEditScreen = true;
				this.initializeCache();
				this.getSpaceDetails();
			}
		});
	}

	downloadCSV() {
		var spacesForDownload = [];
		var headings = ["Space Name","GoFloaters Space Name","Original Space Name","Operator Name","Space Display Name","Space Id","Space Type","Space Sub Type","Meeting Room Name","Registered Company Name", "Space Description","City","Country","Locality","Street","Zipcode","Space Url","Space Added on","Booking Count","Price Per Month","Price Per Day","Price Per Hr","Seats Available","Operation Time","Facilities","New Facilities List","GST Registered","GST Number","Owner Name","Owner Email","Owner Phone","Manager Name","Manager Email","Manager Phone","Online","Security Deposit","RevenueShare","BankAccountNumber","Bankname_branch","IFSC Code","Landmark","Purpose", "New Purposes List","Photo URL","WeWork Building Id","WeWork Inventory Id","AWFIS Building Id","AWFIS Inventory Id"]
		for (let space of this.filteredSpaces ) {
		  var spaceObject = (({ spaceName,gofloatersSpaceName, originalName,operatorName ,spaceDisplayName,spaceId, spaceType,spaceSubType,meetingRoomName,registeredCompanyName, spaceDesc,city, country,locality, street, zipcode, SpaceUrl,dateAdded,bookingcount, pricepermonth,priceperday, priceperhr, seatsAvailable, operationTime, facilities,facilitiesList,gstRegistered,gstin, ownerName,ownerEmail, mobile,managerName,managerEmail,managerphone,online,securitydeposit,gofRevenueShare,bankAccountNumber,bankname_branch,ifscCode,landmark,purposes,purposesList,photoUrl,weworkBuildingId,weworkInventoryId,awfisBuildingId,awfisInventoryId}) => 
		  ({ spaceName,gofloatersSpaceName,originalName,operatorName ,spaceDisplayName,spaceId, spaceType,spaceSubType,meetingRoomName,registeredCompanyName,  spaceDesc,city, country,locality, street, zipcode, SpaceUrl, dateAdded, bookingcount, pricepermonth,priceperday, priceperhr,seatsAvailable, operationTime, facilities,facilitiesList,gstRegistered,gstin, ownerName,ownerEmail, mobile,managerName,managerEmail,managerphone,online,securitydeposit,gofRevenueShare,bankAccountNumber,bankname_branch,ifscCode,landmark,purposes,purposesList,photoUrl,weworkBuildingId,weworkInventoryId,awfisBuildingId,awfisInventoryId}))(space);
		  if(space.purposes) {
			spaceObject["purposes"] = space.purposes.toString(); // concat this object
		  }
		  if(space.facilities) {
			spaceObject["facilities"] = space.facilities.toString(); // concat this object
		  } 
		  if(space.purposesList) {
			spaceObject["purposesList"] = space.purposesList.toString(); // concat this object
		  }
		  if(space.facilitiesList) {
			spaceObject["facilitiesList"] = space.facilitiesList.toString(); // concat this object
		  } 
		  if(space.spaceSubType)
		  {
			  spaceObject["spaceSubType"] = space.spaceSubType.toString();
		  }
		  else
		  {
			  spaceObject["spaceSubType"] = 'Not Available';
		  }
		  if(!spaceObject["originalName"]) 
		  {
			  spaceObject["originalName"] = 'Not Available';
		  } 
		  if(!spaceObject["gofloatersSpaceName"]) 
		  {
			  spaceObject["gofloatersSpaceName"] = 'Not Available';
		  } 
		  if(!spaceObject["spaceDisplayName"]) 
		  {
			  spaceObject["spaceDisplayName"] = 'Not Available';
		  }  
		//   spaceObject["SpaceUrl"] = "https://app.gofloaters.com/#/home/explore/spacedetail/"+space.spaceId + "/spaceview";
		  spaceObject["SpaceUrl"] = "https://gofloaters.firebaseapp.com/ssu/"+space.spaceId;
		//   spaceObject["photoUrl"] = space.photos?space.photos[0]:"";
		  spaceObject["city"] = space.address.city;
		  spaceObject["country"] = space.address.country;
		  spaceObject["locality"] = space.address.locality;
		  spaceObject["street"] = space.address.street;
		  spaceObject["zipcode"] = space.address.zipcode;
		  spaceObject["landmark"] = space.address.landmark ? space.address.landmark : "Not Available";
		  if(typeof spaceObject["dateAdded"] != 'undefined' ) {
			spaceObject["dateAdded"] = this.moment(space.dateAdded).tz('Asia/Kolkata').format('MM/DD/YYYY');
			
		  }else {
			spaceObject["dateAdded"] = "Not Available";
		  }
	
		  if( typeof spaceObject["pricepermonth"] == 'undefined') {
			spaceObject["pricepermonth"] = "";
		  }
		  if( typeof spaceObject["priceperday"] == 'undefined') {
			spaceObject["priceperday"] = "";
		  }
		  if( typeof spaceObject["priceperhr"] == 'undefined') {
			spaceObject["priceperhr"] = "";
		  }
		  if( typeof spaceObject["seatsAvailable"] == 'undefined') {
			spaceObject["seatsAvailable"] = "";
		  }
		  if( typeof spaceObject["bookingcount"] == 'undefined') {
			spaceObject["bookingcount"] = 0;
		  }
		  if( typeof spaceObject["gstRegistered"] == 'undefined') {
			spaceObject["gstRegistered"] = false;
		  }
		  if( typeof spaceObject["gstin"] == 'undefined') {
			spaceObject["gstin"] = "Not Available";
		  }
		  // spaceObject["locality"] = space.address.toString();
		  // spaceObject["address"] = space.address.toString(); // concat this object
		  // spaceObject["photos"] = space.photos.toString(); // concat this object
		  spacesForDownload.push(spaceObject);
		}
		var options = { 
		  fieldSeparator: ',',
		  quoteStrings: '"',
		  decimalseparator: '.',
		  showLabels: { headers: (headings) }, 
		  showTitle: false,
		  useBom: true
		};
		new Angular2Csv(spacesForDownload, 'GoFloaters Spaces',{ headers: headings });
	  }
	  getdate(val) {
		return this
		  .moment(val).tz('Asia/Kolkata')
		  .format('DD/MM/YYYY');
	  }
	  note() {
		if(!this.edit_space.spaceId) {
			return false;
		}  
		this.dialog.open(AddNoteComponent, {
			data: { header:"Add Notes", button_text:"Add" ,mode:'add'},
			width: '400px',
			height: '250px'
		}).afterClosed().subscribe(updateRequired => {
			if (updateRequired) {
				this.edit_space.note = updateRequired.note;
				
				this.loader.display(true);
				this._bookingservice.updatespace(this.edit_space).then((res: any) => {
					if(res.status) {
						res.note.addedOn = moment(res.note.addedOn)
						.tz("Asia/Kolkata")
						.format("DD/MM/YYYY h:mm a");
					}
					this.loader.display(false);
					this.initializeCache();
					this.getSpaceDetails();
				}).catch((e) =>{
					this.loader.display(false);
				});
			} else {
				this.edit_space = {};
				this.edit_spaceId = '';
			}
		});
	  }
	  archive() {
		if(!this.edit_space.spaceId) {
			return false;
		}  
		if(!this.edit_space.online)
		{
			this.dialog.open(AddNoteComponent, {
				data: { header:"Archive Notes", button_text:"Take Archive" ,mode:'archive'},
				width: '400px',
				height: '250px'
			}).afterClosed().subscribe(updateRequired => {
				if (updateRequired) {
					this.edit_space.archive = true;
					this.edit_space.note = "Archive: "+updateRequired.note;
				
					this.loader.display(true);
					this._bookingservice.updatespace(this.edit_space).then((res: any) => {
						if(res.status) {
							res.note.addedOn = moment(res.note.addedOn)
							.tz("Asia/Kolkata")
							.format("DD/MM/YYYY h:mm a");
						}
						this.loader.display(false);
						this.initializeCache();
						this.getSpaceDetails();
					}).catch((e) =>{
						this.loader.display(false);
					});
				}else {
					this.edit_space = {};
					this.edit_spaceId = '';
				}
			});
		}
		else
		{
			this.onlineError = true;
		}

	  }
	  clone() {
		if(!this.edit_space.spaceId) {
			return false;
		}
		this.loader.display(true);
		this._bookingservice.clonespace({id:this.edit_space.spaceId}).then((res: any) => {
			this.loader.display(false);
			this.initializeCache();
			this.snackBar.open('New space is created, '+res.spaceName, 'Close', {
				duration: 5000,
			});
		}).catch((e) =>{
			this.loader.display(false);
		});
	  }
	  offline() {
		if(!this.edit_space.spaceId) {
			return false;
		}
		if(this.edit_space.online) {
			this.dialog.open(AddNoteComponent, {
				data: { header:"Offline Notes", button_text:"Take Offline" ,mode:'offline'},
				width: '400px',
				height: '250px'
			}).afterClosed().subscribe(updateRequired => {
				if (updateRequired) {
					this.edit_space.online = false;
					this.edit_space.note = "Offline: "+updateRequired.note;
					
					this.loader.display(true);
					this._bookingservice.updatespace(this.edit_space).then((res: any) => {
						this.loader.display(false);
						this.initializeCache();
						this.getSpaceDetails();
						
					}).catch((e) =>{
						this.loader.display(false);
					});
				}else {
					this.edit_space = {};
					this.edit_spaceId = '';
				}
			});
		} else {
			if(this.edit_space.isApproved)
			{
				this.edit_space.online = true;
				this.edit_space.note = "Online: Space back to online";
				
				this.loader.display(true);
				this._bookingservice.updatespace(this.edit_space).then((res: any) => {
					this.loader.display(false);
					this.initializeCache();
					this.getSpaceDetails();
				}).catch((e) =>{
					this.loader.display(false);
				});
			}
			else
			{
				this.unapprovedError = true;
			}			
		}
		
	}
	  change(event) {
		  this.edit_space = this.filteredSpaces.find(o => o.spaceId === event.value);
	  }

	  loginNow() {
		this.loader.display(false);
		this.router.navigate(["/login"]);
	}
}
