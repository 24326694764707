
import {combineLatest, throwError as observableThrowError,  Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import { AppConstants } from "../app.constants";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase";
import { async } from "q";
import { SharedProvider } from "./shared";
import { Subject} from "rxjs/Rx";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import * as _ from 'underscore';

@Injectable()
export class BookingService {
  url = "api/books";
  serviceUrl: any;
  baseurl: any;
  appurl: string;
  options: any;
  bookingListener:any;
  public bookings: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  // public orgBookings: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  currdate: Date;
  headers: Headers;
  constructor(
    private http: HttpClient,
    public _ANGFIRE: AngularFireAuth,
    private db:AngularFireDatabase,
    public sharedService: SharedProvider
  ) {
    // this.baseurl = AppConstants.CONSTANTS.baseurl;
    this.currdate = new Date();
		this.currdate.setHours(0, 0, 0, 0);
    let fromDate = this.currdate.getTime();
  // Add 24 hours to the from date
    let toDate = fromDate + 60 * 60 * 24 * 1000;
    this.baseurl =
      AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
    this.appurl =
      AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].appurl;
    this.serviceUrl = AppConstants.CONSTANTS.bookings;
  }

  private extractData(res: any) {
    const body = res;
    return body.data || {};
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return observableThrowError(error.message || error);
  }
  async getbookinglist(value: any) {
    
    const url = this.baseurl + this.serviceUrl.bookinglist;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }



  async getbooking(value: any) {
    
    const url = this.baseurl + this.serviceUrl.getbooking;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  
async filterSpaces(value)
{
  const url = this.baseurl + this.serviceUrl.filterSpaces;
  return new Promise((resolve, reject) => {
    return this.http
      .post(url, JSON.stringify(value)).pipe(
      map((res:any) => res))
      .subscribe(
        values => {
          resolve(values);
        },
        err => {
          reject(err);
        }
      );
  });
}

  async updateFoodRedemptionSettlement(value) {
     
    const url = this.baseurl + this.serviceUrl.foodredemptionsettlement;
    return new Promise((resolve, reject) => {
        return this.http
            .post(
                url,
                value
            ).pipe(
            map((res:any) => res))
            .subscribe(values => {
                resolve(values);
            }, err => {
                reject(err);
            });
    })
  }
  async updateBookingSettlement(value) {
         
    const url = this.baseurl + this.serviceUrl.bookingsettlement;
    return new Promise((resolve, reject) => {
        return this.http
            .post(
                url,
                value
            ).pipe(
            map((res:any) => res))
            .subscribe(values => {
                resolve(values);
            }, err => {
                reject(err);
            });
    })
  }
  async getBookingHistory(value) {
         
    const url = this.appurl + this.serviceUrl.bookinghistory;
    return new Promise((resolve, reject) => {
        return this.http
            .post(
                url,
                value
            ).pipe(
            map((res:any) => res))
            .subscribe(values => {
                resolve(values);
            }, err => {
                reject(err);
            });
    })
}

async getBookingIntegrationsData(value) {
         
  const url = this.appurl + this.serviceUrl.bookingintegration;
  return new Promise((resolve, reject) => {
      return this.http
          .post(
              url,
              value
          ).pipe(
          map((res:any) => res))
          .subscribe(values => {
              resolve(values);
          }, err => {
              reject(err);
          });
  })
}
 /* Shyam 3rd Feb 2024 - Function not used 
  async getbookinglistForRequestDate(requestDate: any) {
    const url = this.baseurl + this.serviceUrl.bookingListForRequestDate;
    let fromDate = requestDate.requestDate;
    let toDate = fromDate + 60 * 60 * 24 * 1000;  // Add 24 hours to the from date
    this.db.list('bookings', ref => ref.orderByChild("reqDate")
    .startAt(fromDate)
    .endAt(toDate)).valueChanges().subscribe(res =>{
      this.bookings.next(res);
    });
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(requestDate)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  */
  // async getbookinglistForRequestDate(requestDate: any) {
  //     const token1 = await this._ANGFIRE.auth.currentUser.getIdToken();
  //     const headers1 = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token1 });
  //     this.options = new RequestOptions({ headers: headers1 });
  //     const url = this.baseurl + this.serviceUrl.bookingListForRequestDate;
  //     return new Promise((resolve, reject) => {
  //         return this.http
  //           .post(url, JSON.stringify(requestDate), this.options)
  //           .subscribe(values => {
  //             resolve(values);
  //           }, err => {
  //             reject(this.handleErrorObservable);
  //           })
  //       })

  //     // return this.http.post(url, JSON.stringify(requestDate), this.options)
  //     //     .map((response: any) => {
  //     //         return response;
  //     //     }).catch(this.handleErrorObservable);
  // }

  async searchbookinglist(searchData: any) {
    console.log("searchbookinglist",searchData);
    if (this.bookingListener) {
      this.bookingListener.unsubscribe();
      console.log("bookingListener unsubscribed");
    }
    this.bookings.next([]); 
    // const url = this.baseurl + this.serviceUrl.searchbookingList;
    const searchby = searchData.searchby;
    const value = searchData.value;
    
    let from;
    let to;
    if (searchby === "reqDate" || searchby === "bookingdate" || searchby === "reqbookingdate") {
      from = value;
      to = from + 60 * 60 * 24 * 1000;
      console.log("From date " + from + " toDate " + to);
    } else {
      from = value;
      to = value;
    }
    if(searchby === "reqbookingdate") {
      // this.listeners = [];
      this.bookingListener = combineLatest(this.db.list('bookings', ref => ref.orderByChild("reqDate")
      .startAt(from)
      .endAt(to)).valueChanges(),this.db.list('bookings', ref => ref.orderByChild("bookingdate")
      .startAt(from)
      .endAt(to)).valueChanges()).pipe(
        map(([first, second]) => {
          // forkJoin returns an array of values, here we map those values to an object
          return { first, second}
        })
      ).subscribe((res:any) =>{
        res.second = res.second.filter((booking:any) => booking.reqDate < from);
        console.log("searchbookinglist - booking update","-1");
        this.bookings.next([...res.first, ...res.second]);
      });
    } 
    else if(searchby === "spaceId")
    {
      if(value && value.length > 0)
      {
        // this.listeners = [];
        this.bookingListener = this.db.list('bookings', ref => ref.orderByChild(searchby).equalTo(from)).valueChanges().subscribe(res =>{
          console.log("searchbookinglist - booking update","-2");
        this.bookings.next(res);
        });
      }
      else
      {
       this.bookings.next([]); 
      }
    } else if (searchby === "bookingdate" || searchby === "reqDate") {
      this.bookingListener = this.db.list('bookings', ref => ref.orderByChild(searchby)
      .startAt(from)
      .endAt(to)).valueChanges().subscribe(res =>{
        // console.log("res1",res,new Date().getTime());
        console.log("searchbookinglist - booking update","-4");
        this.bookings.next(res);
      });
    } else {
      this.bookingListener = this.db.list('bookings', ref => ref.orderByChild(searchby)
      .equalTo(from)).valueChanges().subscribe(res =>{
        // console.log("res1",res,new Date().getTime());
        console.log("searchbookinglist - booking update","-3");
        this.bookings.next(res);
      });
    }
  }

  async getBookingDetails(bookingId) {
    return new Promise((resolve, reject) => {
      this.db.database.ref('bookings/' + bookingId).once("value",snapshot => {
        if(snapshot.val()) {
          let res = snapshot.val();
          // console.log("res4",res,new Date().getTime());
          resolve(res);
        }
      });
    });
  }

  /* Shyam 3rd Feb 2024 >> Function is not used - Commenting out
  async getbookinglistForRequestDateRange(requestDateRange: any) {
    
    const url = this.baseurl + this.serviceUrl.bookingListForRequestDateRange;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(requestDateRange)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  */

//TODO : Rel 7.4.2 - Add function to see the list of bookings by an organization
 /* Shyam 3rd Feb 2024 - Function not used 
  async getbookinglistForOrganization(searchByData:any){
    console.log("getbookinglistForOrganization",searchByData);
    let bookingObservables = [];
    let organizationId = searchByData.orgId;
    let fromRequestDate = searchByData.fromRequestDate;
    let toRequestDate = searchByData.toRequestDate;
    this.db.database.ref('organizations/' + organizationId).once("value",snapshot => {
      if (snapshot.val()) {
        let organization = snapshot.val();
        let orgUsers = organization.users;
        console.log("orgUsers",orgUsers);
        orgUsers.forEach(orgUserId => {
          bookingObservables.push(this.db.list('bookings', ref => ref.orderByChild('custId').equalTo(orgUserId)).valueChanges());
        });
        combineLatest(bookingObservables).subscribe( resArray => {
          // console.log("bookingObservables Res",resArray);
          let userBookings = [];
          resArray.forEach(res => {
            // console.log("res ",JSON.stringify(res));
            userBookings = [].concat(userBookings,res);
            // console.log("userBookings ",userBookings);
          })
          console.log("userBookings Res",userBookings);
          this.orgBookings.next(userBookings);
        })
      }
    })
  }
  */
  async getOrganizationDetails(organizationId) {
    return new Promise((resolve, reject) => {
      this.db.database.ref('organizations/' + organizationId).once("value", snapshot => {
        if (snapshot) {
          resolve(snapshot.val());
        } else {
          resolve({});
        }
      })
    });
  }

  async getuserlist(fromDate,toDate) {
    let bodyData = {
      fromDate: fromDate,
      toDate:toDate
    };
    const url = this.baseurl + this.serviceUrl.userlist;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(bodyData)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  async getbookinglistSearchBy(requestDateRange: any) {
    
    const url = this.baseurl + this.serviceUrl.bookingListSearchBy;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(requestDateRange)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async getspacelist(value) {
    
    const url = this.baseurl + this.serviceUrl.getspaces;
    let Body_data = {
      id: value
    };
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(Body_data)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }


  async getSpacesForCities(payload: any) {
    const url = this.baseurl + this.serviceUrl.getspacesforcities;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, payload).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async getAllSpaces() {
    
    const url = this.baseurl + this.serviceUrl.getspaces;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, {}).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            this.sharedService.setSpaceList(values);
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

async getMetaData()
{
  const url = this.appurl + this.serviceUrl.getmetadata;
  return new Promise((resolve, reject) => {
    return this.http.get(url).pipe(
      map((res:any) => res))
      .subscribe(
        values => {
          this.sharedService.setMetaData(values);
          resolve(values);
        },
        err => {
          reject(err);
        }
      );
  });
}

  // async getbooking(value: any) {
  //     const token = await this._ANGFIRE.auth.currentUser.getIdToken();
  //     let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
  //     let options = new RequestOptions({ headers: headers });
  //     const url = this.baseurl + this.serviceUrl.getbooking;
  //     return new Promise((resolve, reject) => {
  //         return this.http.post(url, JSON.stringify(value))
  //             .map((res:any) => res)
  //             .subscribe(values => {
  //                 resolve(values);
  //             }, err => {
  //                 reject(err);
  //             });
  //     });
  // }
  async createOrAddSpacePhotos(value) {
    
    const url = this.appurl + this.serviceUrl.spacephotos;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, value).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            this.getspacelist(value.ownerId).then(val => {
              resolve(values);
            });
          },
          err => {
            reject(err);
          }
        );
    });
  }
  async createspace(value) {
    
    const url = this.baseurl + this.serviceUrl.createspace;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, value).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  async updatespace(value) {
    
    const url = this.baseurl + this.serviceUrl.updatespace;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async bulkUpdateSpace(value) {
    
    const url = this.baseurl + this.serviceUrl.bulkUpdateSpace;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }


  async clonespace(value) {
    const url = this.baseurl + this.serviceUrl.clonespace;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, value).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  async addNoteSpace(value) {
    
    const url = this.baseurl + this.serviceUrl.addnotespace;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  async addNoteBooking(value) {
    
    const url = this.baseurl + this.serviceUrl.addnoteBooking;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  async updateBooking(value) {
    

    const url = this.baseurl + this.serviceUrl.updateBooking;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  async editBooking(value) {
    

    const url = this.baseurl + this.serviceUrl.editBooking;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  async changestatus(value) {
    const url = this.baseurl + "/updatestatus";
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async retriggerPartnerBooking(value) {
    const url = this.baseurl + "/booking/retrigger-partner-booking";
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async getSpace(value) {
    

    const url = this.baseurl + this.serviceUrl.getspace;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  async getUser(searchEmailId) {
    

    const url = this.baseurl + this.serviceUrl.getuser;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(searchEmailId)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  async updateUser(value) {
    

    const url = this.baseurl + this.serviceUrl.updateUser;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async saveSpaceFeedback(value) {
    
    const url = this.appurl + this.serviceUrl.addfeedback;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  async createPaymentLink(value) {
    
    const url = this.baseurl + this.serviceUrl.createPaymentLink;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
    async generateBookings( payload ) : Promise<any> {
        

        const url = this.appurl + this.serviceUrl.generateBookings;
        return new Promise((resolve, reject) => {
            return this.http
                .post(
                    url,
                    payload
                ).pipe(
                map((res:any) => res))
                .subscribe(values => {
                    resolve(values);
                }, err => {
                    reject(err);
                });
        });
    }

    async getSpaceDetails( payload ) : Promise<any> {
        let options = {};
        if(payload) {
          let object_keys = Object.keys(payload);
          object_keys.forEach(key => {
            if(payload[key] === undefined) {
              delete payload[key];
            }
          });
          options = {params : new HttpParams({fromObject:payload})};
        } 
        const url =  this.baseurl + this.serviceUrl.getFilteredSpaces;
        return new Promise((resolve, reject) => {
            this.http
                .get(url, options).pipe(
                map(res => res))
                .subscribe(data => {
                    resolve(data)
                }, err => {
                    reject(err);
                })
        });
    }

  async getRevenueReport(value) {
    
    const url = this.appurl + this.serviceUrl.revenuereport;
    return new Promise((resolve, reject) => {
      return this.http
        .post(url, JSON.stringify(value)).pipe(
        map((res:any) => res))
        .subscribe(
          values => {
            resolve(values);
          },
          err => {
            reject(err);
          }
        );
    });
  }
    async getVoucherList( payload ): Promise<any> {
        
        const url = this.baseurl + this.serviceUrl.getVoucherList;
        return new Promise((resolve, reject) => {
            return this.http
                .post(
                    url,
                    payload
                ).pipe(
                map((res:any) => res))
                .subscribe(values => {
                    resolve(values);
                }, err => {
                    reject(err);
                });
        });
    }
    sortByDistance(a,b) {
      if (a.distance < b.distance)
        return -1;
      if (a.distance > b.distance)
        return 1;
      return 0;
    }
    
    async getNearestSpaces( payload ): Promise<any> {
        let options = {};
        if(payload) {
          let object_keys = Object.keys(payload);
          object_keys.forEach(key => {
            if(payload[key] === undefined) {
              delete payload[key];
            }
          });
          options = {params : new HttpParams({fromObject:payload})};
        }
        const url =  this.baseurl + this.serviceUrl.getNearestSpaces;
        return new Promise((resolve, reject) => {
            this.http
                .get(url,options).pipe(
                map((res:any) => {
                  let data = res;
                  data.spaces = data.spaces.sort(this.sortByDistance);
                  return data;
                }))
                .subscribe(data => {
                    resolve(data);
                }, err => {
                    reject(err);
                });
        });
    }

    async addVoucher (payload): Promise<any> {
        
        const url = this.baseurl + this.serviceUrl.addVoucher;
        return new Promise((resolve, reject) => {
            return this.http
                .post(
                    url,
                    payload
                ).pipe(
                map((res:any) => res))
                .subscribe(values => {
                    resolve(values);
                }, err => {
                    reject(err);
                });
        });
    }

    async editVoucher (payload): Promise<any> {
        
        const url = this.baseurl + this.serviceUrl.editVoucher;
        return new Promise((resolve, reject) => {
            return this.http
                .post(
                    url,
                    payload
                )
                .subscribe(values => {
                    resolve(values);
                }, err => {
                    reject(err);
                });
        });
	}

	async getFoodReport(value) {
		const url = this.appurl + this.serviceUrl.foodreport;
		return new Promise((resolve, reject) => {
			return this.http
				.post(url, JSON.stringify(value)).pipe(
				map((res:any) => res))
				.subscribe(
					values => {
						resolve(values);
					},
					err => {
						reject(err);
					}
				);
		});
	}

  async makeFoodPayment(payload) {
    const url = this.appurl + this.serviceUrl.makeFoodPayment;
		return new Promise((resolve, reject) => {
			return this.http
				.post(url, payload).pipe(
				map((res:any) => res))
				.subscribe(
					values => {
						resolve(values);
					},
					err => {
						reject(err);
					}
				);
		});
  }

  async getPaymentList(searchData) {
    const url = this.baseurl + this.serviceUrl.getPaymentList;
		return new Promise((resolve, reject) => {
			return this.http
				.post(url, searchData).pipe(
				map((res:any) => res))
				.subscribe(
					values => {
						resolve(values);
					},
					err => {
						reject(err);
					}
				);
		});
  }

	async getFloaterPassReport(value) {
		const url = this.appurl + this.serviceUrl.floaterpassreport;
		return new Promise((resolve, reject) => {
			return this.http
				.post(url, JSON.stringify(value)).pipe(
				map((res:any) => res))
				.subscribe(
					values => {
						resolve(values);
					},
					err => {
						reject(err);
					}
				);
		});
  }

	async getUserFloatingPoints(value) {
		const url = this.baseurl + this.serviceUrl.getFloatingPoints;
		return new Promise((resolve, reject) => {
			return this.http
				.post(url, JSON.stringify(value)).pipe(
				map((res:any) => res))
				.subscribe(
					values => {
						resolve(values.floatingpoints);
					},
					err => {
						reject(err);
					}
				);
		});
	}

  async getPromotionDetails(): Promise<any> {
    const url = this.baseurl + this.serviceUrl.getPromotionDetails;
    return new Promise((resolve, reject) => {
        return this.http
            .post(
                url,
                {}
            ).pipe(
            map((res:any) => res))
            .subscribe(values => {
                resolve(values);
            }, err => {
                reject(err);
            });
    });
}

async addPromotion(payload:any, editMode?: Boolean): Promise<any> {
  let url : string = '';
  if (editMode) {
  url = this.baseurl + this.serviceUrl.editPromotion;
  } else {
    url = this.baseurl + this.serviceUrl.addPromotion;
  }
  return new Promise((resolve, reject) => {
      return this.http
          .post(
              url,
              payload,
              {responseType:'text' as 'json'}
          ).toPromise()
          .then(res=>{
          resolve(res);
          }).catch(err=>{
            reject(err)
          })
  });
}

async getReferralDetails(): Promise<any> {
  const url = this.baseurl + this.serviceUrl.getReferralDetails;
  return new Promise((resolve, reject) => {
      return this.http
          .post(
              url,
              {}
          ).pipe(
          map((res:any) => res))
          .subscribe(values => {
              resolve(values);
          }, err => {
              reject(err);
          });
  });
}


async captureRazorpayAmount(payload) {
  
  const url = this.baseurl + '/razorpay/capture';
  return new Promise((resolve, reject) => {
    return this.http
      .post(url, payload).pipe(
      map((res:any) => res))
      .subscribe(
        values => {
          resolve(values);
        },
        err => {
          reject(err);
        }
      );
  });
}

async getUserBehaviour(userId) {

  const url = this.baseurl + this.serviceUrl.getUserBehaviour;
  return new Promise((resolve, reject) => {
    return this.http
      .post(url, {
        uid: userId
      }).pipe(
      map((res : any) => res))
      .subscribe(
        values => {
          resolve(values);
        },
        err => {
          reject(err);
        }
      );
  });
}

async getUserBookings(userId) {
  return new Promise((resolve, reject) => {
    return this.db.list('bookings', ref => ref.orderByChild("custId")
    .equalTo(userId))
    .query.once('value').then((snapshot) => {
      
      const bookings = snapshot.val();
      let bookingArray = [];
      let object_keys = Object.keys(bookings);
          object_keys.forEach(key => {
            bookingArray.push(bookings[key]);
          });
      bookingArray = _.sortBy(bookingArray, r => r.startTime);
      console.log("bookingArray 1",bookingArray);

      bookingArray = bookingArray.slice(0, 10);
      console.log("bookingArray 2",bookingArray);
      // console.log("User bookings",bookingArray);
      resolve(bookingArray);
    });
  });
}

}


