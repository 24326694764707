
import {combineLatest, throwError as observableThrowError,  Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import { AppConstants } from "../app.constants";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase";
import { async } from "q";
import { SharedProvider } from "./shared";
import { Subject} from "rxjs/Rx";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import * as _ from 'underscore';

@Injectable()
export class EnquiryService {
  options: any;
  public enquiries: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  currdate: Date;
  headers: Headers;
  constructor(
    private http: HttpClient,
    public _ANGFIRE: AngularFireAuth,
    private db:AngularFireDatabase,
    public sharedService: SharedProvider
  ) {
    // this.baseurl = AppConstants.CONSTANTS.baseurl;
    this.currdate = new Date();
  }

  async searchEnquirylist(searchData: any) {
    console.log("searchEnquirylist",searchData);
    const searchby = searchData.searchby;
    const value = searchData.fromEnquiryDate;
    let from;
    let to;
    from = searchData.fromEnquiryDate;
    to = searchData.toEnquiryDate + 60 * 60 * 24 * 1000;
    console.log("From date " + from + " toDate " + to);
    this.db.list('enquiries', ref => ref.orderByChild(searchby)
      .startAt(from)
      .endAt(to)).valueChanges().subscribe(res =>{
        console.log("res",res,new Date().getTime());
        this.enquiries.next(res);
      });    
  }
}


