import { Component, OnInit, ViewChild } from '@angular/core';
import { BookingService } from '../appservices/booking.service';
import { Router } from '@angular/router';
import { LoaderService } from '../sharedcomponents/index';
import * as moment from 'moment-timezone';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { FcmCloudMessagingService } from '../appservices/fcm-cloud-messaging.service';
import { LoginService } from '../appservices/login.service';
import { AuthenticationService } from '../appservices/authentication.service';
import { MatDialog, MatTableDataSource, MatTable } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { EditBookingComponent } from '../edit-booking/edit-booking.component';
import { map,takeUntil } from 'rxjs/operators';
import {Subscription,Subject} from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import * as _ from "lodash";
import { SharedProvider } from '../appservices/shared';
import { promise } from 'protractor';
@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BookingsComponent implements OnInit {
  public filteredbookings = [];
  private sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  bookingsSubscribed: boolean = false;
  bookingSubscription: Subscription;
  filterByStatus: string;

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  columnsToDisplay = [
    'edit',
    'Booking ID',
    'Owner',
    'customerName',
    'Days to booking',
    'Space',
    // 'Space SubType',
    // 'Mobile Number',
    'Request Date & Time',
    'Booking Date & Time',
    'Total Duration',
    'Seats',
    'Pass Type',
    'Status',
    'Priority',
    'GoFloaters Space Name',
    'City',
    // 'Space Type',
    'Payment Made',
    'Credits Redeemed',
    'Organization Id'
  ];
  expandedElement: any;
  bookings = [];
  public moment: any = moment;
  searchby: any = "reqbookingdate";
  sortby: any = "reqDate";
  searchvalue: any;

  bookingStatus: string;
  availableStatus = [{
    key: 'Requested',
    value: 'Requested'
  }, {
    key: 'Confirmed',
    value: 'Confirmed'
  }];
  // availableStatus = [{
  //   key: 'Requested',
  //   value: 'Requested'
  // }, {
  //   key: 'Checkin',
  //   value: 'Checkin'
  // }, {
  //   key: 'Checkout',
  //   value: 'Checkout'
  // }, {
  //   key: 'closed',
  //   value: 'Closed'
  // }, {
  //   key: 'Confirmed',
  //   value: 'Confirmed'
  // }, {
  //   key: 'Prepaid',
  //   value: 'Prepaid'
  // }, {
  //   key: 'CheckoutInitiated',
  //   value: 'Checkout Initiated'
  // }, {
  //   key: 'Cancelled',
  //   value: 'Cancelled'
  // }, {
  //   key: 'prepaid',
  //   value: 'prepaid'
  // }];
  // currdate : any = this
  //   .moment().tz('Asia/Kolkata')
  //   .format('YYYY-MM-DD');
  currdate: Date;
  bookings_realtime: any;
  spaceCollection: any;
  datasource = new MatTableDataSource<any>();
  showCancelDialog: boolean = false;
  showErrorDialog: boolean = false;
  showBookingConfirmDialog: boolean = false;
  isLoaderBeingDisplayed: boolean;
  showMyBookings: boolean = false;
  showBookingsOf: string = "Team@gofloaters.com";
  destroy$ = new Subject();
  organizations: string[] = [];
  selectedOrgId: string = 'all';

  constructor(
    public _bookingservice: BookingService,
    private loader: LoaderService,
    public message: FcmCloudMessagingService,
    private loginService: LoginService,
    private authService: AuthenticationService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private sharedService: SharedProvider
  ) {
    this.showBookingsOf = "Team@gofloaters.com";
    this.filterByStatus = "All";

  }
  checkCafe(spaceType) {
    return spaceType === 'Cafe' || spaceType === 'Restaurant';
  }

  checkSharedOffice(spaceType) {
    return spaceType === 'Shared Workspace';
  }

  checkMeetingSpace(spaceType) {
    return spaceType === 'Conference Room' || spaceType === 'Training Room';
  }
  checkDate(bookingDate, currDate) {
    if (bookingDate.getMonth() == currDate.getMonth() && bookingDate.getFullYear() == currDate.getFullYear()) {
      return bookingDate.getDate() - currDate.getDate();
    } else {
      return 32;
    }
  }
  priorityValue(priority) {
    if (priority === 'High') {
      return 1;
    } else if (priority === 'Med') {
      return 2;
    } else {
      return 3;
    }
  }
  statusValue(status) {
    switch (status) {
      case 'Requested': return 1;
      case 'Confirmed': return 2;
      case 'Checkin': return 3;
      case 'CheckoutInitiated': return 4;
      case 'CHECKOUT': return 5;
      case 'Prepaid': return 6;
      case 'Cancelled': return 7;
    }
  }
  sortBookings() {
    let that = this;
    this.filteredbookings.sort(function (a, b) {
      switch (that.sortby) {
        case 'priority': return that.priorityValue(a.priority) - that.priorityValue(b.priority);
        case 'bookingdate': return new Date(a.bookingdate).getTime() - new Date(b.bookingdate).getTime();
        case 'status': return that.statusValue(a.status) - that.statusValue(b.status);
        default: return new Date(a.reqDate).getTime() - new Date(b.reqDate).getTime();
      }
    });
    this.datasource.data = this.filteredbookings;
    this.datasource.sort = this.sort;
    if (this.datasource.data.length > 0) {
      //this.table.renderRows();
    }
  }
  filterBookings() {
    if (this.filterByStatus === "All" && this.showBookingsOf === "Team@gofloaters.com" && this.selectedOrgId === 'all') {
      this.datasource.data = this.filteredbookings;
    }
    this.filterBookingsByStatus();
    this.filterUserBookings();
    this.filterByOrganization();
  }
  filterBookingsByStatus() {
    let bookingByStatus = [];
    console.log("this.filterByStatus", this.filterByStatus);
    if (this.filterByStatus != "All") {
      bookingByStatus = this.filteredbookings.filter(booking => {
        if (this.filterByStatus.toLowerCase() == booking.status.toLowerCase()) {
          return true;
        } else {
          return false
        }
      });
      console.log("bookingByStatus", bookingByStatus);
      this.datasource.data = bookingByStatus;
    } 
    else {
      this.datasource.data = this.filteredbookings;
    }
  }
  filterUserBookings() {
    // let that = this;
    let userBookings = [];
    // console.log("this.showBookingsOf", this.showBookingsOf);
    if (this.showBookingsOf != "Team@gofloaters.com") {
      userBookings = this.datasource.data.filter(booking => {
        //If the user's email id and the bbooking owner match the return true
        // let user = this.loginService.getCurrentUser();
        if (booking.bookingOwner) {
          // console.log(booking.bookingid,booking.bookingOwner,user.email);
          if (this.showBookingsOf.toLowerCase() == booking.bookingOwner.toLowerCase()) {
            // if (user.email.toLowerCase() == booking.bookingOwner.toLowerCase()) {
            return true;
          } else {
            return false
          }
        } else {
          return false;
        }
      });
      console.log("userBookings", userBookings);
      this.datasource.data = userBookings;
    } 
    // else {
    //   this.datasource.data = this.filteredbookings;
    // }
  }
  filterByOrganization() {
    if (this.selectedOrgId != 'all') {
      this.datasource.data = this.datasource.data.filter(booking => 
        booking.organizationId === this.selectedOrgId
      );
    }
  }

  bookingPriorityToday(booking, diff) {
    if (booking.status === 'Requested' && diff <= 60) {
      // return 'High';
      return { level: 'High', reason: "Booking in the next 60 mins" };
    } else if (booking.status === 'Requested' && diff > 60 && diff <= 240) {
      // return 'Med';
      return { level: 'High', reason: "Booking in the next 4 hours" };
    } else if (booking.status === 'Confirmed' && (this.checkMeetingSpace(booking.spaceType) || this.checkSharedOffice(booking.spaceType))) {
      // return 'High';
      return { level: 'High', reason: "Confirmed booking today. Payment yet to be made." };
    } else if (diff < 0 && (this.checkCafe(booking.spaceType)) && booking.status === 'Confirmed') {
      // return 'Med';
      return { level: 'Med', reason: "Cafe booking in confirmed status" };
    } else if (booking.status === 'Cancelled') {
      //Space partner has cancelled the booking. This should become high priority.
      if (booking.cancelledby && booking.cancelledby == "Space partner") {
        return { level: 'High', reason: "Booking has been cancelled by space partner. Provide alternate options." };
      } else {
        return { level: '', reason: "NA" };
      }
    }
    // return 'Low';
    return { level: 'Low', reason: "NA" };
  }
  bookingPriorityNextday(booking) {
    if (booking.status === 'Confirmed' && (this.checkMeetingSpace(booking.spaceType) || this.checkSharedOffice(booking.spaceType))) {
      // Check if the confirmation was done 4 hours before. If it was then mark the priority as High
      if ((new Date().getTime() - booking.lastUpdatedAt) > 4 * 60 * 60 * 1000) {
        // return 'High';
        return { level: 'High', reason: "Booking has been confirmed for more than 4 hours. Payment yet to be made." };
      } else {
        // return 'Med';
        return { level: 'Med', reason: "Booking has been confirmed. Payment yet to be made." };
      }
    } else if (booking.status === 'Cancelled') {
      //Space partner has cancelled the booking. This should become high priority.
      if (booking.cancelledby && booking.cancelledby == "Space partner") {
        return { level: 'High', reason: "Booking has been cancelled by space partner. Provide alternate options." };
      } else {
        return { level: '', reason: "NA" };
      }
    }
    // return 'Low';
    return { level: 'Low', reason: "NA" };
  }
  bookingPriorityPast(booking) {
    if (booking.status === 'Confirmed' || booking.status === 'Requested') {
      return { level: 'High', reason: "Old booking still in requested or confirmed status" };
    } else if (booking.status === 'Cancelled') {
      return { level: '', reason: "NA" };
    }
    return { level: 'Low', reason: "NA" };
  }

  ngOnInit() {
    this.bookingsSubscribed = false;
    // this._bookingservice.bookings.unsubscribe();
    if (this.loginService.getCurrentUser().uid !== undefined) {
      this.message.enableNotifications(this.loginService.getCurrentUser());
    }
    // this.spaceCollection = this.sharedService.getSpaceCollection();
    // this.loader.display(true);
    // this.isLoaderBeingDisplayed = true;
    this.currdate = new Date();
    /*
    * We are now storing all the space properties and user properties on the booking object
    if (this.sharedService.isSpaceCollectionLoaded) {
      console.log("Spaces Loaded");
      this.filterdata();
    }
    else {
      console.log("Spaces Not Loaded Yet");
      this.sharedService.getSpacesList().then(val => {
        // this.spaceCollection = val;
        // this.getBookingsList();
        this.filterdata();
      });
    } 
    */
    //Apply filters
    this.filterdata();
    // Subscribe to bookings
    this.getBookingsList();
    // if (this.authService.getCurrentUser().uid !== undefined) {
    //   this.message.enableNotifications(this.authService.getCurrentUser());
    // }
    //this.getBookingsDetails();	

    // this.currdate.setHours(0, 0, 0, 0);
    // let requestDateTimestamp = this.currdate.getTime();
    // this.filterdata();
    // this.getBookingsDetailsForRequestDate(requestDateTimestamp);
  }
  // refresh() {
  //   //this.getBookingsDetails();
  //   let requestDateTimestamp = new Date(this.currdate).getTime(); 
  //   //Do GMT offset
  //   requestDateTimestamp = requestDateTimestamp - 5.5*60*60*1000;
  //   this.getBookingsDetailsForRequestDate(requestDateTimestamp);
  //   // this.getBookingsDetailsForRequestDate(this.currdate);
  // }

  ngOnDestroy() {
    // if (this.bookingsSubscribed) {
    //   this._bookingservice.bookings.unsubscribe();
    // }
    if (this.bookingSubscription) {
      console.log("ngOnDestroy bookingSubscription unsubscribed");
      this.bookingSubscription.unsubscribe();
    }
    // this.destroy$.next();
    // this.destroy$.complete();
  }

  getBookingsList() {
    this.bookingSubscription = this._bookingservice.bookings.subscribe(async res => {
      console.log("getBookingsList", res);
      this.bookingsSubscribed = true;
      this.bookings = res.map(booking => {
        // let spaceId = booking.spaceId;
        // let spaceInfo = this.sharedService.getSpaceDetailsFromSpaceCollection(spaceId);
        // booking.spaceName = spaceInfo.spaceName;
        // booking.originalSpaceName = spaceInfo.originalSpaceName;
        // booking.gofloatersSpaceName = spaceInfo.gofloatersSpaceName;
        // booking.spaceDisplayName = spaceInfo.spaceDisplayName;
        // booking.managerName = spaceInfo.managerName;
        // booking.managerPhone = spaceInfo.managerPhone;
        // booking.spaceSubType = spaceInfo.spaceSubType;
        let bookingDate = new Date(booking.bookingdate);
        let currDate = new Date();
        let diff = (bookingDate.getTime() - currDate.getTime()) / (60 * 1000);
        let date = this.checkDate(bookingDate, currDate);
        if (date < 0) {
          booking.priority = this.bookingPriorityPast(booking);
        } else if (date > 0) {
          booking.priority = this.bookingPriorityNextday(booking);
        } else {
          booking.priority = this.bookingPriorityToday(booking, diff);
        }
        if (booking.awfis || booking.wework || booking.cowrks || booking.innov8) {
          if (booking.awfis) {
            booking["partnerStatus"] = booking.awfis.orderStatus;
            booking["partnerOrderNumber"] = booking.awfis.orderNumber;
          }
          if (booking.wework) {
            booking["partnerStatus"] = booking.wework.orderStatus;
            booking["partnerOrderNumber"] = booking.wework.orderNumber;
          }
          if (booking.cowrks) {
            booking["partnerStatus"] = booking.cowrks.orderStatus;
            booking["partnerOrderNumber"] = booking.cowrks.orderNumber;
          }
          if (booking.innov8) {
            booking["partnerStatus"] = booking.innov8.orderStatus;
            booking["partnerOrderNumber"] = booking.innov8.orderNumber;
          }
          // if (booking.status.toLowerCase() == "cancelled" && booking["partnerStatus"].toLowerCase() != "cancelled") {
          //   booking["bookingStatusMatch"] = "booking-status-match";
          // } else {
          //   booking["bookingStatusMatch"] = "booking-status-mismatch";
          // }
          switch (booking.status.toLowerCase()) {
            case 'requested': { booking["bookingStatusMatch"] = "booking-status-none"; break;};
            case 'confirmed': { booking["bookingStatusMatch"] = "booking-status-none"; break;};
            case 'prepaid': { 
              if (!booking["partnerStatus"]) {
                console.log(booking);
              }
              if ((booking["partnerStatus"].toLowerCase() == "pending") || (booking["partnerStatus"].toLowerCase() == "completed")) 
                {
                   booking["bookingStatusMatch"] = "booking-status-match"; 
                } else { 
                  booking["bookingStatusMatch"] = "booking-status-mismatch"; 
                  console.log("mismatch",booking["partnerStatus"].toLowerCase());
                } 
              break;
            };
            case 'closed': { if ((booking["partnerStatus"].toLowerCase() == "pending") || (booking["partnerStatus"].toLowerCase() == "completed")) { booking["bookingStatusMatch"] = "booking-status-match"; } else { booking["bookingStatusMatch"] = "booking-status-mismatch"; } break;};
            case 'cancelled': { if (booking["partnerStatus"].toLowerCase() != "cancelled") { booking["bookingStatusMatch"] = "booking-status-mismatch"; } else { booking["bookingStatusMatch"] = "booking-status-match"; } break;};
          }
        } else {
          booking["bookingStatusMatch"] = "booking-status-none";
        }
        return booking;
      });
      this.filteredbookings = this.bookings;
      // Get the details of the user to see if the user is new user
      // await this.updateUserDetails();
      //   if (this.isLoaderBeingDisplayed) {
      //     this.isLoaderBeingDisplayed = false;
      //     this.loader.display(false);
      //  } 
      this.loadOrganizations(); // Load organizations after bookings are loaded
      this.sortBookings();
      this.filterBookings();
      // this.filterBookingsByStatus();
      // this.filterUserBookings();
      // this.filterByOrganization();
    });
  }

  async updateUserDetails() {
    const promiseList = [];
    this.filteredbookings.forEach(booking => {
      promiseList.push(this.loginService.getUserDetailsRealtime(booking.custId));
    });
    await Promise.all(promiseList).then((result) => {
      result.forEach((res, index) => {
        // console.log("User fetched", res);
        //If signup date is not there then assume it to the the origin date of GoFloaters
        if (!res) {
          console.log("booking.custId", this.filteredbookings[index]);
        }
        if (!res.signupDate) {
          res["signupDate"] = 1514748600000;
        }
        this.filteredbookings[index]["userSignupDate"] = res.signupDate;
        // this.filteredbookings[index]["referralCode"] = res.referralCode;
        let signupDate = moment(res.signupDate);
        var bookingDate = moment(this.filteredbookings[index]["reqDate"]);
        let dateDiff = bookingDate.diff(signupDate, 'days');
        this.filteredbookings[index]["daysToBooking"] = dateDiff;
        if (dateDiff < 5) {
          this.filteredbookings[index]["newUser"] = "newuser";
        } else {
          this.filteredbookings[index]["newUser"] = "olduser";
        }
      });
    })
  }
/*
* Redundant function
  getSpacesList() {
    this.loader.display(true);
    let spaces: any;
    return new Promise((resolve, reject) => {
      this.db.database.ref('spaces')
        .on('value', snapshot => {
          console.log("Fetching Spaces");
          if (snapshot.exists()) {
            spaces = snapshot.val();
            Object.keys(spaces).map(function (key, index) {
              spaces[key] = {
                spaceName: spaces[key].spaceName || '',
                originalName: spaces[key].originalName || '',
                gofloatersSpaceName: spaces[key].gofloatersSpaceName || '',
                spaceDisplayName: spaces[key].spaceDisplayName || '',
                managerName: spaces[key].managerName || '',
                managerPhone: spaces[key].managerphone || '',
                spaceSubType: spaces[key].spaceSubType ? spaces[key].spaceSubType.toString() : ''
              };
            });
            //  console.log(spaces);
            // console.log(Object.keys(spaces).length);
            this.sharedService.setSpaceCollection(spaces);
            resolve({ "msg": "Spaces Loaded" });
            this.loader.display(false);
          }
          else {
            reject({ "msg": "No Spaces Loaded" });
            this.loader.display(false);
          }
        });
    });
  }
  */

  /* Shyam 3rd Feb 2024 - Function not used
  getBookingsDetailsForRequestDate(requestDate) {
    this
      .loader
      .display(true);
    this
      ._bookingservice
      .getbookinglistForRequestDate({ requestDate: requestDate })
      .then((res: any) => {
        //this.filterdata();
        this
          .loader
          .display(false);
      });

  }
  */

  searchBookingsDetails(searchData) {

    this
      .loader
      .display(true);
    this
      ._bookingservice
      .searchbookinglist(searchData)
      .then((res: any) => {
        //Subscribe again  
        /* this.bookings = res;
        this.filteredbookings = res; */
        //Subscribe to bookings
        // if (!this.bookingsSubscribed) {
        //   this.getBookingsList();
        // }
        this
          .loader
          .display(false);
      });

  }

  getBookingsDetails() {
    this
      .loader
      .display(true);
    const data = {};
    this
      ._bookingservice
      .getbookinglist(data)
      .then((res: any) => {
        /* this.bookings = res;
        this.filteredbookings = res; */
        //this.filterdata();
        this
          .loader
          .display(false);
      });

  }
  changeSearchVal(searchby) {
    if (searchby !== 'reqbookingdate' && searchby !== 'reqDate' && searchby !== 'bookingdate' && searchby !== 'status') {
      this.searchvalue = "";
    }
  }
  searchBookings() {
    /*
    * Shyam 14 Dec 2022 >> Changes added to unsubscribe booking service everytime a search is initiated
    */
    // if (this.bookingSubscription) {
      
    //   this.bookingSubscription.unsubscribe();
    // }
    // console.log("bookingSubscription unsubscribed");
    // this.destroy$.next();
    // this.destroy$.complete();
    //Subscribe to the booking service
    this.getBookingsList();
    //Fetch bookings
    this.filterdata();
  }

  filterdata() {
    console.log("filterdata called");
    let searchData;
    //Reset the filters
    this.filterByStatus = "All";
    this.showBookingsOf = "Team@gofloaters.com";
    if (this.searchby === 'reqbookingdate' || this.searchby === 'reqDate' || this.searchby === 'bookingdate') {
      this.currdate.setHours(0, 0, 0, 0);
      let requestDateTimestamp = this.currdate.getTime();
      //let requestDateTimestamp = new Date(this.currdate).getTime();
      //Do GMT offset
      //requestDateTimestamp = requestDateTimestamp - 5.5*60*60*1000;
      searchData = {
        searchby: this.searchby,
        value: requestDateTimestamp
      }
    }
    else if (this.searchby === 'status') {
      searchData = {
        searchby: this.searchby,
        value: this.bookingStatus
      }
    }
    // else if (this.searchby == 'gofloatersSpaceName' || this.searchby == 'spaceDisplayName') {
    //   let spaceId;
    //   if (this.sharedService.isSpaceCollectionLoaded) {
    //     spaceId = this.sharedService.getSpaceIdForSpaceName(this.searchby, this.searchvalue);
    //     searchData = {
    //       searchby: "spaceId",
    //       value: spaceId
    //     }
    //   }
    //   else {
    //     this.sharedService.getSpacesList().then(val => {
    //       spaceId = this.sharedService.getSpaceIdForSpaceName(this.searchby, this.searchvalue);
    //       searchData = {
    //         searchby: "spaceId",
    //         value: spaceId
    //       }
    //     });
    //   }

    // }
    else {
      searchData = {
        searchby: this.searchby,
        value: this.searchvalue
      }
    }
    this.searchBookingsDetails(searchData);
  }
  // clearFilter() {
  //   this.filteredbookings = this.bookings;
  //   this.currdate = "";
  // }

  editBooking(bookingKey) {
    this.dialog.open(EditBookingComponent, {
      data: {
        bookingKey
      }
    }).afterClosed().subscribe(updateRequired => {
      // if (updateRequired) {
      // this.filterdata();
      // }
    });
  }

  changeStatus(bookingkey, status) {
    this.loader.display(true);
    const data = {
      id: bookingkey,
      status: status,
      disableNotifications: false
    };
    this._bookingservice.changestatus(data).then(
      (res: any) => {
        if (res.status === "success") {
          if (status == "Cancelled") {
            this.showCancelDialog = true;
          } else {
            this.showBookingConfirmDialog = true;
          }
          this.loader.display(false);
          // this.router.navigate(['/landingpage/bookings']);
        } else {
          this.showErrorDialog = true;
        }
      },
      err => {
        this.showErrorDialog = true;
      }
    );
  }

  initiateCallToManager(bookingkey) {
    this.loader.display(true);
    const data = {
      bookingId: bookingkey,
    };
    this._bookingservice.initiateCallToManager(data).then(
      (res: any) => {
        if (res.status === "success") {
          this.loader.display(false);
        } else {
          alert("Call to manager failed");
        }
        this.loader.display(false);
      },
      err => {
        alert("Call to manager failed");
        this.loader.display(false);
      }
    );
  }

  

  retriggerPartnerBooking(bookingkey) {
    this.loader.display(true);
    const data = {
      id: bookingkey,
    };
    this._bookingservice.retriggerPartnerBooking(data).then(
      (res: any) => {
        if (res.status === "success") {
          this.loader.display(false);
          // this.router.navigate(['/landingpage/bookings']);
        } else {
          this.showErrorDialog = true;
        }
      },
      err => {
        this.showErrorDialog = true;
      }
    );
  } 

  confirmBooking(bookingkey) {


  }

  reformatDate(dateStr) {
    var dArr = dateStr.split("-"); // ex input "2018-01-12"
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex out: "12/01/2018"
  }
  /*
  getTime(time) {
    return this
      .moment(time).tz('Asia/Kolkata')
      .format('h:mm a');
  }
  getdate(val) {
    return this
      .moment(val).tz('Asia/Kolkata')
      .format('DD/MM/YYYY');
  }*/
  getdatetime(val,timeZone) {
    return this
      .moment(val).tz(timeZone?timeZone:'Asia/Kolkata')
      .format('DD/MM/YYYY, h:mm a');
  }
  converttime(timestamp) {

    const date = new Date(timestamp * 1000);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = '0' + date.getMinutes();
    // Seconds part from the timestamp
    const seconds = '0' + date.getSeconds();
    // Will display time in 10:30:23 format
    const formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    return formattedTime;
  }

  loadOrganizations() {
    // Extract unique organization IDs from bookings
    const uniqueOrgIds = new Set<string>();
    this.bookings.forEach(booking => {
      if (booking.organizationId) {
        uniqueOrgIds.add(booking.organizationId);
      }
    });
    this.organizations = Array.from(uniqueOrgIds).sort();
  }
}
