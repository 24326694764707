import { Component, OnInit } from '@angular/core';
import { LoginService } from '../appservices/login.service';
import { BookingService } from '../appservices/booking.service';
import { SharedProvider } from '../appservices/shared';
import { FcmCloudMessagingService } from '../appservices/fcm-cloud-messaging.service';
import { Router } from '@angular/router';
import { LoaderService } from '../sharedcomponents/index';
import * as _ from 'underscore';
import * as moment from 'moment';
import { FoodCreditService } from '../appservices/foodcredit.service';

@Component({
  selector: 'app-foodredemption',
  templateUrl: './foodredemption.component.html',
  styleUrls: ['./foodredemption.component.scss']
})
export class FoodRedemptionComponent implements OnInit {

  public user: any;
  searchby : string = 'redeemedDate';
  Blankcontainer: boolean = true;
  //multipleSpaces : boolean = false;
  spaces : any = [];
  filteredRedemptions : any = [];  
  showerrorUnAuthDialog = false;
  public moment: any = moment;
  fromRequestDate : any = this
  .moment().tz('Asia/Kolkata')
  .format('YYYY-MM-DD');
  toRequestDate : any = this
  .moment().tz('Asia/Kolkata')
  .format('YYYY-MM-DD');
  searchvalue: any;
  spaceName: string;
  constructor(
    private router: Router,
    private loader: LoaderService,
    public loginService: LoginService,
    public sharedService: SharedProvider,
    public message: FcmCloudMessagingService,
    private _bookingservice: BookingService,
    private _foodcreditservice:FoodCreditService
  ) { }

  ngOnInit() {
    this.moment.tz.setDefault('Asia/Kolkata');
    this.user = this.loginService.getCurrentUser();
    if (this.user.uid !== undefined) {
      this.sharedService.isLoggedIn = true;
      this.getSpaceList();
     
    } else {
      this.loginNow();
    }
  }
  
  getSpaceList() {
    return new Promise((resolve, reject) => {
    this.loader.display(true);
    const spaceList = this.sharedService.getSpaceList();
    let fromDate = new Date(this.fromRequestDate).getTime();
    let toDate = new Date(this.toRequestDate).getTime();
    let searchData;
      //Do GMT offset
    fromDate = fromDate - 5.5*60*60*1000;
    toDate = toDate - 5.5*60*60*1000;
    searchData = {
      searchby : this.searchby,
      fromRedeemedDate : fromDate,
      toRedeemedDate : toDate
    }
    if (spaceList && spaceList.length > 0) {
      this.loader.display(false);
      this.Blankcontainer = false;    
      this.spaces = spaceList;       
      this.getRedemptionList(searchData); 
      resolve(spaceList);
    } else {
      this
        ._bookingservice
        .getspacelist(this.loginService.getCurrentUser().uid).then((res: any) => {
          if (res.length == 0) {
            this.Blankcontainer = true;
          } else {                 
            this.Blankcontainer = false;
            this.spaces = res;
          }
          this.loader.display(false);
          this.getRedemptionList(searchData); 
          resolve(res);
        }).catch((err) => {
          console.log(err);
          this.loader.display(false);
        })
    }    
  })
  
  
  }
  getRedemptionList(searchData) {
   
    return new Promise((resolve, reject) => {
    this.loader.display(true);    
    this._foodcreditservice.getFoodCreditTransactionList(searchData).then(res => {
      this.loader.display(false);
      let response = Object.keys(res);
      if (response.length > 0) {
        this.filteredRedemptions = _.sortBy(res, 'redeemedOn');         
        this.loader.display(false);
     }else {
      this.filteredRedemptions = [];
     }
      resolve(res);
     })
      .catch(err => {
        this.loader.display(false);
        if (err.status === 403) {
          this.showerrorUnAuthDialog = true;
        }
      });
    })
  }
  getDateTime(val) {
    return this.moment(val).format('DD/MM/YYYY, h:mm a');
  }

  filterdata() {
    let searchData;
    if(this.searchby === 'redeemedDate')
    {
      let fromDate = new Date(this.fromRequestDate).getTime();
      //Do GMT offset
      fromDate = fromDate - 5.5*60*60*1000;
      searchData = {
        searchby : this.searchby,
        fromRedeemedDate : fromDate,
	      toRedeemedDate : fromDate
      }
    }
    else if(this.searchby === 'redeemedDateRange') 
    {
      let fromDate = new Date(this.fromRequestDate).getTime();
      let toDate = new Date(this.toRequestDate).getTime();
      //Do GMT offset
      fromDate = fromDate - 5.5*60*60*1000;
      toDate = toDate - 5.5*60*60*1000;
      searchData = {
        searchby : this.searchby,
        fromRedeemedDate : fromDate,
	      toRedeemedDate : toDate
      }
    }
    else if(this.searchby === 'foodCreditId' || this.searchby === 'customerEmail' || this.searchby === 'spaceName' ){
      searchData = {
        searchby : this.searchby,
        searchvalue : this.searchvalue
      }
    }
    else {
      searchData = {
        searchby : this.searchby,
        searchvalue : this.spaceName
      }
    }
    this.getRedemptionList(searchData);
  }

  loginNow() {
    this.loader.display(false);
    this.router.navigate(['/login']);
  }
  
}

