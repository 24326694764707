export default [{
    key: 'street',
    displayName: 'Street',
    value: ''
}, {
    key: 'locality',
    displayName: 'Locality',
    value: ''
}, {
    key: 'city',
    displayName: 'City',
    value: ''
}, {
    key: 'country',
    displayName: 'Country',
    value: ''
}, {
    key: 'zipcode',
    displayName: 'Zip Code',
    value: ''
}, {
    key: 'landmark',
    displayName: 'Landmark',
    value: ''
},{
    key: 'googleMapsLink',
    displayName: 'Google Maps Link',
    value: ''
}];
