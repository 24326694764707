import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog, MatSnackBar } from '@angular/material';
// import { AddCommunitypartnersComponent } from '../add-communitypartners/add-communitypartners.component';
import { LoaderService } from '../sharedcomponents';
import * as Clipboard from 'clipboard/dist/clipboard.min.js';
import { AddSearchCollectionComponent } from '../add-searchcollection/add-searchcollection.component';

@Component({
  selector: 'app-searchcollection',
  templateUrl: './searchcollection.component.html',
  styleUrls: ['./searchcollection.component.scss']
})
export class SearchCollectionsComponent implements OnInit {
  communitypartners: any;
  partnerId: String = '';
  private data = []
  array = [];
  objArray: any;
  clipboard: any;
  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private loader: LoaderService,
    private snackBar: MatSnackBar
  ) {
    this.clipboard = new Clipboard('#cpyBtn');
  }

  ngOnInit() {
    this.loadData();
  }

  loadData(){
    this.db.database.ref('spaceSuggestions')
    .once("value", async snapshot => {
      this.data = snapshot.val();
      this.objArray = Object.keys(this.data)
    })
  }

  addSearchCollection() {
    this.dialog.open(AddSearchCollectionComponent, {
      data: {
        spaceSuggestionId: '',
        searchDescription:"",
        searchTitle:"",
        spaceIds:"",
        editMode: false,
      }
    }).afterClosed().subscribe(updateRequired => {
      if (updateRequired) {
        this.loadData();
      }
    })
  }

  editSearchCollection(key, value) {
    this.dialog.open(AddSearchCollectionComponent, {
      data: {
        editMode: true,
        spaceSuggestionId: key,
        searchTitle:value.searchTitle,
        searchDescription:value.searchDescription,
        spaceIds:value.spaceIds
      }
    }).afterClosed().subscribe(updateRequired => {
      if (updateRequired) {
        this.ngOnInit();
      }
    });
  }

  showcopytoast() {
    this.snackBar.open('Copied to Clipboard', 'Close', {
      duration: 1000,
    });
  }

}
