import { Component,  Inject,
  OnInit, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { LoaderService } from '../sharedcomponents';
import { BookingService } from '../appservices/booking.service';

@Component({
  selector: 'app-capture-razorpay-amount',
  templateUrl: './capture-razorpay-amount.component.html',
  styleUrls: ['./capture-razorpay-amount.component.scss']
})
export class CaptureRazorpayAmountComponent implements OnInit {
  showSuccessDialog: Boolean = false;
  showerrorDialog: Boolean = false;
  transactionData : any;
  constructor(
  @Inject(MAT_DIALOG_DATA) data ,
  private loader: LoaderService,
  private _bookingservice: BookingService,
  public dialogRef : MatDialogRef<CaptureRazorpayAmountComponent> 
  ) { 
    this.transactionData = data;
  }

  ngOnInit() {
  }
  CaptureAmount(){
      let Payload = this.transactionData;
      this.loader.display(true);
      this._bookingservice.captureRazorpayAmount({Payload}).then((data) => {
        this.loader.display(false);
        this.showSuccessDialog = true;
        this.showerrorDialog = false;
        this.dialogRef.close({data});
      }).catch(() => {
        this.loader.display(false);
        this.showerrorDialog = true;
        this.showSuccessDialog = false;
        this.dialogRef.close();
      });
    
  }
  close(){
  this.dialogRef.close();
  }

}
