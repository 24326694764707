import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog, MatSnackBar } from '@angular/material';
// import { AddCommunitypartnersComponent } from '../add-communitypartners/add-communitypartners.component';
import { LoaderService } from '../sharedcomponents';
import * as Clipboard from 'clipboard/dist/clipboard.min.js';
import { AddOrganizationsComponent } from '../add-organizations/add-organizations.component';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {
  communitypartners: any;
  partnerId: String = '';
  private data = []
  array = [];
  objArray: any;
  clipboard: any;
  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private loader: LoaderService,
    private snackBar: MatSnackBar
  ) {
    this.clipboard = new Clipboard('#cpyBtn');
  }

  ngOnInit() {
    this.db.database.ref('organizations')
      .once("value", async snapshot => {
        this.data = snapshot.val();
        this.objArray = Object.keys(this.data)
      })
  }

  addOrganization() {
    this.dialog.open(AddOrganizationsComponent, {
      data: {
        organizationId: '',
        organization: {
          name: '',
          adminEmailIds: '',
          logoURL: '',
          active:true,
          emailDomains:[]
        },
        editMode: false,
      }
    }).afterClosed().subscribe(updateRequired => {
      if (updateRequired) {
        this.ngOnInit();
      }
    })
  }

  editOrganization(key, value) {
    this.dialog.open(AddOrganizationsComponent, {
      data: {
        editMode: true,
        organizationId: key,
        organization: value,
      }
    }).afterClosed().subscribe(updateRequired => {
      if (updateRequired) {
        this.ngOnInit();
      }
    });
  }

  showcopytoast() {
    this.snackBar.open('Copied to Clipboard', 'Close', {
      duration: 1000,
    });
  }

}
