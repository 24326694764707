import {Injectable, EventEmitter} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import "rxjs/add/operator/map";
import {Observable} from "rxjs";
import {AngularFireAuth} from "@angular/fire/auth";
import {AngularFireDatabase} from '@angular/fire/database';

import { environment } from '../../environments/environment';
import { AppConstants } from "../app.constants";
@Injectable()
export class AuthenticationService {
  public user : any;
  // public userdetails : any;
  currentUser:any = {};
  public referralCodeDetails : any;
  public packageUpdateResponse : any;
  userLogged = new EventEmitter<any>();
  public accessToken : any;
  baseUrl : string;
  constructor(public http : HttpClient, public _ANGFIRE : AngularFireAuth, public db : AngularFireDatabase) {
    this.user = this._ANGFIRE.authState;
    this
      ._ANGFIRE
      .auth
      .useDeviceLanguage();
    // this.baseUrl = environment[environment.currentEnvironment].baseUrl;
    this.baseUrl = AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].appurl;
  }

  async loginWithEmailAndPassword(email : string, password : string) : Promise < any > {
    return new Promise((resolve, reject) => {
      this
        ._ANGFIRE
        .auth
        .signInWithEmailAndPassword(email, password)
        .then((val : any) => {
          this
            .getUserDetails({id: val.user.uid})
            .then(data => {
              this.currentUser = data;
              resolve(data);
            })
        })
        .catch((err : any) => {
          let message : any;
          if (err.code == "auth/network-request-failed") {
            message = 'Please connect to the internet';
          } else if (err.code == 'auth/invalid-email') {
            message = 'Please enter valid email';
          } else if (err.code == 'auth/user-not-found') {
            message = 'Please enter valid email and password';
          } else {
            message = 'Bad credentials';
          }
          reject(message);
        });
    });
  }

  getCurrentUser() {
    return this.currentUser;
}


  async createUserWithEmailAndPassword(email : string, password : string) : Promise < any > {
    return new Promise((resolve, reject) => {
      this
        ._ANGFIRE
        .auth
        .createUserWithEmailAndPassword(email, password)
        .then((val : any) => {
          resolve(val.user);
        })
        .catch((err : any) => {
          let message;
          if (err.code == "auth/network-request-failed") {
            message = 'Please connect to the internet';
          } else if (err.code == 'auth/invalid-email') {
            message = 'Please enter valid email';
          } else if (err.code == 'auth/user-not-found') {
            message = 'Please enter valid email and password';
          } else {
            message = 'Bad credentials';
          }
          reject(message);
        });
    });
  }
  async updateUserDetails(user) : Promise < any > {
    return new Promise((resolve, reject) => {
      return this
        .http
        .post(this.baseUrl + "/updateuserdetails", user)
        .subscribe((values:any) => {
          // this.userdetails = values
          resolve(values);
        }, err => {
          resolve({})
        })
    })
  }

  async getUserDetails(user) : Promise < any > {
    return new Promise((resolve, reject) => {
      return this
        .http
        .post(this.baseUrl + "/getuserdetails", user)
        .subscribe((values:any) => {
          // this.userdetails = values
          resolve(values);
        }, err => {
          resolve({})
        })
    })
  }

  async sendPasswordResetEmail(email : string) : Promise < any > {
    return new Promise((resolve, reject) => {
      this
        ._ANGFIRE
        .auth
        .sendPasswordResetEmail(email)
        .then((val : any) => {
          resolve(val);
        })
        .catch((err : any) => {
          let message : any;
          if (err.code == "auth/network-request-failed") {
            message = 'Please connect to the internet';
          } else if (err.code == 'auth/invalid-email') {
            message = 'This email address has not been used to sign up into GoFloaters';
          } else if (err.code == 'auth/user-not-found') {
            message = 'Please enter valid email';
          } else {
            message = 'Bad credentials';
          }
          reject(message);
        });
    });
  }

  logOut() : Promise < any > {
    return new Promise((resolve, reject) => {
      this
        ._ANGFIRE
        .auth
        .signOut()
        .then((data : any) => {
          this.userLogged.emit(false)
          resolve(data);
        })
        .catch((error : any) => {
          reject(error);
        });
    });
  }
}
