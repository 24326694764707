import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { BookingService } from '../appservices/booking.service';
import { GoogleMap } from '@agm/core/services/google-maps-types';
import LocationDetails from "../view-space-details/config/location-details-config";
import { MatDialog, MatSnackBar } from '@angular/material';
import { LoaderService } from '../sharedcomponents/index';
import { ViewSpaceDetailsComponent } from '../view-space-details/view-space-details.component';
import { SharedProvider } from '../appservices/shared';
@Component({
  selector: 'app-space-search',
  templateUrl: './space-search.component.html',
  styleUrls: ['./space-search.component.scss']
})
export class SpaceSearchComponent implements OnInit, AfterViewInit {
  static defaultCoordinates = { lat: 13.059415, lng: 80.2075807 };
  public tab: number=0;
  private selectedOption: String = 'all';
  private spaceList: any[] = [];
  private gridList: any[] = [];
  public filteredSpaceList: any[] = [];
  public gridSpaceList: any[] = [];
  public filteredGridSpaceList: any[] = [];
  public location: any = { lat: 13.059415, lng: 80.2075807 };
  private map: google.maps.Map;
  @ViewChild('legendWrapper', {static: false}) legendElement: ElementRef;
  public markers= [];
  private searchMarker: google.maps.Marker;
  private selectedSpace: any;
  public smartTablesettings: any;
  public filter:any = {};
  public amenities : any;
  public city = '';
  public filterOptions = [{
    name: 'All',
    key: 'all',
    icon: ''
  }, {
    name: 'Cafe',
    key: 'cafe',
    icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
  }, {
    name: 'Shared Office',
    key: 'sharedoffice',
    icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
  }, {
    name: 'Meeting Spaces',
    key: 'meetingrooms',
    icon: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png'
  }];
  public radius: any = 5;
  locationArray: any;
  searchType: string = "location";
  originalName: any;
  spaceId: string;
  gofloatersSpaceName: string;
  
  constructor(private _bookingService: BookingService, public loader: LoaderService, private dialog: MatDialog, private renderer: Renderer2,
    private snackBar: MatSnackBar, private sharedService : SharedProvider) {
    this.smartTablesettings = {
      attr: {
        class: 'table table-bordered'
      },
      noDataMessage:"No Space available",
      actions: {
        add:false,
        edit:false,
        delete:false,
        columnTitle: 'Copy',
        position:"right",
        custom:[ {
          title: '<i class="material-icons">file_copy</i>'
          } ]      
      },
      columns: {
        spaceName: {
          title: 'Space',
          type:'html',
          valuePrepareFunction: (spaceName) => {
            return '<a>'+spaceName+'</a>';
          }
        },
        address: {
          title: 'Locality',
          valuePrepareFunction: (address) => {
            return address.locality;
          },
          filterFunction(address?: any, search?: string): boolean {
            let match = true;
            Object.keys(address).map(u => address.locality).filter(it => {
              match = it.toLowerCase().includes(search);
            });
  
            if (match || search === '') {
              return true;
            } else {
              return false
            }
          },
          filter: true,
          compareFunction: (direction: any, a: any, b: any) => {
            let first = typeof a.locality === 'string' ? a.locality.toLowerCase(): a.locality;
            let second = typeof b.locality === 'string' ? b.locality.toLowerCase(): b.locality;

            if (first < second) {
              return -1 * direction;
            }
            if (first > second) {
              return direction;
            }
            return 0;
          }
        },
        priceperhr: {
          title: 'Price / Hour'
        },
        pricepermonth: {
          title: 'Price / Month'
        },
        priceperday: {
          title: 'Price / Day'
        },
        seatsAvailable: {
          title: 'Seats Available',
          filter: false,
        },
        bookingcount:{
          title: 'Booking Count',
          filter: false,
        },
        distance:{
          title: 'Distance',
          valuePrepareFunction: (distance) => {
            return parseFloat(distance).toFixed(2);
          },
          filter: false
        },
        operationTime: {
          title: 'Operation Time',
          filter: false,
        },
        originalName: {
          title: 'Original Space Name',
          filter: true,
        },
        gofloatersSpaceName: {
          title: 'GoFloaters Space Name',
          filter: true,
        },
        spaceDisplayName: {
          title: 'Space Display Name',
          filter: true,
        }
      }
    };
   }

  ngOnInit() {        
    this.amenities = this.sharedService.getAmenities();
    if(!this.amenities)
    {
      this.loader.display(true);
      this._bookingService.getMetaData().then(val => {       
        this.amenities = this.sharedService.getAmenities();
        this.loader.display(false);
      }).catch(err => {
        this.loader.display(false);
        console.log(err);
      });
    }
  }
  ngAfterViewInit() {
    //this.initMap();
  }
  onTabChanged(ev) {
    console.log(ev);
  }
  showcopytoast(event) {
    console.log("event.data",event.data);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    
    let textToCopy = "";
    textToCopy = event.data.gofloatersSpaceName + " - " + event.data.spaceDisplayName + "\n";
    textToCopy = textToCopy + event.data.originalName + "\n";
    textToCopy = textToCopy + "Location : " + event.data.address.locality + ", " + event.data.address.city  + "\n";
    textToCopy = textToCopy + event.data.address.landmark  + "\n";
    textToCopy = textToCopy + "\n";
    textToCopy = textToCopy + "Charges : " + "\n";
    if (event.data.hourPassAvailable ) {
      textToCopy = textToCopy +  "₹ " + event.data.priceperhr + "/hour + GST" + "\n";
    }
    if (event.data.dayPassAvailable) {
      textToCopy = textToCopy + "₹ " + event.data.priceperday + "/day + GST" + "\n";
    }
    // if (event.data.monthPassAvailable) {
    //   textToCopy = textToCopy + event.data.pricepermonth + "/month + GST" + "\n";
    // }
    textToCopy = textToCopy + "\n";

    textToCopy = textToCopy + "Amenities Included : " + "\n";
    // textToCopy = textToCopy + event.data.facilitiesList.toString() + "\n";
    let firstAmenity = true;
    event.data.facilitiesList.forEach(facility => {
      if (firstAmenity) {
        textToCopy = textToCopy + facility;
        firstAmenity = false;
      } else {
        textToCopy = textToCopy + ", " + facility;
      }
      
    });
    textToCopy = textToCopy + "\n\n";
    textToCopy = textToCopy + "Space Details & Booking Link :" + "\n" 
    //https://gofloaters.com/coworking-space/gooffice-2038-open-desks-malleswaram-bengaluru/
    //https://gofloaters.com/meeting-space/gospace-2027-7-seater-meeting-room-mahalakshmi-layout-bengaluru/
    //https://gofloaters.com/coworking-space/gooffice-2817-open-desks-koramangala-bengaluru/
    let spaceURL = "https://gofloaters.com/";
    if (event.data.spaceType == "Shared Workspace") {
      spaceURL = spaceURL + "coworking-space/";
    } else {
      spaceURL = spaceURL + "meeting-space/";
    }
    spaceURL = spaceURL + event.data.gofloatersSpaceName + " " + event.data.spaceDisplayName + " " + event.data.address.locality + " " +  event.data.city;
    spaceURL = spaceURL.replace(/\s+/g, '-').toLowerCase();
    textToCopy = textToCopy + spaceURL + "\n\n";
    // textToCopy = textToCopy + "From the above link you will be able to see photos, amenities, operation timings & reviews of this space. You will be able to book this space instantly by clicking the 'Book Now' button." + "\n" 

    selBox.value = textToCopy;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
		this.snackBar.open('Copied to Clipboard', 'Close', {
			duration: 1000,
		});
	}
  
  public initMap() {
    const that= this;
    setTimeout(()=>{
      let loc = SpaceSearchComponent.defaultCoordinates;
      if(that.location) {
        loc = that.location;
      }
      
      
      that.addMarkers();
      that.addLegend();
      
    }, 1000);
    
  }

  addLegend() {
    for (let index = 1; index < this.filterOptions.length; index++) {
      const element = this.filterOptions[index];
      const div = document.createElement('div');
      div.style.padding = '0.5rem';
      div.innerHTML = '<img style="width:32px" src="' + element.icon + '"> ' + element.name;
      this.renderer.appendChild(this.legendElement.nativeElement, div)
    }
    
  }

  recenterMap() {
    if (this.location) {
      this.map.setCenter(this.location);
    }
  }

  removeMarkers() {
    this.markers = [];
  }


  private getConfig (configurations: any[], res: any) {
		if (!res) {
			return [];
		}
		return configurations.map(config => ({
			displayName: config.displayName,
			value: res[config.key]
		}));
	}

  addMarkers() {
    this.markers = this.filteredSpaceList.map(space => {
      const location = space.location.split(',');
      let icon;
      if (this.checkCafe(space)) {
        icon = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
      } else if (this.checkSharedOffice(space)) {
        icon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
      } else if (this.checkMeetingSpace(space)) {
        icon = 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
      }
      const marker = {
        icon: icon,
        position: {
          lat: +location[0],
          lng: +location[1]
        },
        space: space
      }
      /* const marker = new google.maps.Marker({
        title: space.spaceName,
        icon,
        position: {
          lat: +location[0],
          lng: +location[1]
        },
        map: this.map
      });
      marker.addListener('click', () => {
        this.selectedSpace = space;
        this.selectedSpace.location_details = this.getConfig(LocationDetails, space.address);
        this.openSpaceModel();
      }); */
      return marker;
    });
  }
  selectMarker(space) {
      this.selectedSpace = space;
      this.selectedSpace.location_details = this.getConfig(LocationDetails, space.address);
      this.openSpaceModel();
  }
  openSpaceModel(event =null) {
    if(event) {
      this.selectedSpace = event.data;
      this.selectedSpace.location_details = this.getConfig(LocationDetails, this.selectedSpace.address);
    }
    this.dialog.open(ViewSpaceDetailsComponent, {
			data: {
				space: this.selectedSpace,
				modelMode: true
			}
		}).afterClosed().subscribe(updateRequired => {
			
		});
  }
  openGridSpaces(gridSpaces =null) {
    if(gridSpaces) {
      this.selectedSpace = gridSpaces;
      this.selectedSpace.location_details = this.getConfig(LocationDetails, this.selectedSpace.address);
    }
    this.dialog.open(ViewSpaceDetailsComponent, {
			data: {
				space: this.selectedSpace,
				modelMode: true
			}
		}).afterClosed().subscribe(updateRequired => {
			
		});
  }
  checkCafe(space) {
    return space.spaceType === 'Cafe' || space.spaceType === 'Restaurant';
  }

  checkSharedOffice(space) {
    if(this.filter.officeType)
    {
      return space.spaceType === 'Shared Workspace' && space.officeSpaceType === this.filter.officeType
    }
    else
      return space.spaceType === 'Shared Workspace';
  }

  checkMeetingSpace(space) {
    return space.spaceType === 'Conference Room' || space.spaceType === 'Training Room';
  }
  checkPrice(spacePrice, min, max) {
    if(min && max && max !== 0) {
      return spacePrice >= min &&  spacePrice <= max? true: false;
    } else if(min) {
      return spacePrice >= min;
    } if(max){
      return spacePrice <= max;
    }else {
      return true;
    }
  }
  clearFilter() {
    this.filter = {};
   this.filterSpaces();
  }
  filterSpaces() {

    if(this.filter.spaceType && this.filter.spaceType != 'all')
    {
      let params = {
        city : this.city,
        location : this.locationArray,
        radius : this.radius,
        filters : this.filter
      }
      this.loader.display(true);
      this._bookingService.filterSpaces(params).then((res:any) => {   
        this.filteredSpaceList = res;
        this.loader.display(false);
      }).catch(err => {
        this.loader.display(false);
        console.log(err);
      });
    }
    else
    {    
    this.filteredSpaceList = this.spaceList.filter(space => {
      let condition: boolean = true;
      if(this.filter.priceType) {
        switch(this.filter.priceType) {
          case 'hour': condition = this.checkPrice(space.priceperhr,this.filter.minPrice,this.filter.maxPrice);
                      break;
          case 'day': condition = this.checkPrice(space.priceperday,this.filter.minPrice,this.filter.maxPrice);
                      break;
          case 'month': condition = this.checkPrice(space.pricepermonth,this.filter.minPrice,this.filter.maxPrice);
                      break;
        }
      }
      if(!condition) {
        return false;
      }
      if(this.filter.spaceType) {
        switch(this.filter.spaceType) {
          case 'cafe' : condition = this.checkCafe(space);
                        break;
          case 'sharedoffice' : condition = this.checkSharedOffice(space);
                        break;
          case 'meetingrooms' : condition = this.checkMeetingSpace(space);
                        break;
          default: condition = true;
        }
      }
      if(!condition) {
        return false;
      }
      if(this.filter.minSeat) {
        condition = space.seatsAvailable >= this.filter.minSeat? true: false;
      }
      if(!condition) {
        return false;
      }
      return condition;
    });
    this.removeMarkers();
    this.addMarkers();
    this.addLegend();
  }
    /* if(this.map) {
      this.recenterMap();
      
    } */
  }


  async setAddress(place: any) {
    this.city = place.city;
    const { location } = place;
    this.locationArray = location;
    this.location = {
      lat: location[0],
      lng: location[1]
    };
  }
  async getSearchSpace() {
    try {
      this.loader.display(true);
      // if(!this.sharedService.isSpaceCollectionLoaded) {
      //   await this.sharedService.getSpacesList();
      // }
      let searchString = "";
      switch (this.searchType) {
        // case "gofloatersSpaceName":
        //   searchString = this.gofloatersSpaceName.toLowerCase();
        //   this.spaceList = this.sharedService.searchSpaceSpacesFromSpaceCollection(searchString,this.searchType);
        //   break;
        // case "originalName":
        //   searchString = this.originalName.toLowerCase();
        //   this.spaceList = this.sharedService.searchSpaceSpacesFromSpaceCollection(searchString,this.searchType);
        //   break;  
        // case "spaceId":
        //   searchString = this.spaceId.toLowerCase();
        //   this.spaceList = this.sharedService.searchSpaceSpacesFromSpaceCollection(searchString,this.searchType);
        //   break;                  
        default:
          const { spaces } = await this._bookingService.getNearestSpaces({
            location:this.locationArray,
            radius:this.radius
          });
          this.spaceList = spaces;
          this.filteredSpaceList = spaces;
          break;
      }
    } catch (e) {
      this.spaceList = [];
    }
    console.log("Fetched space list",this.spaceList);
    if(this.map) {
      this.filterSpaces();
      if (this.searchMarker) {
        this.searchMarker.setMap(null);
      }
      this.searchMarker = new google.maps.Marker({
        title: 'Search Point',
        position: this.location,
        map: this.map
      });
    } else {
      this.filterSpaces();
    }
    setTimeout(() => {
      this.loader.display(false);
    }, 3000);
  }
}
