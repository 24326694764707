import {AuthService} from '../auth.service';
import { AppConstants } from "../app.constants";
import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { NavItem } from '../nav-item';
import { NavService } from '../nav.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { SharedProvider } from '../appservices/shared';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingpageComponent implements OnInit {
  @ViewChild('drawer',{static: false}) appDrawer: ElementRef;
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  appVersion = AppConstants.CONSTANTS.currentVersion;
  navItems: NavItem[] = [
    {
      displayName: 'Bookings',
      iconName: 'ticket',
      route: '/landingpage/bookings',
    },
    {
      displayName: 'Spaces',
      iconName: 'building',
      children: [
        {
          displayName: 'Space List',
          route: '/landingpage/manage'
        },
        {
          displayName: 'Space Search',
          route: '/landingpage/searchspace'
        },
        {
          displayName: 'Add Space',
          route: 'landingpage/add-space'
        },
      ]
    },
    // {
    //   displayName: 'Food Redemptions',
    //   iconName: 'Food redemtions.png',
    //   route: '/landingpage/foodredemption',
    // },
    {
      displayName: 'Reports',
      iconName: 'table',
      children: [
        {
          displayName: 'Payment Report',
          route: '/landingpage/reportdashboard'
        },
        // {
        //   displayName: 'Food Redemption Report',
        //   route: '/landingpage/fooddashboard'
        // },
        // {
        //   displayName: 'Food Credits Purchase Report',
        //   route: '/landingpage/foodcreditspurchasereport'
        // },
        {
          displayName: 'Booking List',
          route: '/landingpage/dashboard'
        },
        {
          displayName: 'Org Bookings',
          route: '/landingpage/orgbookings'
        },
        {
          displayName: 'Enquiries',
          route: '/landingpage/enquiries'
        },
        {
          displayName: 'User List',
          route: '/landingpage/userdashboard'
        },
        {
          displayName: 'Voucher List',
          route: '/landingpage/voucherdashboard'
        },
        // {
        //   displayName: 'Floater Pass Report',
        //   route: '/landingpage/floaterpassreport'
        // },
        {
          displayName: 'Referral Report',
          route: '/landingpage/referralreport'
        }
      ]
    },
    {
      displayName: 'Tools',
      iconName: 'gear',
      children: [
        {
          displayName: 'Send Notifications',
          route: '/landingpage/notifications'
        },
        {
          displayName: 'Create Payment Link',
          route: '/landingpage/paymentlink'
        },
        {
          displayName: 'Generate Bookings',
          route: '/landingpage/generate-bookings'
        },
        {
          displayName: 'Space Bulk Update',
          route: '/landingpage/spacebulkupdate'
        },
        {
          displayName: 'Partner Invoices',
          route: '/landingpage/partnerinvoices'
        },
        // {
        //   displayName: 'Space Collections',
        //   route: '/landingpage/promotion-details'
        // },
        // {
        //   displayName: 'Community Partners',
        //   route: '/landingpage/communitypartners'
        // },
        {
          displayName: 'Organizations',
          route: '/landingpage/organizations'
        },
        {
          displayName: 'Space Suggestions',
          route: '/landingpage/searchcollections'
        },
        {
          displayName: 'Payment Transactions',
          route: '/landingpage/paymenttransactions'
        },
        {
          displayName: 'Manage User',
          route: '/landingpage/manage-user'
        },
         {
          displayName: 'FAQ',
          route: '/landingpage/faq'
        },
      ]
    },
    {
      displayName: 'Help',
      iconName: 'info',
      route: '/landingpage/help',
    },
  ];
  header: string;
  constructor( public logoutservice:AuthService, public navService: NavService,
    private breakpointObserver: BreakpointObserver, private router: Router,
    private sharedService : SharedProvider) { 
   
  }

  ngOnInit() {
    let that =this;
    this.sharedService.bookingTitle.subscribe((text : string) => {
      if(window.location.pathname.indexOf('viewbooking') > 0)
      {
        that.header = text;
      }      
    });
    this.sharedService.spaceTitle.subscribe((text : string) => {
      if(window.location.pathname.indexOf('viewspace') > 0)
      {
        that.header = text;
      }
    });
    this.navService.currentUrl.subscribe((url: string) => {
      this.navItems.forEach(item => {
        if(item.children) {
          item.children.forEach(child => {
            if(child.route !== undefined && url.indexOf(`${child.route}`) === 0) {
              that.header = child.displayName;
            }
          })
        }
        if(item.route !== undefined && url.indexOf(`${item.route}`) === 0) {
          that.header = item.displayName;
        }
      })
        
        
    });
  }
  logout(){
    this.logoutservice.signOut()
  }
}
