import { Injectable } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import * as firebase from 'firebase';
import {HttpClient} from "@angular/common/http";
import { SharedProvider } from './appservices/shared';
import { AppConstants } from './app.constants';
import { LoginService } from './appservices/login.service';
@Injectable()
export class AuthService {

   authState: any = null;
   baseUrl: any;
  constructor(private afAuth: AngularFireAuth, private router: Router, public http : HttpClient,   private sharedService : SharedProvider,   private login: LoginService,
    private loginService: LoginService) {
    this.baseUrl= AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
    this.afAuth.authState.subscribe((auth) => {
      this.authState = auth;
    });

  }

  get isUserAnonymousLoggedIn(): boolean {
    return (this.authState !== null) ? this.authState.isAnonymous : false;
  }

  get currentUserId(): string {
    return (this.authState !== null) ? this.authState.uid : '';
  }

  get currentUserName(): string {
    return this.authState['email'];

  }

  get currentUser(): any {
    return (this.authState !== null) ? this.authState : null;
    }

  get isUserEmailLoggedIn(): boolean {
    if ((this.authState !== null) && (!this.isUserAnonymousLoggedIn)) {
      return true
    } else {
      return false
    }
  }

   signUpWithEmail(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this
        .afAuth
        .auth
        .createUserWithEmailAndPassword(email, password)
      .then((val) => {
        let user = val.user;
        this.authState = user;
        this.updateUser(user.uid,user).then(val => {
           resolve(user);
        })
        .catch(err => {
          reject(err);
        })
        
      

      })
      .catch(error =>{
        let message;
        if (error.code == "auth/network-request-failed") {
          message = 'Please connect to the internet';
        } else if (error.code == 'auth/invalid-email') {
          message = 'Please enter valid email';
        } else if (error.code == 'auth/user-not-found') {
          message = 'Please enter valid email and password';
        } else {
          message = 'Bad credentials';
        }
        reject(message);
      });
  });
}

  async updateUser(uid : string,user: any) {
    let signupDate = new Date();
    let signupSource = "Admin App";
    //Updatetype is set as welcomed as we dont want to send welcome email to the space owner user
    //Location is hard coded as Chennai as of now. This has to be changed
    const userObj={
      uid:uid,
      displayName:user.displayName,
      updatetype:'welcomed',
      signupDate:signupDate.getTime(),
      signupSource:signupSource,
      email:user.email,
      isSpaceOwner:true,
      phoneNumber:user.phoneNumber,
      location:{city:"Chennai",country:"India"}
    }
    return new Promise((resolve, reject) => {
      return this
        .http
        .post(this.baseUrl + "/updateuserdetails", userObj)
        .subscribe((values:any) => {
          // this.userdetails = values
          this.sharedService.isLoggedIn = true;
          this.login.setCurrentUser(userObj);
          resolve(values);
        }, err => {
          resolve({})
        })
    })
  }
  loginWithEmail(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((val) => {
        let user = val.user;
        this.authState = user;
        if (user) {
          firebase.auth().currentUser.getIdToken().then(function(idToken) {
            window.localStorage.setItem('floaterstoken', idToken);
          }).catch(function(error) {
          });
        }
      })
      .catch(error => {
        throw error;
      });
  }

  signOut(): void {
    this.afAuth.auth.signOut();
    this.router.navigate(['/login']);
  }
  // updateProfile(): void {
  //      this.authState.auth.updateProfile({
  //        displayName: '',
  //        phoneNumber: ''
  //      });
  // }
}
