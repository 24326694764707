import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AngularFireDatabase } from '@angular/fire/database';
import { LoaderService } from '../sharedcomponents';

@Component({
  selector: 'app-add-communitypartners',
  templateUrl: './add-communitypartners.component.html',
  styleUrls: ['./add-communitypartners.component.scss']
})
export class AddCommunitypartnersComponent implements OnInit {
  editMode: Boolean = false;
  partnerId: String = "";
  partnerName: String = "";
  partnerDescription: String = "";
  partnerLogoUrl: String = "";
  offerSummary: String = "";
  offerDetailUrl: String = "";
  priority : Number = 0;
  private partner;
  availablePartner: any[] = [];
  public errorMessage: String = '';
  public showSuccessDialog: Boolean = false;
  public showerrorDialog: Boolean = false;
  public showSuccessDialog1: Boolean = false;
  constructor(
    private db: AngularFireDatabase,
    private loader: LoaderService,
    private dialogRef : MatDialogRef<AddCommunitypartnersComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    if (data) {
      this.editMode = data.editMode;
      this.partnerId = data.partnerId;
      const communitypartners = data.communitypartners;
      this.partnerName = communitypartners.partnerName;
      this.partnerDescription = communitypartners.partnerDescription;
      this.partnerLogoUrl = communitypartners.partnerLogoUrl;
      this.offerSummary = communitypartners.offerSummary;
      this.offerDetailUrl = communitypartners.offerDetailUrl;
      this.availablePartner = communitypartners.availablePartner || [];
      this.priority = communitypartners.priority ? communitypartners.priority : 9999;
    }
  }
  ngOnInit() {
  }

  addCommunitypartners() {
    const {partnerId} = this;
    const ref = this.db.database.ref('communitypartners/' + partnerId);
    ref.once("value", snapshot => {
     this.partner = snapshot.val();
    if(!this.partner){
      this.loader.display(true)
    const data = {
      dateAdded : `${new Date().getTime()}`,
      partnerName: this.partnerName,
      partnerDescription: this.partnerDescription,
      partnerLogoUrl: this.partnerLogoUrl,
      offerSummary: this.offerSummary,
      offerDetailUrl: this.offerDetailUrl,
      priority : this.priority
    }
    const newPartners = this.db.database.ref('communitypartners/' + partnerId);
    newPartners.set(data).then(() => {
      this.loader.display(false);
      this.errorMessage = '';
      this.showSuccessDialog = true;
      this.showSuccessDialog1 = false;
      this.showerrorDialog = false;
    }).catch(() => {
      this.loader.display(false);
      this.errorMessage = 'Server error!...';
      this.showSuccessDialog = false;
      this.showSuccessDialog1 = false;
      this.showerrorDialog = true;
    });
    }
    else{
      this.errorMessage = 'Partner Id is already exists';
        this.showSuccessDialog = false;
      this.showSuccessDialog1 = false;
        this.showerrorDialog = true;
    }
    })
  }

  editCommunitypartners() {
    this.loader.display(true);
    const Updates = this.db.database.ref('communitypartners/' + this.partnerId);
    // Updates.once("value", snapshot => {
      // var updates = {};
      // snapshot.forEach(childSnapShot => {
      //   var comment = childSnapShot.val();
      //   const data = {
      //     partnerName: this.partnerName,
      //     partnerDescription: this.partnerDescription,
      //     partnerLogoUrl: this.partnerLogoUrl,
      //     offerSummary: this.offerSummary,
      //     offerDetailUrl: this.offerDetailUrl,
      //     priority : this.priority
      //   }
      //   updates = data;
      // })
      const updatedData = {
        partnerName: this.partnerName,
        partnerDescription: this.partnerDescription,
        partnerLogoUrl: this.partnerLogoUrl,
        offerSummary: this.offerSummary,
        offerDetailUrl: this.offerDetailUrl,
        priority : this.priority
      }
      // updates = data;
      Updates.update(updatedData).then(() => {
        console.log("Partner updated");
        this.loader.display(false);
        this.errorMessage = '';
        this.showSuccessDialog = false;
      this.showSuccessDialog1 = true;
        this.showerrorDialog = false;
      }).catch(() => {
        this.loader.display(false);
        this.errorMessage = 'Server error!...';
        this.showSuccessDialog = false;
      this.showSuccessDialog1 = false;
        this.showerrorDialog = true;
      });
    // })
  }

  success() {
		this.dialogRef.close(true);
	}

	error() {
		this.dialogRef.close(false);
  }
  
}
