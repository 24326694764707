import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AddCommunitypartnersComponent } from '../add-communitypartners/add-communitypartners.component';
import { LoaderService } from '../sharedcomponents';
import * as Clipboard from 'clipboard/dist/clipboard.min.js';

@Component({
  selector: 'app-communityparnters',
  templateUrl: './communityparnters.component.html',
  styleUrls: ['./communityparnters.component.scss']
})
export class CommunityparntersComponent implements OnInit {
  communitypartners: any;
  partnerId: String = '';
  private data = []
  array = [];
  objArray: any;
  clipboard: any;
  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private loader: LoaderService,
    private snackBar: MatSnackBar
  ) {
    this.clipboard = new Clipboard('#cpyBtn');
  }

  ngOnInit() {
    this.db.database.ref('communitypartners')
      .once("value", async snapshot => {
        this.data = snapshot.val();
        this.objArray = Object.keys(this.data)
      })
  }

  addCommunityPartner() {
    this.dialog.open(AddCommunitypartnersComponent, {
      data: {
        partnerId: '',
        communitypartners: {
          partnerName: '',
          partnerDescription: '',
          partnerLogoUrl: '',
          priority:9999
        },
        editMode: false,
      }
    }).afterClosed().subscribe(updateRequired => {
      if (updateRequired) {
        this.ngOnInit();
      }
    })
  }

  editCommunitypartners(key, value) {
    this.dialog.open(AddCommunitypartnersComponent, {
      data: {
        editMode: true,
        partnerId: key,
        communitypartners: value,
      }
    }).afterClosed().subscribe(updateRequired => {
      if (updateRequired) {
        this.ngOnInit();
      }
    });
  }

  showcopytoast() {
    this.snackBar.open('Copied to Clipboard', 'Close', {
      duration: 1000,
    });
  }

}
