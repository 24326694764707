import { Component, OnInit, Inject } from '@angular/core';
import { BookingService } from '../appservices/booking.service';
import { LoaderService } from '../sharedcomponents/index';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MatSnackBar } from '@angular/material';
import * as Clipboard from 'clipboard/dist/clipboard.min.js';

@Component({
	selector: 'app-add-voucher',
	templateUrl: './add-voucher.component.html',
	styleUrls: ['./add-voucher.component.scss']
})
export class AddVoucherComponent implements OnInit {
	editVoucherData: any = {};
	editMode: Boolean = false;
	private ExpiresOn = new Date();
	private purpose: String = '';
	private floatingpoints: any;
	private count: Number = 5;
	private version: String = 'V1';
	private versionOption: String = 'FP';
	private floatingPointsPercent = 50;
	private foodCreditsPercent = 50;
	private showSuccessDialog: Boolean = false;
	private showerrorDialog: Boolean = false;
	private createdVoucherLists: String[] = [];
	private clipboard: any;
	private voucherType : any;
	private totalPoints : any;
	constructor(
		private _bookingService: BookingService,
		private loader: LoaderService,
		private dialogRef: MatDialogRef<AddVoucherComponent>,
		private snackBar: MatSnackBar,
		@Inject(MAT_DIALOG_DATA) private data
	) {
		if (data) {
			this.editMode = data.editMode;
			this.editVoucherData = data.voucher;
			this.voucherType = this.editVoucherData.voucherCode.substring(0, 2);
		}
		this.clipboard = new Clipboard('#cpyBtn');
	}

	ngOnInit() {
	}

	showcopytoast() {
		this.snackBar.open('Copied to Clipboard', 'Close', {
			duration: 1000,
		});
	}

	editVoucher() {
		this.loader.display(true);
		this._bookingService.editVoucher({
			...this.editVoucherData,
			user: null,
			ExpiresOn: this.getTimeStampWithoutMS(this.editVoucherData.ExpiresOn.getTime() + 24 * 60 * 60 * 1000 - 1)
		}).then(() => {
			this.loader.display(false);
			this.showSuccessDialog = true;
			this.showerrorDialog = false;
		}).catch(() => {
			this.loader.display(false);
			this.showSuccessDialog = false;
			this.showerrorDialog = true;
		});
	}

	getTimeStampWithoutMS(timestamp) {
		return Math.floor(timestamp / 1000);
	}

	addVoucher() {
		const { ExpiresOn, purpose, totalPoints, count } = this;
		let floatingpoints;
		const ExpiresOnWithoutMS = this.getTimeStampWithoutMS(ExpiresOn.getTime() + 24 * 60 * 60 * 1000 - 1);
		this.loader.display(true);
		let newVersionObject: any = {};
		if(this.version === 'V1'){
			floatingpoints = totalPoints
		}else if (this.version === 'V2') {
			newVersionObject = {
				totalPoints: totalPoints,
				floatingpoints: null,
				voucherKey: this.versionOption
			};
			switch (this.versionOption) {
				case 'FP':
					newVersionObject.floatingPointsPercent = 1;
					newVersionObject.foodCreditsPercent = 0;
					break;
				case 'FC':
					newVersionObject.floatingPointsPercent = 0;
					newVersionObject.foodCreditsPercent = 1;
					break;
				case 'PC':
					newVersionObject.floatingPointsPercent = (this.floatingPointsPercent / 100);
					newVersionObject.foodCreditsPercent = (this.foodCreditsPercent / 100);
					break;
				case 'PXC':
					newVersionObject.floatingPointsPercent = 1;
					newVersionObject.foodCreditsPercent = 1;
					break;
			}
		}
		this._bookingService.addVoucher({
			ExpiresOn: ExpiresOnWithoutMS,
			purpose,
			floatingpoints,
			count,
			creationDate: this.getTimeStampWithoutMS(new Date().getTime()),
			...newVersionObject
		}).then(voucherCodes => {
			this.createdVoucherLists = voucherCodes || [];
			this.loader.display(false);
			this.showSuccessDialog = true;
			this.showerrorDialog = false;
		}).catch(error => {
			console.log("error : " , error);
			this.createdVoucherLists = [];
			this.loader.display(false);
			this.showSuccessDialog = false;
			this.showerrorDialog = true;
		});
	}

	success() {
		this.dialogRef.close(true);
	}

	error() {
		this.dialogRef.close(false);
	}

}
