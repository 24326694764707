import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { LoaderService } from '../sharedcomponents/index';
import { LoginService } from '../appservices/login.service';
import { AuthenticationService } from '../appservices/authentication.service';
import { SharedProvider } from '../appservices/shared';
import { AppConstants } from "../app.constants";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  appVersion = AppConstants.CONSTANTS.currentVersion;
  email = '';
  password = '';

  error: { name: string, message: string } = { name: '', message: '' };

  ngOnInit() { }


  constructor(public authService: AuthService, private router: Router, private loader: LoaderService,public authService1: AuthenticationService, public logicService: LoginService,
    public sharedService: SharedProvider) { }

  // onLoginEmail(): void {
  //   this.loader.display(true);
  //  // if (this.validateForm(this.email, this.password)) {
  //     this.authService.loginWithEmail(this.email, this.password)
  //       .then(() => {
  //        this.router.navigate(['/landingpage']);
  //         this.loader.display(false);
  //       }).catch(_error => {
  //         this.error = _error;
  //         this.router.navigate(['/login']);
  //         this.loader.display(false);
  //       });
  //   }
  // }
  // onLoginEmail1(): void {
  //   this.loader.display(true);
  //   this.logicService.login(this.email, this.password)
  //     .subscribe(res => {
  //       window.localStorage.setItem('floaterstoken', res['access_token']);
  //       this.router.navigate(['/landingpage']);
  //       this.loader.display(false);
  //     }, err => {
  //       if (err.status === 403) {
  //         this.error.message = 'You are not allowed';
  //       } else {
  //         this.error.message = 'Login failed';
  //       }
  //       this.loader.display(false);
  //     });
  // }

  onLoginWithGoogle (): void {
    this
    .loader
    .display(true);
    this
    .logicService
    .loginWithGmail()
    .then((user : any) => {
      this.sharedService.isLoggedIn = true;
      // If the user is an admin 
      if (this.logicService.currentUser.isAdmin) {
      this
      .router
      .navigate(['/landingpage']);
    } else {
      alert("You don't have access to this application.");
      //Logout the user
      this.authService.signOut();
    }
      this
      .loader
      .display(false);
    })
    .catch(error => {
      this
      .loader
      .display(false);
    });

  }

  onLoginEmail() : void {
    this
    .loader
    .display(true);

    const that = this;
    if (this.email && this.password ) {
      this
        .logicService
        .loginWithEmailAndPassword(this.email.trim(), this.password)
        .then((user : any) => {
          this.sharedService.isLoggedIn = true;
          // If the user is an admin 
          if (this.logicService.currentUser.isAdmin) {
          this
          .router
          .navigate(['/landingpage']);
        } else {
          alert("You don't have access to this application.");
          //Logout the user
          this.authService.signOut();
        }
          this
          .loader
          .display(false);
        })
        .catch(error => {
          this
          .loader
          .display(false);
        });
    } else {
      this
      .loader
      .display(false);
    }
  }
}


