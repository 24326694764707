/// <reference types="@types/googlemaps" />
import {
	Component,
	OnInit,
	Input,
	ElementRef,
	NgZone,
	ViewChild,
	Inject
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AddspaceService } from "../appservices/addspace.service";
import { LoaderService } from "../sharedcomponents/index";
import { BookingService } from "../appservices/booking.service";
import { LoginService } from "../appservices/login.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import * as $ from "jquery";
import * as moment from "moment-timezone";
import { MapsAPILoader } from "@agm/core";
import * as firebase from "firebase";
import * as _ from "lodash";
import { ImageCroppedEvent } from 'ngx-image-cropper';

import {
	AngularFireStorage
} from "@angular/fire/storage";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { DragulaService } from "ng2-dragula";
import { AppConstants } from "../app.constants";
import { SharedProvider } from "../appservices/shared";
import { AngularFireDatabase } from "@angular/fire/database";
// import { Ptor } from "protractor";
@Component({
	selector: "app-add-space",
	templateUrl: "./add-space.component.html",
	styleUrls: ["./add-space.component.scss"]
})
export class AddSpaceComponent implements OnInit {
	@Input() adressType: string;
	@ViewChild("addresstext", { static: false }) addresstext: any;

	id: any;
	errorMessage: String;
	showDialog = false;
	showSuccessDialog = false;
	showerrorDialog = false;
	showerrorDialogdaypass = false;
	showerrorDialogRatePerMonth = false;
	showerrordbDialog = false;
	showSuccessImageDelete = false;
	showFailureImageDelete = false;
	showMandatoryFields = false;
	mandatoryFieldsErrorMessage = '';
	selectedpurposes: any;
	purposesList: any = [];
	uploadPercentageList: any[] = [];
	space: any = { address: {}, location: [], photos: [] };
	locationval: any = {};
	location: any;
	otherfacilitiesAvailable: any;
	disableGofloatersSpaceName: boolean = false;
	dayPassDiscountApplicable:boolean = false;
	hourPassDiscountApplicable:boolean = false;
	discountValidTillDate: any;
	discountedPriceperday:number = 0;
	discountedPriceperhr:number = 0;
	public latitude: number;
	public longitude: number;
	public moment: any = moment;
	@ViewChild("search", { static: false })
	public searchElementRef: ElementRef;
	public cityInfo: any = [];
	public isSuperUser:boolean = false;
	private days = [
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
		"Sunday"
	];
	private spaceSuperUsers = AppConstants.CONSTANTS.spaceSuperUsers;
	public operationTimings = {
		displayName: "",
		items: [
			{
				title: "Week Days",
				from: "08:00",
				to: "18:00",
				useNow: true,
				holiday: false,
				useAll: true,
				name: "All days",
				timing: [
					{
						from: "08:00",
						to: "18:00",
						useNow: true,
						holiday: false,
						name: "Monday"
					},
					{
						from: "08:00",
						to: "18:00",
						useNow: true,
						holiday: false,
						name: "Tuesday"
					},
					{
						from: "08:00",
						to: "18:00",
						useNow: true,
						holiday: false,
						name: "Wednesday"
					},
					{
						from: "08:00",
						to: "18:00",
						useNow: true,
						holiday: false,
						name: "Thursday"
					},
					{
						from: "08:00",
						to: "18:00",
						holiday: false,
						useNow: true,
						name: "Friday"
					}
				]
			},
			{
				title: "Week End Days",
				from: "08:00",
				to: "18:00",
				useNow: true,
				holiday: false,
				name: "All days",
				useAll: true,
				timing: [
					{
						from: "08:00",
						to: "18:00",
						useNow: true,
						holiday: false,
						name: "Saturday"
					},
					{
						from: "08:00",
						to: "18:00",
						useNow: true,
						holiday: false,
						name: "Sunday"
					}
				]
			}
		]
	};
	bestSuitedFor: any = [];
	bestSuitedForList: any = [];
	bestSuitedList: any = [
		{
			type: ["Cafe", "Restaurant"],
			bestSuited: [
				"Cracking away on your laptop",
				"Working with your team",
				"Focussed, formal discussions",
				"Casual discussions with team and friends",
				"Switching on your zen mode",
				"Switching on your Creative mode"
			]
		},
		{
			type: ["Training Room", "Conference Room", "Event Space", "Banquet Halls", "Work Pod"],
			bestSuited: [
				"One to One Meetings",
				"Team Meetings",
				"Board Meetings & Presentations",
				"Interviews",
				"Workshops & Events",
				"Training",
				"Work Pod"
			]
		},
		{
			type: ["Shared Workspace"],
			bestSuited: []
		}
	];
	// spaceOwnerId: any;

	allPercentage: Observable<number>[] = [];
	// Progress monitoring
	percentage: Observable<number>;

	snapshot: Observable<any>;

	// Download URL
	downloadURL: Observable<string>;

	// State for dropzone CSS toggling
	isHovering: boolean;

	// =====================================//
	downloadURLs: any = [];
	uploadPercent: any;
	storageUrl: any;
	fileStoragePath: any;
	alltaskList: any[] = [];
	facilitiesAvailable: any[] = [];
	editMode = false;
	facilities_arr: any = [];
	showOldFacilities: Boolean = false;
	showOldPurposes: Boolean = false;
	newFacilities_arr: any = [];
	facilitiesList: any = [];
	commonFacilities: any = [
		{
			name: "AC",
			value: "AC",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Hi Speed WiFi",
			value: "Hi Speed WiFi",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Power Backup",
			value: "Power Backup",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "WiFi Backup",
			value: "WiFi Backup",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Metro Nearby",
			value: "Metro Nearby",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Smoking Zone",
			value: "Smoking Zone",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Rest Rooms",
			value: "Rest Rooms",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "No Onsite Rest Rooms",
			value: "No Onsite Rest Rooms",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Free Parking",
			value: "Free Parking",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Paid Parking",
			value: "Paid Parking",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Disinfected Daily",
			value: "Disinfected Daily",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Hand Sanitizers",
			value: "Hand Sanitizers",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Distance >3 Feet",
			value: "Distance >3 Feet",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Face Masks Available",
			value: "Face Masks Available",
			spaceType: ["all"],
			Ischecked: false
		},
		{
			name: "Network Firewall",
			value: "Network Firewall",
			spaceType: ["all"],
			Ischecked: false
		},
	];
	specificFacilities: any = [
		{
			name: "Power Points",
			value: "Power Points",
			spaceType: ["Cafe", "Restaurant"],
			Ischecked: false
		},
		{
			name: "Outdoor Seating",
			value: "Outdoor Seating",
			spaceType: ["Cafe", "Restaurant"],
			Ischecked: false
		},
		{
			name: "Pet Friendly",
			value: "Pet Friendly",
			spaceType: ["Cafe", "Restaurant"],
			Ischecked: false
		},
		{
			name: "Printer on Request",
			value: "Printer on Request",
			spaceType: ["Cafe", "Restaurant"],
			Ischecked: false
		},
		{
			name: "Discount Offers",
			value: "Discount Offers",
			spaceType: ["Cafe", "Restaurant"],
			Ischecked: false
		},
		{
			name: "Printer",
			value: "Printer",
			spaceType: ["Training Room", "Conference Room", "Shared Workspace"],
			Ischecked: false
		},
		{
			name: "Copier",
			value: "Copier",
			spaceType: ["Training Room", "Conference Room", "Shared Workspace"],
			Ischecked: false
		},
		{
			name: "Pantry",
			value: "Pantry",
			spaceType: ["Training Room", "Conference Room", "Shared Workspace"],
			Ischecked: false
		},{
			name: "Food Court",
			value: "Food Court",
			spaceType: ["Training Room", "Conference Room", "Shared Workspace"],
			Ischecked: false
		},{
			name: "Lounge Area",
			value: "Lounge Area",
			spaceType: ["Training Room", "Conference Room", "Shared Workspace"],
			Ischecked: false
		},{
			name: "Recreation & Games Area",
			value: "Recreation & Games Area",
			spaceType: ["Training Room", "Conference Room", "Shared Workspace"],
			Ischecked: false
		},
		{
			name: "Projector",
			value: "Projector",
			spaceType: ["Conference Room", "Training Room"],
			Ischecked: false
		},
		{
			name: "Video Conference",
			value: "Video Conference",
			spaceType: ["Conference Room", "Training Room"],
			Ischecked: false
		},
		{
			name: "TV",
			value: "Television",
			spaceType: ["Conference Room", "Training Room"],
			Ischecked: false
		},
		{
			name: "Whiteboard",
			value: "Whiteboard",
			spaceType: ["Conference Room", "Training Room"],
			Ischecked: false
		},
		{
			name: "Audio Equipment",
			value: "Audio Equipment",
			spaceType: ["Conference Room", "Training Room"],
			Ischecked: false
		},
		{
			name: "Phone Booth",
			value: "Phone Booth",
			spaceType: ["Shared Workspace"],
			Ischecked: false
		},
		{
			name: "Discussion Booth",
			value: "Discussion Booth",
			spaceType: ["Shared Workspace"],
			Ischecked: false
		},
		{
			name: "Meeting Rooms",
			value: "Meeting Rooms",
			spaceType: ["Shared Workspace"],
			Ischecked: false
		},
		{
			name: "Office Stationary",
			value: "Office Stationery",
			spaceType: ["Training Room", "Conference Room", "Shared Workspace"],
			Ischecked: false
		},
		{
			name: "Complimentary Coffee/Tea",
			value: "Coffee/Tea",
			spaceType: ["Training Room", "Conference Room", "Shared Workspace"],
			Ischecked: false
		},
		{
			name: "Outside Food Allowed",
			value: "Outside Food Allowed",
			spaceType: ["Training Room", "Conference Room", "Shared Workspace"],
			Ischecked: false
		},
		{
			name: "With Systems",
			value: "With Systems",
			spaceType: ["Training Room"],
			Ischecked: false
		},
		{
			name: "Work Pod",
			value: "Work Pod",
			spaceType: ["Training Room"],
			Ischecked: false
		}
	];
	autocomplete: any;
	mandatoryFieldsLabels = {
		'bankname_branch': 'Bank name & Branch',
		'bankAccountNumber': 'Bank Account Number',
		'operationTiming': 'Operation Timings',
		'location': 'Lat, Long',
		'spaceName': 'Space Name',
		'spaceDesc': 'Description',
		'ownerName': 'Owner Name',
		'mobile': 'Owner Phone',
		'ownerEmail': 'Owner Email',
		'managerName': 'Manager Name',
		'address': 'All Address Field',
		'gofRevenueShare': 'GoFloaters Revenue Share',
		'noiseLevel': 'Noise Level',
		'gstin': 'GSTIN',
		'priceperday': 'Rate / day',
		'priceperhr': 'Rate / hr',
		'barCodeId': 'QR Code ID',
		'photos': 'Atleast One Photo',
		'officeSpaceType': 'Office Space Type',
		'purposesList': 'Best Suited For',
		'facilitiesList': 'Atleast One Facility',
		'gofloatersSpaceName': 'GoFloaters Space Name',
		'spaceDisplayName': 'Space Display Name',
		"googleMapsLink":"Google Maps Link"
	};
	mandatoryFields = [
		'spaceName',
		'spaceDesc',
		'ownerName',
		'mobile',
		'ownerEmail',
		'managerName',
		'address',
		'noiseLevel',
		'bankname_branch',
		'bankAccountNumber',
		'operationTiming',
		'location',
		'gofRevenueShare',
		'gstin',
		'priceperday',
		'priceperhr',
		'barCodeId',
		'photos',
		'officeSpaceType',
		'purposesList',
		'facilitiesList',
		'gofloatersSpaceName',
		'spaceDisplayName',
		'googleMapsLink'
	];
	private imageChangedEvent = null;
	croppedImage: string;
	imageCroppingDone: boolean = true;
	croppedImageSize: string = "";
	photoURLToEdit : string = null;
	// spaceOwnerId = '';
	constructor(
		private _addspaceservice: AddspaceService,
		private route: ActivatedRoute,
		private router: Router,
		private _bookingservice: BookingService,
		private loader: LoaderService,
		private mapsAPILoader: MapsAPILoader,
		private ngZone: NgZone,
		private storage: AngularFireStorage,
		private dragulaService: DragulaService,
		public loginService: LoginService,
		public sharedService: SharedProvider,
		private dialogRef: MatDialogRef<AddSpaceComponent>,
		private db: AngularFireDatabase,
		@Inject(MAT_DIALOG_DATA) private data
	) {
		this.id = this.data.spaceID;
		this.editMode = this.data.editMode;
		this.storageUrl =
			AppConstants.CONSTANTS[
				AppConstants.CONSTANTS.currentEnvironment
			].firebase.storageBucket;
		this.fileStoragePath = AppConstants.CONSTANTS.filestorage.filepath;
		dragulaService.createGroup("space-photos", {
			revertOnSpill: true
		});
	}

	setAddress(place: any) {
		try {
			this.space.location = place.location;
			const { zipcode, country, street, city, locality } = place;
			this.space.address = {
				zipcode,
				country,
				street,
				city,
				locality
			};
		} catch (e) { }
	}

	ngOnInit() {
		this.moment.tz.setDefault('Asia/Kolkata');
		this.loader.display(true);
		const user = this.loginService.getCurrentUser();
		// console.log("add-Space user",user);
		if (this.spaceSuperUsers.includes(user.email)) {
			this.isSuperUser = true;
		}
		if (user.uid !== undefined) {
			// this.spaceOwnerId = user.uid;
			this.sharedService.isLoggedIn = true;
		} else {
			this.loginNow();
		}
		const that = this;

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(that.showPosition);
			this.loader.display(false);
		} else {
			alert("Geolocation is not supported by this browser.");
		}
		// this.id = this.route.snapshot.params['id'];
		if (this.id) {
			setTimeout(() => {
				$("div.label-floating").toggleClass("is-empty");
			}, 1000);
			this.editSpace();
		}
		if (this.sharedService.citiesMetaData) {
			this.cityInfo = this.sharedService.getActiveCities();
		}
		else {
			this.loader.display(true);
			this._bookingservice.getMetaData().then(val => {
				this.cityInfo = this.sharedService.getActiveCities();
				this.loader.display(false);
			}).catch(err => {
				console.log(err);
			});
		}
	}

	ngOnDestroy() {
		this.dragulaService.destroy("space-photos");
	}

	showPosition(position) {
		// (<HTMLInputElement>document.getElementById('location')).value =  position.coords.latitude + ', ' + position.coords.longitude;
	}
	gotomangespace() {
		this.loader.display(false);
		this.router.navigate(["/landingpage/manage"]);
	}
	addspace() {
		this.space.purposes = this.selectedpurposes;
		this.space.purposesList = this.purposesList;
		// if (this.space.dayPassAvailable) {
		// 	if (
		// 		this.space.spaceType == "Restaurant" ||
		// 		this.space.spaceType == "Cafe"
		// 	) {
		// 		if (!this.space.priceperday) {
		// 			this.showerrorDialogdaypass = true;
		// 			return;
		// 		}
		// 	} else if (this.space.spaceType == "Shared Workspace") {
		// 		if (!this.space.pricepermonth) {
		// 			this.showerrorDialogRatePerMonth = true;
		// 			return;
		// 		}
		// 	}
		// }
		if (this.space.dayPassAvailable) {
			if (!this.space.priceperday) {
				this.showerrorDialogdaypass = true;
				return;
			}
		}
		if (this.space.monthPassAvailable) {
			if (!this.space.pricepermonth) {
				this.showerrorDialogRatePerMonth = true;
				return;
			}
		}
		// tslint:disable-next-line:max-line-length
		//this.space['location'] = (<HTMLInputElement>document.getElementById('location')).value ? ((<HTMLInputElement>document.getElementById('location')).value).split(',') : [];
		// this.space['facilities'] = (<HTMLInputElement>document.getElementById('facilities')).value ? ((<HTMLInputElement>document.getElementById('facilities')).value).split(',') : [];
		// const otherFac = this.otherfacilitiesAvailable ? this.otherfacilitiesAvailable.split(',') : [];
		// this.space['facilities'] = _.compact(_.map(_.union(this.facilitiesAvailable, otherFac), (value) => {
		//   return value.trim();
		// }));
		// this.space["facilities"] = this.facilities_arr;
		const otherFac = this.otherfacilitiesAvailable
			? this.otherfacilitiesAvailable.split(",")
			: [];
		this.space["facilities"] = _.compact(
			_.map(_.union(this.facilities_arr, otherFac), value => {
				return value.trim();
			})
		);

		this.space["facilitiesList"] = this.newFacilities_arr;
		if (this.space.spaceSubType && this.space.spaceSubType.indexOf('Office Space') == -1) {
			if (this.space.officeSpaceType)
				this.space.officeSpaceType = '';
		}

		this.space["videos"] = (<HTMLInputElement>document.getElementById("videos"))
			.value
			? (<HTMLInputElement>document.getElementById("videos")).value.split(",")
			: [];
		if (this.space["dateAdded"]) {
			this.space["dateAdded"] = Number(
				this.moment(this.space["dateAdded"]).tz("Asia/Kolkata")
			);
		} else {
			this.space["dateAdded"] = Number(this.moment().tz("Asia/Kolkata"));
		}

		this.space.operationTiming = _.omit(this.getOperatingTimings(), ["name"]);
		// Update the discount fields
		// if (this.space.discountsApplicable) {
		if (this.dayPassDiscountApplicable || this.hourPassDiscountApplicable) {
			if (!this.space.discountsApplicable) {
				this.space["discountsApplicable"] = {};
			}
			if (this.discountValidTillDate) {
				this.space.discountsApplicable["validTillDate"] = Number(
					this.moment(this.discountValidTillDate).endOf("day").tz("Asia/Kolkata")
				);
				// console.log("this.space.discountsApplicable.validTillDate",this.space.discountsApplicable["validTillDate"]);
			} else {
				this.space["discountsApplicable"]["validTillDate"] = Number(this.moment().endOf("day").tz("Asia/Kolkata"));
			}
			if (this.dayPassDiscountApplicable) {
				this.space.discountsApplicable["priceperday"] = this.discountedPriceperday;
			}
			if (this.hourPassDiscountApplicable) {
				this.space.discountsApplicable["priceperhr"] = this.discountedPriceperhr;
			}
			console.log("Updated discountsApplicable", this.space.discountsApplicable);
		}
		if (this.space.discountsApplicable) {
			if (!this.dayPassDiscountApplicable) {
				delete this.space.discountsApplicable.priceperday;
			}
			if (!this.hourPassDiscountApplicable) {
				delete this.space.discountsApplicable.priceperhr;
			}
			if (!this.dayPassDiscountApplicable && !this.hourPassDiscountApplicable) {
				delete this.space.discountsApplicable.validTillDate;
			}
		}

		// }

		if (this.validateFields()) {
			this.loader.display(true);
			if (this.space.spaceId) {
				this.validateGofloatersSpaceName().then((val: any) => {
					if (val.valid == "false") {
						this.loader.display(false);
						this.showMandatoryFields = true;
						this.mandatoryFieldsErrorMessage = "GoFloaters Space Name already exists. Kindly enter a unique name."
					}
					else {
						this._bookingservice.updatespace(this.space).then(
							(res:any) => {
								if (res) {
									this.space.dateAdded = this.moment(this.space["dateAdded"])
										.tz("Asia/Kolkata")
										.format("YYYY-MM-DD");
									if (res.discountsApplicable) {
										if (res.discountsApplicable.validTillDate) {
											// this.space["discountsApplicable"]["validTillDate"] = this.moment(res.discountsApplicable.validTillDate)
											// 	.tz("Asia/Kolkata")
											// 	.format("YYYY-MM-DD");
											this.discountValidTillDate = this.moment(res.discountsApplicable.validTillDate)
											.tz("Asia/Kolkata")
											.format("YYYY-MM-DD");
										}
										if (res.discountsApplicable.priceperday) {
											this.dayPassDiscountApplicable = true;
											this.discountedPriceperday = res.discountsApplicable.priceperday
										}
										if (res.discountsApplicable.priceperhr) {
											this.hourPassDiscountApplicable = true;
											this.discountedPriceperhr = res.discountsApplicable.priceperhr;
										}
									} else {
										this.dayPassDiscountApplicable = false;
										this.hourPassDiscountApplicable = false;
									}
									this.showSuccessDialog = true;
								} else {
									this.showerrorDialog = true;
								}
								this.loader.display(false);
							},
							err => {
								this.showerrordbDialog = true;
							}
						);
					}
				});


			} else {
				this.validateGofloatersSpaceName().then((val: any) => {
					if (val.valid == "false") {
						this.loader.display(false);
						this.showMandatoryFields = true;
						this.mandatoryFieldsErrorMessage = "GoFloaters Space Name already exists. Kindly enter a unique name."
					}
					else {
						this._bookingservice.createspace(this.space).then(
							(res:any) => {
								if (res) {
									this.space = res;
									this.space.dateAdded = this.moment(this.space["dateAdded"])
										.tz("Asia/Kolkata")
										.format("YYYY-MM-DD");
									if (res.discountsApplicable) {
										if (res.discountsApplicable.validTillDate) {
											this.space["discountsApplicable"]["validTillDate"] = this.moment(res.discountsApplicable.validTillDate)
												.tz("Asia/Kolkata")
												.format("YYYY-MM-DD");
										}
									}
									if (res.discountsApplicable.priceperday) {
										this.dayPassDiscountApplicable = true;
									}
									if (res.discountsApplicable.priceperhr) {
										this.hourPassDiscountApplicable = true;
									}
									this.showSuccessDialog = true;
								} else {
									this.showerrorDialog = true;
								}
								this.loader.display(false);
							},
							err => {
								this.showerrordbDialog = true;
							}
						);
					}
				})

			}
		}
	}


	validateGofloatersSpaceName() {
		return new Promise((resolve, reject) => {
			if (this.space.gofloatersSpaceName) {
				if (this.space.spaceType && this.space.spaceType == 'Cafe')
				// || (this.space.spaceSubType && this.space.spaceSubType.indexOf('Work Cafe') !== -1)
				{
					resolve({ valid: "true" });
				}
				else {
					this.db.list('spaces', ref => ref.orderByChild("gofloatersSpaceName")
						.equalTo(this.space.gofloatersSpaceName))
						.query.once('value').then((snapshot) => {
							if (snapshot.exists()) {
								if (this.space.spaceId) //While editing existing space
								{
									let existSpaceId = Object.keys(snapshot.val())[0];
									if (existSpaceId != this.space.spaceId) {
										resolve({ valid: "false" });
									}
									else {
										resolve({ valid: "true" });
									}
								}
								else    //While adding a new space
								{
									resolve({ valid: "false" });
								}
							}
							else {
								resolve({ valid: "true" });
							}
						})
						.catch(err => {
							reject(err);
						});
				}

			}
			else {
				resolve({ valid: "true" });
			}

		})


	}



	validateFields(): boolean {
		const { space, mandatoryFields } = this;
		if (space.online) {
			for (let index = 0; index < mandatoryFields.length; index++) {
				const element = mandatoryFields[index];
				const spaceElement = space[element];
				let valid = true;
				if (spaceElement === null || spaceElement === undefined || (typeof spaceElement === 'string' && spaceElement === '')) {
					if (element === 'gstin') {
						if (space.gstRegistered) {
							valid = false;
						}
					} else if (element === 'priceperday') {
						if (space.dayPassAvailable) {
							valid = false;
						}
					} else if (element === 'priceperhr') {
						if (space.hourPassAvailable) {
							valid = false;
						}
					} else if (element === 'barCodeId') {
						if (['Cafe', 'Restaurant'].includes(space.spaceType) && space.acceptFoodCredits) {
							valid = false;
						}
					} else if (element === 'officeSpaceType') {
						if (space.spaceSubType && space.spaceSubType.indexOf('Office Space') !== -1 && !space.officeSpaceType) {
							valid = false;
						}
					}
					else {
						valid = false;
					}
				} else if (element === 'location') {
					if (!Array.isArray(spaceElement) || !spaceElement[0] || !spaceElement[1]) {
						valid = false;
					}
				} else if ((space.spaceSubType && element == 'facilitiesList')
					|| (space.spaceSubType && (space.spaceSubType.indexOf('Office Space') == -1 || space.spaceSubType.length > 1) && element === 'purposesList')) {
					if (!Array.isArray(spaceElement) || spaceElement.length === 0) {
						valid = false;
					}
				} else if (element === 'address') {
					if (!spaceElement.street || !spaceElement.locality || !spaceElement.city ||
						!spaceElement.country || !spaceElement.zipcode || !spaceElement.landmark) {
						valid = false;
					}
				} else if (element === 'photos') {
					if (!spaceElement || spaceElement.length === 0) {
						valid = false;
					}
				}
				if (valid === false) {
					this.showMandatoryFields = true;
					this.mandatoryFieldsErrorMessage = `${this.mandatoryFieldsLabels[element]} is required`;
					return false;
				}
			}
		}
		return true;
	}
	setOperatingTimings() {
		if (!this.space.operationTiming) {
			return;
		}
		let { days } = this.space.operationTiming;
		let allWeekDays: any = {};
		let allWeekEndDays: any = {};
		if (
			days["monday"].from == days["tuesday"].from &&
			(days["wednesday"].from == days["thursday"].from) ==
			(days["friday"].from == days["monday"].from) &&
			(days["monday"].to == days["tuesday"].to &&
				days["wednesday"].to == days["thursday"].to &&
				(days["friday"].to && days["monday"].to))
		) {
			allWeekDays.from = days["monday"].from;
			allWeekDays.to = days["monday"].to;
			allWeekDays.useAll = true;
		}
		if (
			days["monday"].useNow &&
			days["tuesday"].useNow &&
			days["wednesday"].useNow &&
			days["thursday"].useNow &&
			days["friday"].useNow
		) {
			allWeekDays.useNow = true;
		}
		if (
			days["monday"].holiday &&
			days["tuesday"].holiday &&
			days["wednesday"].holiday &&
			days["thursday"].holiday &&
			days["friday"].holiday
		) {
			allWeekDays.holiday = true;
		}
		if (
			days["saturday"].from == days["sunday"].from &&
			days["saturday"].to == days["sunday"].to
		) {
			allWeekEndDays.from = days["saturday"].from;
			allWeekEndDays.to = days["sunday"].to;
			allWeekEndDays.useAll = true;
		}
		if (days["saturday"].useNow && days["sunday"].useNow) {
			allWeekEndDays.useNow = true;
		}
		if (days["saturday"].holiday && days["sunday"].holiday) {
			allWeekEndDays.holiday = true;
		}
		const items = this.operationTimings.items;
		days = this.days.map(day => {
			days[day.toLowerCase()].name = day;
			return days[day.toLowerCase()];
		});
		this.operationTimings = {
			displayName: "",
			items: [
				{
					title: items[0].title,
					name: items[0].name,
					from: allWeekDays.from,
					to: allWeekDays.to,
					holiday: allWeekDays.holiday,
					useNow: allWeekDays.useNow,
					useAll: allWeekDays.useAll,
					timing: days.slice(0, 5)
				},
				{
					title: items[1].title,
					name: items[1].name,
					from: allWeekEndDays.from,
					to: allWeekEndDays.to,
					holiday: allWeekEndDays.holiday,
					useNow: allWeekEndDays.useNow,
					useAll: allWeekEndDays.useAll,
					timing: days.slice(5, 7)
				}
			]
		};
	}

	onChangeHoliday(type, val) {
		this.operationTimings.items = this.operationTimings.items.map(
			(item: any) => {
				if (item.title == type) {
					if (val) {
						item.from = "00:00";
						item.to = "00:00";
						item.useNow = false;
						item.timing = item.timing.map((time: any) => {
							time.holiday = true;
							time.useNow = false;
							time.from = "00:00";
							time.to = "00:00";
							return time;
						});
					} else {
						item.from = "09:00";
						item.to = "18:00";
						item.useNow = true;
						item.timing = item.timing.map((time: any) => {
							time.holiday = false;
							time.useNow = true;
							time.from = "09:00";
							time.to = "18:00";
							return time;
						});
					}

					return item;
				} else {
					return item;
				}
			}
		);
	}

	onChangeUseNow(type, val) {
		this.operationTimings.items.map(item => {
			if (item.title == type) {
				return item.timing.map((time: any) => {
					if (val) {
						time.useNow = true;
					} else {
						time.useNow = false;
					}
					return time;
				});
			} else {
				return item;
			}
		});
	}
	onChangefrom(type, val) {
		this.operationTimings.items.map(item => {
			if (item.title == type) {
				return item.timing.map((time: any) => {
					if (val) {
						time.from = val;
					}
					return time;
				});
			} else {
				return item;
			}
		});
	}

	onChangeTo(type, val) {
		this.operationTimings.items.map(item => {
			if (item.title == type) {
				return item.timing.map((time: any) => {
					if (val) {
						time.to = val;
					}
					return time;
				});
			} else {
				return item;
			}
		});
	}
	onChangeDayHoliday(type, day, val) {
		this.operationTimings.items.map(item => {
			item.holiday = _.every(item.timing, "holiday");
			item.useNow = _.every(item.timing, "useNow");
			item.timing = item.timing.map((time: any) => {
				if (time.name == day) {
					if (val) {
						time.from = "00:00";
						time.to = "00:00";
					}
					time.useNow = !val;
					return time;
				} else {
					return time;
				}
			});
			return item;
		});
	}

	onChangeDayFrom(type, val) {
		this.operationTimings.items.map(item => {
			if (item.title == type) {
				if (_.every(item.timing, (ev) => {
					return ev.from == val;
				})) {
					item.from = val;
				} else {
					item.from = "";
				}
			}
			return item;
		});
	}

	onChangeDayTo(type, val) {
		this.operationTimings.items.map(item => {
			if (item.title == type) {
				if (_.every(item.timing, (ev) => {
					return ev.to == val;
				})) {
					item.to = val;
				} else {
					item.to = "";
				}
			}
			return item;
		});
	}

	onChangeDayUseNow(type, val) {
		this.operationTimings.items.map(item => {
			item.useNow = _.every(item.timing, "useNow");
			return item;
		});
	}

	getOperatingTimings() {
		const items = this.operationTimings.items;
		const weekDays = items[0];
		const weekEndDays = items[1];
		if (weekDays.useAll) {
			items[0].timing = items[0].timing.map(time => ({
				from: weekDays.from,
				to: weekDays.to,
				holiday: weekDays.holiday,
				useNow: weekDays.useNow,
				name: time.name
			}));
		}

		if (weekEndDays.useAll) {
			items[1].timing = items[1].timing.map(time => ({
				from: weekEndDays.from,
				to: weekEndDays.to,
				holiday: weekEndDays.holiday,
				useNow: weekEndDays.useNow,
				name: time.name
			}));
		}

		let timings = items[0].timing.concat(items[1].timing);
		let days = [];
		let continousdays = ["Monday"];
		let resultString = [];
		// let holidays = [];
		// const sorter = {
		//   "Sunday": 7, // << if sunday is first day of week
		//   "Monday": 1,
		//   "Tuesday": 2,
		//   "Wednesday": 3,
		//   "Thursday": 4,
		//   "Friday": 5,
		//   "Saturday": 6

		// }
		// _.groupBy([6.1, 4.2, 6.3], Math.floor);
		// const modifiedData = timings.map((time: any) => {
		//   const a = {
		//     day : time.name,
		//     holiday : time.holiday,
		//     number : sorter[time.name],
		//     timeString : this.moment(time.from, "HH:mm")
		//     .tz("Asia/Kolkata").format("hh:mm A") +" to " + this.moment(time.to, "HH:mm").tz("Asia/Kolkata").format("hh:mm A");
		//   }
		//   return a;
		// });

		for (let index = 0; index < timings.length; index++) {
			const element = timings[index];
			const timeString =
				this.moment(element.from, "HH:mm")
					.tz("Asia/Kolkata")
					.format("hh:mm A") +
				" to " +
				this.moment(element.to, "HH:mm")
					.tz("Asia/Kolkata")
					.format("hh:mm A");
			days.push(timeString);
			if (index > 0) {
				if (days[index - 1] == timeString) {
					continousdays.splice(1, 1, element.name);
				} else {
					resultString.push(continousdays.join("-") + " : " + days[index - 1]);
					continousdays = [element.name];
				}

				if (index == timings.length - 1) {
					if (element.name != continousdays[0]) {
						continousdays.splice(1, 1, element.name);
						resultString.push(continousdays.join("-") + " : " + timeString);
					} else {
						resultString.push(continousdays + " : " + timeString);
					}
					if (days[0] == timeString) {
						const combineString =
							resultString[resultString.length - 1]
								.split(":")[0]
								.split("-")[0] +
							"-" +
							resultString[0]
								.split(":")[0]
								.split("-")
								.slice(-1)[0] +
							" : " +
							timeString;
						resultString.splice(0, 1);
						resultString.pop();
						resultString.push(combineString);
					}
				}
			}
		}

		timings = _.keyBy(timings, val => val.name.toLowerCase());
		timings = _.omit(timings, [
			"monday.name",
			"tuesday.name",
			"wednesday.name",
			"thursday.name",
			"friday.name",
			"saturday.name",
			"sunday.name"
		]);


		this.space.operationTime = this.replaceAll(
			resultString.join(", "),
			"12:00 AM to 12:00 AM",
			"Holiday"
		);

		var mapObj = {
			Monday: "Mon",
			Tuesday: "Tue",
			Wednesday: "Wed",
			Thursday: "Thu",
			Friday: "Fri",
			Saturday: "Sat",
			Sunday: "Sun"

		};
		this.space.operationTime = this.space.operationTime.replace(/Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday/gi, function (matched) {
			return mapObj[matched];
		});

		return {
			displayName: this.replaceAll(
				resultString.join(", "),
				"12:00 AM to 12:00 AM",
				"Holiday"
			),
			days: timings
		};
	}

	replaceAll(str, find, replace) {
		return str.replace(new RegExp(find, "g"), replace);
	}

	success() {
		if (this.editMode) {
			// this.dialogRef.close(true);
			this.dialogRef.close(false);
		} else {
			// this.router.navigate(['/landingpage/manage']);
		}
	}
	addlocations() {
		if (this.locationval) {
			this.space = Object.assign({}, this.space, this.locationval);
		}
	}
	cancel() {
		if (this.editMode) {
			this.dialogRef.close(false);
		} else {
			this.router.navigate(["/landingpage/manage"]);
		}
	}
	editSpace() {
		this.loader.display(true);
		const data = {
			id: this.id,
			app:'admin'
		};
		this._bookingservice.getSpace(data).then((res: any) => {
			// this.mapbestSuitedList(res.spaceType);
			this.space = res;
			// if (this.space.purposes && this.space.purposes.length > 0) {
			// 	this.selectedpurposes = this.space.purposes;
			// }
			if (this.space.purposesList && this.space.purposesList.length > 0) {
				this.purposesList = this.space.purposesList;
			}
			if (res.facilitiesList && res.facilitiesList.length > 0) {
				this.newFacilities_arr = res.facilitiesList;
			}
			if (!this.space.spaceSubType) {
				if (this.space.spaceType) {
					let oldType = this.space.spaceType;
					let newType = [];
					switch (oldType) {
						case 'Cafe': newType.push('Work Cafe'); break;
						case 'Conference Room': newType.push('Meeting Space'); break;
						case 'Training Room': newType.push('Training Room'); break;
						case 'Shared Workspace': newType.push('Office Space'); break;
					}
					this.space.spaceSubType = newType;
					this.mapFacilitiesAndPurposes(newType);
				}
			}
			if (!this.space.spaceDisplayName) {
				this.space.spaceDisplayName = this.space.spaceName;
			}
			if (this.space.spaceSubType) {
				this.mapFacilitiesAndPurposes(this.space.spaceSubType);
			}
			this.setOperatingTimings();
			if (!this.space.location) {
				this.space.location = [];
			}
			// const otherfacilites = [];
			// if (res.facilities) {
			// 	Object.keys(res.facilities).forEach((key, index) => {
			// 		let index_arr = this.SpaceInfo.findIndex(x => {
			// 			return x.value == res.facilities[index];
			// 		});
			// 		if (index_arr >= 0) {
			// 			this.SpaceInfo[index_arr].Ischecked = true;
			// 		} else {
			// 			otherfacilites.push(res.facilities[index]);
			// 		}
			// 	});
			// }
			// this.otherfacilitiesAvailable = otherfacilites.join(",");

			// if (this.space.facilities) {
			// 	this.facilities_arr = _.difference(
			// 		this.space.facilities,
			// 		otherfacilites
			// 	);
			// }
			if (!this.space.calendarURL) {
				this.space.calendarURL = 'N/A';
			}
			if (!this.space.calendarId) {
				this.space.calendarId = '';
			}

			if (res.dateAdded) {
				this.space.dateAdded = this.moment(res.dateAdded)
					.tz("Asia/Kolkata")
					.format("YYYY-MM-DD");
			}
			if (res.discountsApplicable) {
				if (res.discountsApplicable.validTillDate) {
					this.discountValidTillDate = this.moment(res.discountsApplicable.validTillDate)
					.tz("Asia/Kolkata")
					.format("YYYY-MM-DD");
					// console.log("validTillDate",this.space["discountsApplicable"]["validTillDate"]);
				}
				if (res.discountsApplicable.priceperday) {
					this.dayPassDiscountApplicable = true;
					this.discountedPriceperday = res.discountsApplicable.priceperday;
				}
				if (res.discountsApplicable.priceperhr) {
					this.hourPassDiscountApplicable = true;
					this.discountedPriceperhr = res.discountsApplicable.priceperhr;
				}
			}
			this.loader.display(false);
		});
	}

	// onChangeSpaceType($event, spacetype) {
	// 	this.mapbestSuitedList(spacetype);
	// }

	// onChangePurpose($event, purpose) {
	// 	this.space.purposes = purpose;
	// }
	onChangePurposeList($event, purpose) {
		this.space.purposesList = purpose;
	}
	// mapbestSuitedList(spacetype) {
	// 	this.selectedpurposes = [];
	// 	this.space.purposes = [];
	// 	this.bestSuitedList.map(val => {
	// 		if (val.type.indexOf(spacetype) > -1) {
	// 			this.bestSuitedFor = val.bestSuited;
	// 		}
	// 	});
	// }
	fileUpload(evt) {
		const that = this;
		if (this.space.spaceName) {
			const storageRef = firebase.storage().ref();
			const filename = this.space.spaceName + Math.floor(Date.now());
			evt.stopPropagation();
			evt.preventDefault();
			const file = evt.target.files[0];
			const metadata = {
				contentType: file.type
			};

			storageRef
				.child("images/" + filename)
				.put(file, metadata)
				.then(function (snapshot) {
					const url = snapshot.downloadURL;
					if (that.space.photos) {
						that.space.photos.push(url);
					} else {
						that.space["photos"] = [];
						that.space.photos.push(url);
					}
				})
				.catch(function (error) {
					console.error("Upload failed:", error);
				});
		}
	}

	Failure() {
		this.router.navigate(["/add-space"]);
	}

	dbFauilure() {
		this.router.navigate(["/add-space"]);
	}

	disbaleFileUpload() {
		if (
			this.space.spaceName &&
			this.space.address &&
			this.space.address.city &&
			this.space.address.street &&
			this.space.address.locality &&
			this.space.address.country &&
			this.space.address.zipcode
		) {
			return false;
		} else {
			return true;
		}
	}

	onChange(faciliy: any, isChecked: boolean) {
		if (isChecked == true) {
			this.facilities_arr.push(faciliy);
		} else {
			this.facilities_arr.splice(this.facilities_arr.indexOf(faciliy), 1);
		}
	}

	onChangeAllFacilities(facility: any, isChecked: boolean) {
		if (isChecked == true) {
			this.newFacilities_arr.push(facility);
		} else {
			this.newFacilities_arr.splice(this.newFacilities_arr.indexOf(facility), 1);
		}
	}


	onChangeNewSpaceType(newSpaceType) {
		this.mapFacilitiesAndPurposes(newSpaceType);
	}
	mapFacilitiesAndPurposes(newSpaceType) {
		let purposesIndex = [];
		this.bestSuitedForList = [];
		if (newSpaceType.length == 0)  //no spacetype selected
		{
			this.facilitiesList = [];
			this.purposesList = [];
			this.newFacilities_arr = [];
			this.disableGofloatersSpaceName = false;
			this.space.gofloatersSpaceName = '';
			this.commonFacilities.forEach(element => {
				element.Ischecked = false;
			});
			this.specificFacilities.forEach(element => {
				element.Ischecked = false;
			});
		}
		else {
			if (newSpaceType.indexOf('Work Cafe') !== -1) {
				this.space.gofloatersSpaceName = 'N/A';
				this.disableGofloatersSpaceName = true;
			}
			else {
				this.disableGofloatersSpaceName = false;
			}
			let fac = [];
			this.facilitiesList = [];
			this.facilitiesList = this.commonFacilities;
			newSpaceType.forEach(element => {
				let type = '';
				switch (element) {
					case 'Work Cafe': {
						type = "Cafe";
						if (purposesIndex.indexOf(0) == -1)
							purposesIndex.push(0);
						break;
					}
					case 'Office Space': { type = "Shared Workspace"; break; }
					case 'Meeting Space': {
						type = "Conference Room";
						if (purposesIndex.indexOf(1) == -1)
							purposesIndex.push(1);
						break;
					}
					case 'Work Pod': {
						type = "Training Room";
						if (purposesIndex.indexOf(1) == -1)
							purposesIndex.push(1);
						break;
					}
					case 'Training Room': {
						type = "Training Room";
						if (purposesIndex.indexOf(1) == -1)
							purposesIndex.push(1);
						break;
					}
					case 'Event Space': {
						type = "Training Room";
						if (purposesIndex.indexOf(1) == -1)
							purposesIndex.push(1);
						break;
					}
					case 'Banquet Halls': {
						type = "Training Room";
						if (purposesIndex.indexOf(1) == -1)
							purposesIndex.push(1);
						break;
					}
				}

				//Adding facilities specific to the space type selected
				for (let i = 0; i < this.specificFacilities.length; i++) {
					if (this.specificFacilities[i].spaceType.indexOf(type) !== -1)
						fac.push(this.specificFacilities[i]);
				}
				this.facilitiesList = _.union(this.facilitiesList, fac);
			});
			this.updateFacilities();

			//Populating values for the Best Suited For dropdown based on all the spacetypes selected finally
			purposesIndex.map(val => {
				this.bestSuitedList[val].bestSuited.forEach(element => {
					this.bestSuitedForList.push(element);
				});
			});
			this.updatePurposes();
		}
	}

	updateFacilities() {

		let tempFacilities = [];
		tempFacilities = [...this.newFacilities_arr];
		// this.newFacilities_arr.forEach(element => {
		// 	tempFacilities.push(element);
		// });
		this.newFacilities_arr.forEach(facility => {
			for (let i = 0; i < this.facilitiesList.length; i++) {
				if (this.facilitiesList[i].value == facility) {
					this.facilitiesList[i].Ischecked = true;
					break;
				}
				if (i == this.facilitiesList.length - 1) {
					tempFacilities.splice(tempFacilities.indexOf(facility), 1);
					for (let j = 0; j < this.specificFacilities.length; j++) {
						if (this.specificFacilities[j].value == facility) {
							this.specificFacilities[j].Ischecked = false;
							break;
						}
					}
				}
			}
		});
		this.newFacilities_arr = [];
		this.newFacilities_arr = [...tempFacilities];
		// tempFacilities.forEach(element => {
		// 	this.newFacilities_arr.push(element);
		// });
		// console.log(this.newFacilities_arr);
	}

	updatePurposes() {
		//Removing the purposes not suitable for the spaceType
		let tempPurposes = [];
		tempPurposes = [...this.purposesList];
		// this.purposesList.forEach(element => {
		// 	tempPurposes.push(element);
		// });
		this.purposesList.forEach(element => {
			if (this.bestSuitedForList.indexOf(element) == -1) {
				tempPurposes.splice(tempPurposes.indexOf(element), 1);
			}
		});
		this.purposesList = [];
		this.purposesList = [...tempPurposes];
		// tempPurposes.forEach(element => {
		// 	this.purposesList.push(element);
		// });
		// console.log(this.purposesList);
	}

	uploadFileForCropping(event) {
		// console.log("Image for cropping",event);
		if (!this.disbaleFileUpload()) {
			const filelist = event;
			if (filelist.length > 1) {
				console.error("Select only one file at a time");
				alert("Upload only one file at a time.")
				return;
			}
			this.imageChangedEvent = event;
			this.imageCroppingDone = false;
		}
	}

	editImage(photoUrl) {
		// if (AppConstants.CONSTANTS.currentEnvironment == "prod") {
		// 	this.photoURLToEdit=  photoUrl.replace('https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o','https://cdn.app.gofloaters.com');
		// } else {
			this.photoURLToEdit = photoUrl;
		// }
		this.imageCroppingDone = false;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
		this.croppedImageSize = event.width + " px X " + event.height + " px"; 
	}
	async doneCropping () {
		// console.log("this.croppedImage",this.croppedImage);
		// this.croppedImage = null;
		await this.uploadFileBase64();
		this.imageCroppingDone = true;
		this.imageChangedEvent = null;
		this.photoURLToEdit = null;
	}
	imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
	}

	uploadFileBase64() {
		const filename = `${this.space.spaceName}_${new Date().getTime()}`;
		const filePath = this.fileStoragePath + "/" + filename;
		const customMetadata = { app: "Space Admin App", name: filename,contentType:'image/jpg' };
		const fileRef = this.storage.ref(filePath);
		const task = fileRef.putString(this.croppedImage.split(',')[1],'base64',customMetadata);
		const _percentage$ = task.percentageChanges();
		this.alltaskList.push(task);
		this.allPercentage.push(_percentage$);
		task.then(ress => {
			ress.ref.getDownloadURL().then(val => {
				let link = val;
				var index = _.findIndex(this.uploadPercentageList, {
					filename: this.getfilename(link)
				});
				if (index >= 0) {
					this.uploadPercentageList.splice(index, 1);
				}
				this.downloadURLs = this.downloadURLs.concat([link]);
				// this.allPercentage.forEach(val => {
				// })
				if (this.space && this.space.photos && this.space.photos.length > 0) {
					// this.space.photos = this.space.photos.push(url);
					this.space.photos = _.union(this.space.photos, this.downloadURLs);
					this.createOrAddPhotos("add", this.space);
				} else {
					// const updatedImage = [...this.downloadURLs];
					this.space.photos = [...this.downloadURLs];
					if (this.space.spaceId) {
						this.createOrAddPhotos("add", this.space);
					} else {
						this.createOrAddPhotos("create", this.space);
					}
				}
			});
		});
		// observe percentage changes
		this.uploadPercent = task.percentageChanges();
		this.alltaskList.push(task);
		// get notified when the download URL is available
		const ts = task
			.snapshotChanges()
			.pipe(
				tap(snap => {
					// console.log("snap",snap);
					var index = _.findIndex(this.uploadPercentageList, {
						filename: snap.metadata.name
					});
					// let avl_list = this.uploadPercentageList.find(percent => percent.filename === snap.metadata.customMetadata.name)
					if (index >= 0) {
						const updatedpercentage = {
							percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
							state: snap.state,
							filename: snap.metadata.name
						};
						this.uploadPercentageList.splice(index, 1, updatedpercentage);
					} else {
						const uploadPercentage = {
							percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
							state: snap.state,
							filename: snap.metadata.name
						};
						this.uploadPercentageList.push(uploadPercentage);
					}
					if (snap.bytesTransferred === snap.totalBytes) {
					}
				})
			)
			.subscribe();
	}


	uploadFile(event) {
		if (!this.disbaleFileUpload()) {
			this.downloadURLs = [];
			const filelist = event;
			if (filelist.length > 1) {
				console.error("Select only one file at a time");
				alert("Upload only one file at a time.")
				return;
			}
			for (const file of filelist) {
				if (file.size > 5242880) {
					console.error("File size is greater than 5 MB ");
					return;
				}

				if (file.type.split("/")[0] !== "image") {
					console.error("unsupported file type :( ");
					return;
				}
				const filename = `${new Date().getTime()}_${file.name}`;
				const filePath = this.fileStoragePath + "/" + filename;
				const customMetadata = { app: "Space Admin App", name: filename };
				const fileRef = this.storage.ref(filePath);
				const task = this.storage.upload(filePath, file, { customMetadata });
				const _percentage$ = task.percentageChanges();
				this.alltaskList.push(task);
				this.allPercentage.push(_percentage$);
				task.then(ress => {
					ress.ref.getDownloadURL().then(val => {
						let link = val;
						var index = _.findIndex(this.uploadPercentageList, {
							filename: this.getfilename(link)
						});
						if (index >= 0) {
							this.uploadPercentageList.splice(index, 1);
						}
						this.downloadURLs = this.downloadURLs.concat([link]);
						// this.allPercentage.forEach(val => {
						// })
						if (this.space && this.space.photos && this.space.photos.length > 0) {
							// this.space.photos = this.space.photos.push(url);
							this.space.photos = _.union(this.space.photos, this.downloadURLs);
							this.createOrAddPhotos("add", this.space);
						} else {
							// const updatedImage = [...this.downloadURLs];
							this.space.photos = [...this.downloadURLs];
							if (this.space.spaceId) {
								this.createOrAddPhotos("add", this.space);
							} else {
								this.createOrAddPhotos("create", this.space);
							}
						}
					});
				});
				// observe percentage changes
				this.uploadPercent = task.percentageChanges();
				this.alltaskList.push(task);
				// get notified when the download URL is available
				const ts = task
					.snapshotChanges()
					.pipe(
						tap(snap => {
							var index = _.findIndex(this.uploadPercentageList, {
								filename: snap.metadata.customMetadata.name
							});
							// let avl_list = this.uploadPercentageList.find(percent => percent.filename === snap.metadata.customMetadata.name)
							if (index >= 0) {
								const updatedpercentage = {
									percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
									state: snap.state,
									filename: snap.metadata.customMetadata.name
								};
								this.uploadPercentageList.splice(index, 1, updatedpercentage);
							} else {
								const uploadPercentage = {
									percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
									state: snap.state,
									filename: snap.metadata.customMetadata.name
								};
								this.uploadPercentageList.push(uploadPercentage);
							}
							if (snap.bytesTransferred === snap.totalBytes) {
							}
						})
					)
					.subscribe();
			}
		}
	}
	getfilename(fullpath) {
		let storagepath = decodeURIComponent(fullpath).split(
			this.storageUrl + "/o/" + this.fileStoragePath
		)[1];
		return storagepath.substring(1, storagepath.indexOf("?"));
	}
	createOrAddPhotos(action, space) {
		const spaceval = {
			action: action,
			// ownerId: this.spaceOwnerId,
			isApproved: this.space.isApproved,
			spacedata: space,
			spaceId: this.space.spaceId,
			spaceName: space.spaceName,
			city: space.city ? space.city : space.address.city,
			app: "adminapp"
		};

		if (action == "create") {
			spaceval.spacedata = space;
		} else {
			spaceval.spacedata = space.photos;
		}

		this._bookingservice
			.createOrAddSpacePhotos(spaceval)
			.then((spacevalue: any) => {
				this.space.spaceId = spacevalue.spaceId;
			});
	}

	deleteImage(name) {
		if (this.space.photos.length === 1) {
			/*
				If there is only one photo we just remove it only from dom.
				If the user try to save the changes we will show the error
				pop up
			*/
			this.space.photos = [];
			return;
		}
		this.loader.display(true);
		const updatedphotos = [...this.space.photos];
		updatedphotos.splice(updatedphotos.indexOf(name), 1);
		const spaceval = {
			action: "remove",
			// ownerId: this.spaceOwnerId,
			isApproved: this.space.isApproved,
			photos: updatedphotos,
			spaceId: this.space.spaceId,
			spaceName: this.space.spaceName,
			city: this.space.city ? this.space.city : this.space.address.city,
			app: "spaceowner"
		};

		this._bookingservice.createOrAddSpacePhotos(spaceval).then(spacevalue => {
			this.space.photos = [...updatedphotos];
			// Delete the photo from storage only if the photo is a firebase storage photo
			if (name.includes("firebasestorage.googleapis.com")) {
				let storagepath = name.split(this.storageUrl + "/o/")[1];
			const path = decodeURIComponent(
				storagepath.substring(0, storagepath.indexOf("?"))
			);
				const storageRef = firebase.storage().ref();
				storageRef
					.child(path)
					.delete()
					.then(val => {
						this.showSuccessImageDelete = true;
						this.loader.display(false);
					})
					.catch(err => {
						this.showFailureImageDelete = true;
						this.loader.display(false);
					});
			} else {
				//No need to delete the file from storage.. Hence return.
				this.showSuccessImageDelete = true;
				this.loader.display(false);
			}
		});
	}

	toggleHover(event: boolean) {
		this.isHovering = event;
	}

	// Bias the autocomplete object to the user's geographical location,
	// as supplied by the browser's 'navigator.geolocation' object.
	geolocate() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function (position) {
				var geolocation = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};
				var circle = new google.maps.Circle({
					center: geolocation,
					radius: position.coords.accuracy
				});
				this.autocomplete.setBounds(circle.getBounds());
			});
		}
	}

	loginNow() {
		this.loader.display(false);
		this.router.navigate(["/login"]);
	}
	photoFileName(photo) {
		// let fileName = decodeURI(photo);
		// console.log("fileName",fileName);
		let fileName= photo.replace("https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o/images","");
		if (photo.includes("https://creatorexport.zoho.com/file/gofloaters/csaas/All_Spaces_Images/3852549000002480015/")) {
			fileName= fileName.replace("https://creatorexport.zoho.com/file/gofloaters/csaas/All_Spaces_Images/3852549000002480015/","");
		}
		if (photo.includes("https://creatorexport.zoho.com/file/gofloaters/csaas/All_Branches_Photos/3852549000002480011/")) {
			fileName= fileName.replace("https://creatorexport.zoho.com/file/gofloaters/csaas/All_Branches_Photos/3852549000002480011/","");
		}
		if (photo.includes("/image-download/XNuw9Rhaj2dZsjn0u18ttRvPP2HACX4DmQ5ahXQ9T8B1yZbpNU7qfYOT4D9TpzMfHQmV6ZKHtr8evhRREt5F8mey0QsEUnJVFpRS?filepath=")) {
			fileName= fileName.replace("/image-download/XNuw9Rhaj2dZsjn0u18ttRvPP2HACX4DmQ5ahXQ9T8B1yZbpNU7qfYOT4D9TpzMfHQmV6ZKHtr8evhRREt5F8mey0QsEUnJVFpRS?filepath=","-");
		}
		if (photo.includes("/image-download/KyXgddj8qGrSPqyZKzmM17xENBy3TKznuCnVV6Uv5keu9zBJZC7yzk9Hm6MO2t47n29BsHxUd15GeraVr37H5VjUjWs4tarOysnm?filepath=")) {
			fileName= fileName.replace("/image-download/KyXgddj8qGrSPqyZKzmM17xENBy3TKznuCnVV6Uv5keu9zBJZC7yzk9Hm6MO2t47n29BsHxUd15GeraVr37H5VjUjWs4tarOysnm?filepath=","-");
		}
		fileName= fileName.replace("%2F","");
		fileName= fileName.replaceAll("%20"," ");
		fileName = fileName.split("?")[0];
		return fileName;
	}
}
