import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { AuthService } from '../auth.service';



@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  public fname: string;
  public lname: string; 
  email = '';
  password = '';
  
  error: {name: string, message: string} = {name: '', message: ''};

  ngOnInit() {} 

  constructor(public authService: AuthService, private router: Router) { }

 onSignupEmail(): void {
      // if (this.validateForm(this.email, this.password)) {
        this.authService.signUpWithEmail(this.email, this.password)
          .then(() => {
            this.router.navigate(['/login'])
          }).catch(_error => {
            this.error = _error
            this.router.navigate(['/signup'])
          })
      }

}
