import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BookingService } from "../appservices/booking.service";
import { LoaderService } from "../sharedcomponents/index";
import * as moment from "moment-timezone";
import { MessageService } from "../appservices/messageservice";
import { EditBookingComponent } from "../edit-booking/edit-booking.component";
import { ViewSpaceDetailsComponent } from '../view-space-details/view-space-details.component';
import { MatDialog, MatTableDataSource, MatTable } from "@angular/material";
import * as _ from "lodash";
import { AddNoteComponent } from '../sharedcomponents/add-note/add-note.component';
import {Title} from "@angular/platform-browser";
import { SharedProvider } from "../appservices/shared";
@Component({
	selector: "app-viewbooking",
	templateUrl: "./viewbooking.component.html",
	styleUrls: ["./viewbooking.component.scss"]
})
export class ViewbookingComponent implements OnInit, OnDestroy {
	id: any;
	private sub: any;
	public booking: any = {};
	displayedColumns: string[] = ['lastUpdatedAt', 'lastUpdatedByEmail', 'lastUpdatedById', 'status'];
	displayedColumnsnote: string[] = ['note', 'addedOn','addedByEmail'];
	currentbooking: any;
	user:any;
	bookinghistory: any;
	bookingIntegrationData:any;
	public moment: any = moment;
	showDialog = false;
	showSuccessDialog = false;
	showerrorDialog = false;
	showerrordbDialog = false;
	messageSuccessDialog = false;
	messageErrorDialog = false;
	showWeWorkBookingDetails = false;
	showAwfisBookingDetails = false;
	showInnov8BookingDetails = false;
	space: any;
	spaceid: any;
	invoiceDetail: any;
	options: any;
	messageDetail: any = {};
	messages: any;
	public notes:MatTableDataSource<any> =  new MatTableDataSource<any>([]);
	public myDataSource: MatTableDataSource<any>;
	spaceFeedback:any;
	@ViewChild('myTable',{static:false}) myTable: MatTable<any>;
	constructor(
		private _bookingservice: BookingService,
		private route: ActivatedRoute,
		public router: Router,
		public loader: LoaderService,
		public message: MessageService,
		private dialog: MatDialog,
		private titleService:Title,
		private sharedService : SharedProvider
	) {
		this.spaceFeedback = {feedback:""};

	}

	ngOnInit() {
		
		this.id = this.route.snapshot.params["id"];
		this.getBookingsDetails();
	}
	getSpaceDetails() {
		const data = {
			id: this.spaceid
		};

		this._bookingservice.getSpace(data).then(res => {
			this.space = res;
			if(this.space.facilities)
			{
				let facilityString = '';
				for(let i = 0; i < this.space.facilities.length; i++)
				{
					facilityString += this.space.facilities[i] + ', ';
				}
				if(facilityString && facilityString.length > 0)
				{
					this.space.facilities = facilityString.substring(0, facilityString.length - 2)
				}
			}			
			// this.loader.display(false);
		});
	}

	calculateSpaceUsageFees() {
		const { bookingType, spaceType, checkin, checkout,durationDay,priceperday,
			priceperhr, durationMonth, pricepermonth, headCount } = this.currentbooking;
		if (['Cafe', 'Restaurant'].includes(spaceType)) {
			if (checkin && checkout) {
				return Math.ceil((checkout - checkin) / (1000 * 60 * 60)) * Number(headCount) * Number(priceperhr);
			}
		} else if (['Shared Workspace'].includes(spaceType)) {
			if(bookingType == "Day") {
				return Number(durationDay) * Number(priceperday) * Number(headCount);
			}
			return Number(durationMonth) * Number(pricepermonth) * Number(headCount);
		}
	}


	getBookingsDetails() {
		this.loader.display(true);
		// const data = {
		// 	//custId: "GDHAnLxS6nUbM7ciU2FnmZ7Q18x1"
		// 	searchby: "bookingkey",
		// 	value: this.id
		// };
		// this._bookingservice.searchbookinglist(data).then((res: any) => {
		// 	console.log("res2",res,new Date().getTime());
		// });
		this._bookingservice.getBookingDetails(this.id).then(res => {
			// console.log("res3",res,new Date().getTime());
			if (res) {
				this.currentbooking = res;
				if (this.currentbooking.wework) {
					this.showWeWorkBookingDetails = true;
				}
				if (this.currentbooking.awfis) {
					this.showAwfisBookingDetails = true;
				}
				if (this.currentbooking.innov8) {
					this.showInnov8BookingDetails = true;
				}
				this.getUserDetails();
				let title = this.currentbooking.bookingid + ' - ' + "Booking Details";
				this.titleService.setTitle(title);
				this.sharedService.setBookingTitle(title);
				// console.log("Current booking data: " , this.currentbooking);
				this.invoiceDetail = {
					name: this.currentbooking.customerName,
					email: this.currentbooking.customerEmail,
					contact: this.currentbooking.customerPhoneNumber,
					bookingkey: this.currentbooking.bookingkey,
					bookingid: this.currentbooking.bookingid,
					receiptid: this.currentbooking.bookingid,
					spaceusagefee: this.calculateSpaceUsageFees() || this.currentbooking.spaceUsageFee,
					description: "GoFloaters Fees for " + this.currentbooking.spaceName,
					sendsms: true,
					sendemail: true
				};
				
				this.spaceid = this.currentbooking.spaceId;
				this.booking.status = this.currentbooking.status;
				if(this.currentbooking.notes) {
					this.notes = this.notes = new MatTableDataSource<any>(_.values(this.currentbooking.notes).map(value => {
						value.addedOn = moment(value.addedOn)
							.tz("Asia/Kolkata")
							.format("DD/MM/YYYY h:mm a");
						return value;
					}));
				}
				
				this.booking.disableNotifications = this.currentbooking.disableNotifications;
				// this.getSpaceDetails();
				// this.getBookingHistory();
				this.loader.display(false);
				// this.getBookingIntegrationsData();
				
			}
		});
	}
	getUserDetails () {
		let searchData;
		searchData = {
			userEmailId: this.currentbooking.customerEmail
		};
		this
		._bookingservice
		.getUser
		(searchData)
		.then(res => {
			this.user = res;
		}, err => {
			console.log("Error fetching the user details");
		});
		
	}
	getBookingHistory() {
		const booking_key = { 'id': this.currentbooking.bookingkey }
		this._bookingservice.getBookingHistory(booking_key).then((res: any) => {
			if (res) {
				this.bookinghistory = _.values(res).map(value => {
					value.lastUpdatedAt = moment(value.lastUpdatedAt)
						.tz("Asia/Kolkata")
						.format("DD/MM/YYYY h:mm a");
					return value;
				});
			}
		}).catch((e) => {
			console.log("GetBookingHistory", e);
		})
	}
	getBookingIntegrationsData() {
		const booking_key = { 'id': this.currentbooking.bookingkey }
		this._bookingservice.getBookingIntegrationsData(booking_key).then((res: any) => {
			if (res) {
				this.bookingIntegrationData = res;
			}
		}).catch((e) => {
			console.log("GetBookingHistory", e);
		})
	}
	ngOnDestroy() { 
		this.titleService.setTitle("GoFloaters");
	}
	openSpaceModel(spaceId) {
		this.dialog.open(ViewSpaceDetailsComponent, {
			data: {
				spaceId: spaceId,
				modelMode: true
			}
		}).afterClosed().subscribe(updateRequired => {
			
		});
	}
	sendmessage() {
		let number;
		let email;
		let userid;
		if (this.messageDetail.sendTo == "manager") {
			number = this.space.managerphone;
			email = this.space.ownerEmail;
			userid = this.space.ownerId;
		} else if (this.messageDetail.sendTo == "customer") {
			number = this.currentbooking.customerPhoneNumber;
			email = this.currentbooking.customerEmail;
			userid = this.currentbooking.custId;
		}

		if (this.messageDetail.medium == "call") {
			location.href = "tel:" + number;
		} else if (this.messageDetail.medium == "whatsapp") {
			location.href =
				"whatsapp://send?phone=+91" +
				number +
				"&&text=" +
				this.messageDetail.finalmessage;
		} else if (this.messageDetail.medium == "email") {
			this.messageDetail.email = email;

			this.message.sendMessage(this.messageDetail).then((res: any) => {
				if (res.status == "success") {
					this.messageSuccessDialog = true;
				} else {
					this.messageErrorDialog = true;
				}
			});
		} else if (this.messageDetail.medium == "pushnotification") {
			this.messageDetail.userId = userid;
			this.message.sendMessage(this.messageDetail).then((res: any) => {
				if (res.status == "success") {
					this.messageSuccessDialog = true;
				} else {
					this.messageErrorDialog = true;
				}
			});
		} else if (this.messageDetail.medium == "sms") {
			this.messageDetail.number = number;
			this.message.sendMessage(this.messageDetail).then((res: any) => {
				if (res.status == "success") {
					this.messageSuccessDialog = true;
				} else {
					this.messageErrorDialog = true;
				}
			});
		}
	}
	changestatus() {
		const data = {
			id: this.currentbooking.bookingkey,
			status: this.booking.status,
			disableNotifications : this.booking.disableNotifications
		};
		this.loader.display(true);
		this._bookingservice.changestatus(data).then(
			(res: any) => {
				this.loader.display(false);
				if (res.status === "success") {
					this.showSuccessDialog = true;
				} else {
					this.showerrorDialog = true;
				}
			},
			err => {
				this.showerrordbDialog = true;
			}
		);
	}
	success() {
		this.router.navigate(["/landingpage/bookings"]);
	}

	saveFeedback() {
		this.loader.display(true);
		this.spaceFeedback["customerName"] = this.currentbooking.customerName;
		this.spaceFeedback["feedbackDate"] = new Date().getTime();
		this.spaceFeedback["rating"] = 5;
		this.spaceFeedback["gofloaterrating"] = 5;
		this.spaceFeedback["spaceId"] = this.currentbooking.spaceId;
		this.spaceFeedback["spaceName"] = this.space.spaceDisplayName;
		this.spaceFeedback["bookingId"] = this.currentbooking.bookingkey;
		this._bookingservice.saveSpaceFeedback(this.spaceFeedback).then(
			(res: any) => {
				this.loader.display(false);
				if (res.status === "success") {
					this.showSuccessDialog = true;
				} else {
					this.showerrorDialog = true;
				}
			})

	}

	sendpaymentlink() {
		this.loader.display(true);
		this.invoiceDetail.sendsms = this.invoiceDetail.sendsms ? "1" : "0";
		this.invoiceDetail.sendemail = this.invoiceDetail.sendemail ? "1" : "0";
		this.invoiceDetail.amount = (
			this.invoiceDetail.spaceusagefee * 1.18
		).toFixed(2);
		this._bookingservice.createPaymentLink(this.invoiceDetail).then(
			(res: any) => {
				// this.loader.display(false);
				this.currentbooking.razorpayurl = res.status.short_url;
				this._bookingservice.editBooking(this.currentbooking).then(
					(res: any) => {
						this.loader.display(false);
						if (res.status === "success") {
							this.showSuccessDialog = true;
						} else {
							this.showerrorDialog = true;
						}
					},
					err => {
						this.showerrordbDialog = true;
					}
				);

				// if (res.status === 'success') {
				//   this.showSuccessDialog = true;
				//  }else {
				//   this.showerrorDialog = true;
				//  }
			},
			err => {
				//  this.showerrordbDialog = true;
			}
		);
	}

	onMessageChange(ev) {
		this.messageDetail.finalmessage = this.messageDetail.message;
	}

	onMediumChange() {
		this.messageDetail.message = "";
		this.messageDetail.finalmessage = "";
	}
	onSendToChange(value) {
		this.messageDetail.message = "";
		this.messageDetail.finalmessage = "";
		if (value === "manager") {
			this.messages = [
				`We got a booking request from ${
				this.currentbooking.customerName
				} for ${this.currentbooking.spaceName} from ${moment(
					this.currentbooking.startTime
				)
					.tz("Asia/Kolkata")
					.format("DD/MM/YYYY h:mm a")} to ${moment(this.currentbooking.endTime)
						.tz("Asia/Kolkata")
						.format("DD/MM/YYYY h:mm a")}. Shall we proceed to confirm?`,
				`${this.currentbooking.customerName} has cancelled the request for ${
				this.currentbooking.spaceName
				} dated ${moment(this.currentbooking.startTime)
					.tz("Asia/Kolkata")
					.format("DD/MM/YYYY h:mm a")}.`
			];
		} else if (value === "customer") {
			this.messages = [
				`Your request for ${this.currentbooking.spaceName} from ${moment(
					this.currentbooking.startTime
				)
					.tz("Asia/Kolkata")
					.format("DD/MM/YYYY h:mm a")} to ${moment(this.currentbooking.endTime)
						.tz("Asia/Kolkata")
						.format(
							"DD/MM/YYYY h:mm a"
						)} is confirmed. Please do Checkin when you start using space`,
				`Your request for ${this.currentbooking.spaceName} from ${moment(
					this.currentbooking.startTime
				)
					.tz("Asia/Kolkata")
					.format("DD/MM/YYYY h:mm a")} to ${moment(this.currentbooking.endTime)
						.tz("Asia/Kolkata")
						.format(
							"DD/MM/YYYY h:mm a"
						)} has cancelled. Please try some other location`
			];
		}
	}
	editBooking() {
		this.dialog
			.open(EditBookingComponent, {
				data: {
					bookingKey: this.currentbooking.bookingkey
				}
			})
			.afterClosed()
			.subscribe(updateRequired => {
				if (updateRequired) {
					this.ngOnInit();
				}
			});
	}

	
	cancelbooking() {
			this.loader.display(true);
			const data = {
				id: this.currentbooking.bookingkey,
				status: "Cancelled",
				disableNotifications : this.booking.disableNotifications
			};
			this._bookingservice.changestatus(data).then(
				(res: any) => {
					if (res.status === "success") {
						this.showDialog = true;
						this.loader.display(false);
						// this.router.navigate(['/landingpage/bookings']);
					} else {
						this.showerrorDialog = true;
					}
				},
				err => {
					this.showerrordbDialog = true;
				}
			);
	}
	cancelBooking() {
		this.router.navigate(["/landingpage/bookings"]);
	}
	Failure() {
		this.router.navigate(["/viewbooking"]);
	}
	dbFauilure() {
		this.router.navigate(["/viewbooking"]);
	}
	getTime(time) {
		return this.moment(time)
			.tz("Asia/Kolkata")
			.format("h:mm a");
	}
	getdate(val) {
		return this.moment(val)
			.tz("Asia/Kolkata")
			.format("DD/MM/YYYY");
	}
	getdatetime(val) {
		return this.moment(val)
			.tz("Asia/Kolkata")
			.format("DD/MM/YYYY, h:mm a");
	}
	converttime(timestamp) {
		const date = new Date(timestamp * 1000);
		// Hours part from the timestamp
		const hours = date.getHours();
		// Minutes part from the timestamp
		const minutes = "0" + date.getMinutes();
		// Seconds part from the timestamp
		const seconds = "0" + date.getSeconds();
		// Will display time in 10:30:23 format
		const formattedTime =
			hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);

		return formattedTime;
	}
	public addNote() {
		this.dialog.open(AddNoteComponent, {
				data: { header:"Add Note", button_text:"Add"},
				width: '400px',
				height: '250px'
		}).afterClosed().subscribe(updateRequired => {
			if (updateRequired) {
				const data = {
					'bookingkey': this.id,
					'note': updateRequired.note
				};
				this.loader.display(true);
				this._bookingservice.addNoteBooking(data).then((res: any) => {
					if(res.status) {
						res.note.addedOn = moment(res.note.addedOn)
							.tz("Asia/Kolkata")
							.format("DD/MM/YYYY h:mm a");
						console.log(res.note);
						this.notes.data.push(res.note);
						this.myTable.renderRows();
					}
					this.loader.display(false);
				}).catch((e) =>{
					this.loader.display(false);
				});
			}
		});
	}
}
