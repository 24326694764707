
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable()
export class AddspaceService {
    url = "api/books";
    constructor(private http:HttpClient) { }
    addSpace(space): Observable<any> {
            return this.http.post(this.url, space).pipe(
                       map(this.extractData),
                       catchError(this.handleErrorObservable),);
        }
    private extractData(res: any) {
        let body = res;
            return body.data || {};
        }
        private handleErrorObservable (error: any | any) {
        console.error(error.message || error);
        return observableThrowError(error.message || error);
        }
    
}