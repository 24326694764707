export default [
    {
        key: 'spaceDesc',
        displayName: 'Space Description',
        value: ''
    },
    {
        key: 'operationTime',
        displayName: 'Operation Time',
        value: ''
    },
    {
        key: 'originalName',
        displayName: 'Original Name',
        value: ''
    },{
        key: 'operatorName',
        displayName: 'Operator Name',
        value: ''
    }, {
        key: 'meetingRoomName',
        displayName: 'Meeting Room Name',
        value: ''
    },
    {
        key: 'spaceType',
        displayName: 'Space Type',
        value: ''
    },
    {
        key: 'officeSpaceType',
        displayName: 'Office Space Type',
        value: ''
    },
    {
        key: 'minHeadCount',
        displayName: 'Min Head Count',
        value: ''
    },
    {
        key: 'calendarURL',
        displayName: 'Calendar URL',
        value: ''
    },
    {
        key: 'calendarId',
        displayName: 'Calendar Id',
        value: ''
    },
    {
        key: 'noiseLevel',
        displayName: 'Noise Level',
        value: ''
    },
    {
        key: 'sortPriority',
        displayName: 'Responsiveness Score',
        value: ''
    },
    {
        key: 'videos',
        displayName: 'Video URL',
        value: ''
    }];